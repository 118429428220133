import clsx from 'clsx';
import React, { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CurrencyInput from '../../../components/Form/CurrencyInput/CurrencyInput';
import Icon from '../../../components/Icon/Icon';
import useBetslip from '../../../hooks/useBetslip/useBetslip';
import useExoticBetslip from '../../../hooks/useBetslip/useExoticBetslip';
import useMultiBetslip from '../../../hooks/useBetslip/useMultiBetslip';
import useSingleBetslip from '../../../hooks/useBetslip/useSingleBetslip';
import { getMultiBets } from '../../../utils/betslip/common';
import { EmptyBetslip } from './EmptyBetslip';
import ExoticBetCard from './ExoticBetCard';
import MultiRaceBetCard from './MultiRaceBetCard';
import SingleBetCard from './SingleBetCard';
import styles from './TraditionalBetslip.module.css';
import { TraditionalSingleBet } from './types';

const TransitionTimeoutSettings = {
  enter: 300,
  exit: 300,
};

export default function TraditionalBetslip() {
  const {
    state: { betslip },
  } = useBetslip();
  const { getSingleBets, updateStakeAll: updateStakeAllSingles } =
    useSingleBetslip();
  const { getExoticBets, updateStakeAll: updateStakeAllExotics } =
    useExoticBetslip();
  const { updateStakeAll: updateStakeAllMultis } = useMultiBetslip();

  const exoticBets = getExoticBets();
  const multiRaceBets = getMultiBets(betslip);

  // Traditional betslip single bets are grouped by race and bet type.

  const singleBets = useMemo(
    () =>
      getSingleBets().reduce<TraditionalSingleBet[]>((aggregated, value) => {
        const existingBet = aggregated.find(
          (bet) =>
            bet.betType === value.betType && bet.race.id === value.race.id
        );

        if (existingBet) {
          return aggregated.map((bet) => {
            if (
              bet.race.id === existingBet.race.id &&
              bet.betType === existingBet.betType
            ) {
              return {
                ...bet,
                selections: [...bet.selections, value.competitor].sort(
                  (a, b) => a.tabNo - b.tabNo
                ),
              };
            }

            return bet;
          });
        }

        return [
          ...aggregated,
          {
            race: value.race,
            meeting: value.meeting,
            betType: value.betType,
            stake: value.stake,
            selections: [value.competitor],
          },
        ];
      }, []),
    [getSingleBets]
  );

  const isBetslipEmpty =
    singleBets.length < 1 && exoticBets.length < 1 && multiRaceBets.length < 1;

  return (
    <>
      {singleBets.length > 0 ? (
        <Accordion
          title="Singles"
          count={singleBets.length}
          onChangeStake={(stake) =>
            updateStakeAllSingles({
              stake,
            })
          }
        >
          <TransitionGroup component="div">
            {singleBets.map((bet) => (
              <CSSTransition
                timeout={TransitionTimeoutSettings}
                key={`${bet.betType}-${bet.race.id}`}
                classNames={{
                  enter: styles['betitem-animate-in'],
                  enterActive: styles['betitem-animate-in-done'],
                  enterDone: styles['betitem-animate-in-done'],
                  exitActive: styles['betitem-animate-out'],
                  exit: styles['betitem-hide'],
                  exitDone: styles['betitem-hide'],
                }}
              >
                <SingleBetCard bet={bet} />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Accordion>
      ) : null}
      {exoticBets.length > 0 && (
        <Accordion
          title="Exotics"
          count={exoticBets.length}
          onChangeStake={(stake) =>
            updateStakeAllExotics({
              stake,
            })
          }
        >
          <TransitionGroup component="div">
            {exoticBets.map((bet) => (
              <CSSTransition
                timeout={TransitionTimeoutSettings}
                key={`${bet.betType}-${bet.race.id}`}
                classNames={{
                  enter: styles['betitem-animate-in'],
                  enterActive: styles['betitem-animate-in-done'],
                  enterDone: styles['betitem-animate-in-done'],
                  exitActive: styles['betitem-animate-out'],
                  exit: styles['betitem-hide'],
                  exitDone: styles['betitem-hide'],
                }}
              >
                <ExoticBetCard bet={bet} />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Accordion>
      )}
      {multiRaceBets.length > 0 && (
        <Accordion
          title="Multiples"
          count={multiRaceBets.length}
          onChangeStake={(stake) =>
            updateStakeAllMultis({
              stake,
            })
          }
        >
          <TransitionGroup component="div">
            {multiRaceBets.map((bet) => (
              <CSSTransition
                timeout={TransitionTimeoutSettings}
                key={`${bet.betType}-${bet.races?.[0]}`}
                classNames={{
                  enter: styles['betitem-animate-in'],
                  enterActive: styles['betitem-animate-in-done'],
                  enterDone: styles['betitem-animate-in-done'],
                  exitActive: styles['betitem-animate-out'],
                  exit: styles['betitem-hide'],
                  exitDone: styles['betitem-hide'],
                }}
              >
                <MultiRaceBetCard bet={bet} />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Accordion>
      )}
      {isBetslipEmpty && <EmptyBetslip />}
    </>
  );
}

function Accordion({
  title,
  count,
  children,
  onChangeStake,
}: {
  title: string;
  count: number;
  children: ReactNode;
  onChangeStake: (stake: number) => void;
}) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div
      className={clsx(styles.acccordion, {
        [styles.expanded]: expanded,
      })}
    >
      <div className={styles.accordionHeader}>
        <button
          type="button"
          className={styles.accordionButton}
          onClick={() => setExpanded((prev) => !prev)}
          title={`${expanded ? 'Hide' : 'Show'} ${title}`}
        >
          <Icon className={styles.chevron} name="caretDown" />
          <span>
            {title} &middot; {count}
          </span>
        </button>
        <div className={styles.stakeInputContainer}>
          <span className={styles.label}>Add to all {title}</span>
          <CurrencyInput
            size={5}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChangeStake(+event.target.value)
            }
          />
        </div>
      </div>
      <div className={styles.bets}>{children}</div>
    </div>
  );
}
