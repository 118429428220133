import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';
import Icon, { IconName } from '../Icon/Icon';
import styles from './Button.module.css';

type ButtonTheme =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'transparentDark'
  | 'transparentLight'
  | 'none';
type ButtonType = 'button' | 'submit' | 'reset';
type ButtonSize = 'default' | 'small' | 'medium' | 'large';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: ButtonType;
  theme?: ButtonTheme;
  size?: ButtonSize;
  className?: string;
  iconClassName?: string;
  selected?: boolean;
  leftIcon?: IconName;
  rightIcon?: IconName;
}

const Button = forwardRef(
  (
    {
      type = 'button',
      theme = 'default',
      size = 'default',
      className = '',
      iconClassName = '',
      leftIcon,
      rightIcon,
      children,
      selected,
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      /* eslint-disable react/button-has-type */
      type={type}
      /* eslint-enable react/button-has-type */
      className={clsx(
        styles.button,
        styles[`theme--${theme}`],
        styles[`size--${size}`],
        className
      )}
      data-selected={selected}
      ref={ref}
      {...rest}
    >
      {leftIcon && (
        <div className={clsx(styles.iconContainer, styles.marginRight)}>
          <Icon name={leftIcon} className={iconClassName} />
        </div>
      )}
      <span className={styles.buttonContent}>{children}</span>
      {rightIcon && (
        <div className={clsx(styles.iconContainer, styles.marginLeft)}>
          <Icon name={rightIcon} className={iconClassName} />
        </div>
      )}
    </button>
  )
);

export default Button;
