import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import QueryString from 'qs';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions/FormActions';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import Icon from '../../../components/Icon/Icon';
import useAppState from '../../../hooks/useAppState/useAppState';
import useProxy from '../../../hooks/useProxy';
import { Auth } from '../../../types/auth';
import ModalContainer from './ModalContainer';
import styles from './ModalContainer.module.css';

type Props = {
  onDismiss: () => void;
};

type FormValues = {
  username: string;
  password: string;
};

export default function LoginModal({ onDismiss }: Props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();
  const location = useLocation();
  const { setUser } = useAppState();
  const axios = useProxy();
  const [errorMessage, setErrorMessage] = useState('');

  const auth = useMutation<Auth, unknown, FormValues>(
    'Auth',
    async (data) => {
      setErrorMessage('');
      const response = await axios.post<FormValues, AxiosResponse<Auth>>(
        '/api/v1/falcon/auth',
        data
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setUser(data);
        toast.success('You have logged in.');
        onDismiss();
      },
      onError: () => {
        setErrorMessage('Incorrect username or password. Please try again.');
      },
    }
  );

  const login: SubmitHandler<FormValues> = async (data) => {
    await auth.mutate(data);
  };

  return (
    <ModalContainer onDismiss={onDismiss} title="Log into your account">
      <form
        onSubmit={handleSubmit(login)}
        className={clsx(styles.form, {
          [styles.hasError]: !!errorMessage,
        })}
      >
        {errorMessage ? (
          <div className={styles.errorMessage}>
            <Icon name="alert" />
            {errorMessage}
          </div>
        ) : null}
        <InputRow label="Username" errorText={errors.username?.message}>
          <Input
            id="Username"
            autoComplete="username"
            autoFocus
            {...register('username', { required: 'Required' })}
          />
        </InputRow>
        <InputRow
          label="Password"
          errorText={errors.password?.message}
          ActionComponent={
            <Link
              to={{
                search: QueryString.stringify({
                  ...QueryString.parse(location.search, {
                    ignoreQueryPrefix: true,
                  }),
                  action: 'forgot',
                }),
              }}
              onClick={(event) => event.stopPropagation()}
              className="link"
            >
              Forgot password?
            </Link>
          }
        >
          <Input
            type="password"
            id="Password"
            autoComplete="password"
            {...register('password', { required: 'Required' })}
          />
        </InputRow>
        <FormActions>
          <Button type="submit" disabled={auth.isLoading} theme="secondary">
            Log In
          </Button>
        </FormActions>
        <p className={styles.secondary}>
          <span>Not a user?&nbsp;</span>
          <Link
            to={{
              search: QueryString.stringify({
                ...QueryString.parse(location.search, {
                  ignoreQueryPrefix: true,
                }),
                action: 'signup',
              }),
            }}
            onClick={(event) => event.stopPropagation()}
            className="link"
          >
            Create an account
          </Link>
        </p>
      </form>
    </ModalContainer>
  );
}
