import { useReducer } from 'react';
import BetslipContext, { INITIAL_STATE } from './BetslipContext';
import betslipReducer from './betslipReducer';

export default function BetslipProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(betslipReducer, INITIAL_STATE);

  return (
    <BetslipContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </BetslipContext.Provider>
  );
}
