import clsx from 'clsx';
import { useContext, useEffect, useRef } from 'react';
import Silk from '../../../../../components/Silk/Silk';
import meetingTypes from '../../../../../constants/meetingTypes';
import useAppState from '../../../../../hooks/useAppState/useAppState';
import useFormat from '../../../../../hooks/useFormat';
import { useMeasure } from '../../../../../hooks/useMeasure';
import { RaceDetailedCompetitor } from '../../../../../hooks/useRace/types';
import { UseRaceData } from '../../../../../hooks/useRace/useRace';
import RaceContext from '../../RaceContext';
import RunnerInfo from '../RunnerInfo/RunnerInfo';
import ShortForm from '../ShortForm/ShortForm';
import styles from './RunnerDetails.module.css';

type Props = {
  race: UseRaceData;
  competitor: RaceDetailedCompetitor;
  position?: number;
  hideForm?: boolean;
};

export default function RunnerDetails({
  race,
  competitor,
  position,
  hideForm = false,
}: Props) {
  const format = useFormat();
  const { settings } = useAppState();
  const { setRunnerHeight } = useContext(RaceContext);
  const ref = useRef(null);
  const rect = useMeasure(ref);

  useEffect(() => {
    setTimeout(
      () =>
        setRunnerHeight((prev) => ({
          ...prev,
          [competitor.tabNo - 1]: Math.ceil(rect?.height || 0),
        })),
      0
    );
  }, [rect, setRunnerHeight, competitor]);

  function renderDetails() {
    switch (race.meeting.type) {
      case meetingTypes.THOROUGHBRED:
        return (
          <div className={styles.runnerContainer}>
            <span className={clsx(styles.long, styles.jockey)}>
              <span className={styles.label}>J: </span>
              <span>{competitor.jockey}</span>
            </span>
            <span className={clsx(styles.long, styles.weightJ)}>
              <span className={styles.label}>W: </span>
              {format.weight(competitor.weightTotal)}
            </span>
            <span className={clsx(styles.long, styles.trainer)}>
              <span className={styles.label}>T: </span>
              {competitor.trainer}
            </span>
            <span className={clsx(styles.long, styles.form)}>
              <span className={styles.label}>F: </span>
              {competitor.formHistory
                ?.slice(0, 6)
                .map((form) => form.finishingPosition)}
            </span>
          </div>
        );
      case meetingTypes.HARNESS:
        return (
          <div className={styles.runnerContainer}>
            <span className={styles.long}>
              <span className={styles.label}>D: </span>
              <span>{competitor.jockey}</span>
            </span>
            <span className={styles.long}>
              <span className={styles.label}>F: </span>
              {competitor.formHistory
                ?.slice(0, 6)
                .map((form) => form.finishingPosition)}
            </span>
            <span className={styles.long}>
              <span className={styles.label}>T: </span>
              {competitor.trainer}
            </span>
          </div>
        );
      case meetingTypes.GREYHOUND:
        return (
          <div className={styles.runnerContainer}>
            <span className={styles.long}>
              <span className={styles.label}>T: </span>
              {competitor.trainer}
            </span>
            <span className={styles.long}>
              <span className={styles.label}>W: </span>
              {format.weight(competitor.weightTotal)}
            </span>
            <span className={styles.long}>
              <span className={styles.label}>F: </span>
              {competitor.formHistory
                ?.slice(0, 6)
                .map((form) => form.finishingPosition)}
            </span>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div ref={ref}>
      <div
        className={clsx(styles.competitor, {
          [styles.scratched]: competitor.scratched,
        })}
      >
        <div className={styles.silkContainer}>
          <Silk competitor={competitor} race={race} meeting={race.meeting} />
          {position ? (
            <div
              className={clsx(
                styles.positionBox,
                styles[`position--${position}`]
              )}
            >
              {format.ordinal(position)}
            </div>
          ) : null}
        </div>
        <div className={styles.competitorDetails}>
          <span className={styles.competitorName}>
            {competitor.tabNo}. {competitor.name}
          </span>
          {competitor.scratched ? (
            <span className={styles.label}>SCRATCHED</span>
          ) : (
            renderDetails()
          )}
        </div>
      </div>
      {!hideForm && settings.showRunnerInfo && (
        <div className={styles.section}>
          <RunnerInfo competitor={competitor} race={race} />
        </div>
      )}
      {!hideForm && settings.showShortForm && (
        <div className={styles.section}>
          <ShortForm competitor={competitor} race={race} />
        </div>
      )}
    </div>
  );
}
