import { gql, useQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Meeting, RaceDetailed } from './types';

export type UseRaceData = RaceDetailed & {
  meeting: Meeting;
};

export default function useRace(id?: string) {
  const [race, setRace] = useState<UseRaceData | undefined>();

  const { data, loading } = useQuery<{ race: UseRaceData }>(
    gql`
      query race($id: ID) {
        race(id: $id) {
          id
          number
          name
          distance
          startTime
          class
          status
          prizeMoney
          trackCondition
          weather
          comment
          priceSets(sources: ["${process.env.REACT_APP_PRICE_CODE}"]){
            type,
            poolSize
          }
          competitors {
            tabNo
            age
            sex
            colour
            dam
            sire
            owners
            barrier
            name
            jockey
            trainer
            scratched
            weightTotal,
            selectionID(source: "${process.env.REACT_APP_PRICE_CODE}"),
            formHistory {
              finishingPosition
              date
            }
            prices(sources: ["${process.env.REACT_APP_PRICE_CODE}"]) {
              price
              type
              tabNo
            }
            runner {
              id
            }
          }
          meeting {
            id
            type
            externalIDs(sources: "gbs") {
              id
            }
            track {
              name
              surface
              country
            }
            races {
              id
              number
              status
            }
            hasVision
          }
          results(sources: []) {
            source
            positions {
              tabNo
              position
              margin
            }
            dividends {
              type
              dividend
              dividendNumbers
              poolSize
            }
          }
        }
      }
    `,
    {
      variables: {
        id,
      },
    }
  );

  useEffect(() => {
    setRace(data?.race);
  }, [data]);

  useSubscription(
    gql`
      subscription raceUpdated($raceId: ID) {
        raceUpdated(raceId: $raceId) {
          id
          startTime
          status
        }
      }
    `,
    {
      variables: {
        raceId: id,
      },
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data && race) {
          const { raceUpdated } = subscriptionData.data;
          setRace((prevRace) => ({
            ...prevRace,
            ...raceUpdated,
          }));
        }
      },
    }
  );

  return { race, loading };
}
