import { createContext, Dispatch, SetStateAction } from 'react';

export type CCForm = {
  cardNumber: number;
  expiry: string;
  cvv: number;
  amount: number;
};

export type BankForm = {
  routingNum: number;
  accountNum: number;
  accountConfirm: number;
  amount: number;
};

export type CCFormContext = {
  data?: CCForm;
  setData?: Dispatch<SetStateAction<CCForm>>;
  onComplete?: () => void;
};

const DepositContext = createContext<CCFormContext>({});

export default DepositContext;
