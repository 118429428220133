import { ReactNode, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import useClickOutside from '../../hooks/useClickOutside';
import styles from './Badge.module.css';

type Props = {
  Component: ReactNode;
  children: ReactNode;
};

export default function Badge({ Component, children }: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLInputElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(
    buttonRef.current,
    tooltipRef.current,
    {
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    }
  );

  useClickOutside(tooltipRef, () => setShowTooltip(false), showTooltip);

  function handleClick(event: React.MouseEvent) {
    event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
    event.preventDefault(); // Prevents nested embedded links to be fired
    setShowTooltip((prev) => !prev);
  }

  return (
    <>
      <button
        onClick={handleClick}
        ref={buttonRef}
        type="button"
        className={styles.button}
      >
        {Component}
      </button>
      {showTooltip
        ? createPortal(
            <div
              ref={tooltipRef}
              className={styles.tooltip}
              style={popperStyles.popper}
              {...attributes.popper}
            >
              <div
                ref={setArrowRef}
                className={styles.arrow}
                style={popperStyles.arrow}
                data-popper-arrow
              />
              {children}
            </div>,
            document.getElementById('bm-root') || document.body
          )
        : null}
    </>
  );
}
