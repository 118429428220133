const numberColours: {
  number: number;
  backgroundColor: string;
  color: string;
}[] = [
  { number: 1, backgroundColor: '#BC3636', color: '#FFFFFF' },
  { number: 2, backgroundColor: '#FFFFFF', color: '#232D3F' },
  { number: 3, backgroundColor: '#3172D5', color: '#FFFFFF' },
  { number: 4, backgroundColor: '#DCCD4B', color: '#232D3F' },
  { number: 5, backgroundColor: '#4D9F35', color: '#FFFFFF' },
  { number: 6, backgroundColor: '#000000', color: '#DCCD4B' },
  { number: 7, backgroundColor: '#FCA574', color: '#232D3F' },
  { number: 8, backgroundColor: '#DBB1C5', color: '#232D3F' },
  { number: 9, backgroundColor: '#84E2E8', color: '#232D3F' },
  { number: 10, backgroundColor: '#A84FAA', color: '#FFFFFF' },
  { number: 11, backgroundColor: '#C5C5C5', color: '#BC3636' },
  { number: 12, backgroundColor: '#55CF61', color: '#232D3F' },
  { number: 13, backgroundColor: '#9C6939', color: '#FFFFFF' },
  { number: 14, backgroundColor: '#8F461D', color: '#DCCD4B' },
  { number: 15, backgroundColor: '#E5D8BE', color: '#232D3F' },
  { number: 16, backgroundColor: '#7DCAF5', color: '#BC3636' },
  { number: 17, backgroundColor: '#27207A', color: '#FFFFFF' },
  { number: 18, backgroundColor: '#0A5F45', color: '#DCCD4B' },
  { number: 19, backgroundColor: '#00417C', color: '#E95656' },
  { number: 20, backgroundColor: '#C81F5C', color: '#DCCD4B' },
  { number: 21, backgroundColor: '#DF86ED', color: '#232D3F' },
];

export default numberColours;
