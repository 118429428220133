import React, { createContext, Dispatch, SetStateAction } from 'react';
import { BetType } from '../../../constants/betTypes';
import { RaceDetailedCompetitor } from '../../../hooks/useRace/types';
import { UseRaceData } from '../../../hooks/useRace/useRace';
import { SortByKey } from '../../../utils/race/types';

const RaceContext = createContext<{
  sortBy: SortByKey;
  setSortBy: Dispatch<SetStateAction<SortByKey>>;
  sortDesc: boolean;
  setSortDesc: (value: boolean) => void;
  sortedCompetitors: RaceDetailedCompetitor[];
  race?: UseRaceData;
  betType: BetType;
  multiRunnerLength: number;
  updateMultiRunnerLength: (number: number, force?: boolean) => void;
  runnerHeight: Record<string, number>;
  setRunnerHeight: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}>({
  sortBy: SortByKey.NAME,
  setSortBy: () => {},
  sortDesc: true,
  setSortDesc: () => {},
  sortedCompetitors: [],
  betType: BetType.WIN,
  multiRunnerLength: 0,
  updateMultiRunnerLength: () => {},
  runnerHeight: {},
  setRunnerHeight: () => {},
});

export default RaceContext;
