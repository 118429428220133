import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import Icon from '../../../components/Icon/Icon';
import styles from './DepositMenu.module.css';
import { DepositViews } from './DepositTypes';

export default function DepositMenu({
  view,
  setView,
}: {
  view: DepositViews;
  setView: Dispatch<SetStateAction<DepositViews>>;
}) {
  return (
    <div className={styles.depositMenu}>
      <div
        className={clsx(styles.menuItemContainer, {
          [styles.selected]: view === 'creditCard',
        })}
        onClick={() => setView('creditCard')}
        role="button"
        tabIndex={0}
      >
        <div className={styles.flex}>
          <Icon name="creditCard" className={styles.rightMargin} />
          <div>Credit / Debit Card</div>
        </div>
        <div className={styles.flex}>
          <Icon name="visa" className={styles.rightMargin} />
          <Icon name="mastercard" />
        </div>
      </div>

      <div
        className={clsx(styles.menuItemContainer, {
          [styles.selected]: view === 'bank',
        })}
        onClick={() => setView('bank')}
        role="button"
        tabIndex={0}
      >
        <div className={styles.flex}>
          <Icon name="bank" className={styles.rightMargin} />
          <div>ACH Transfer</div>
        </div>
      </div>

      <div
        className={clsx(styles.menuItemContainer, {
          [styles.selected]: view === 'payNearMe',
        })}
        onClick={() => setView('payNearMe')}
        role="button"
        tabIndex={0}
      >
        <div className={styles.flex}>
          <Icon name="payNearMe" className={styles.rightMargin} />
          <div>PayNearMe</div>
        </div>
      </div>
    </div>
  );
}
