import QueryString from 'qs';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import Select from '../../../components/Form/Select/Select';
import Icon from '../../../components/Icon/Icon';
import InfoBox from '../../../components/InfoBox/InfoBox';
import CardSecurityHelpText from './CardSecurityHelpText';
import styles from './CreditCardDeposit.module.css';
import DepositAmountInput from './DepositAmountInput';
import DepositContext, { CCForm } from './DepositContext';

const cards = [
  {
    value: '8901',
    label: '5432 **** ***** 8901',
  },
  {
    value: '5432',
    label: '8901 **** ***** 5432',
  },
];

const infoBoxData = [
  { title: 'Transaction Limit', value: '$1 - $500' },
  {
    title: 'Fees',
    value: '3.5%',
    spanText: '(Your bank may also charge you a bank cash advance fee)',
  },
  { title: 'Availability', value: 'Upon Approval' },
];

export default function CreditCardDeposit({
  hasExistingCard = false,
}: {
  hasExistingCard?: boolean;
}) {
  const { data, setData } = useContext(DepositContext);
  const [isAddingCard, setIsAddingCard] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    setError,
    reset,
  } = useForm<CCForm>({
    defaultValues: data,
  });

  const submitAddCCDeposit: SubmitHandler<CCForm> = (formValues) => {
    // console.log('Add Credit Card and Deposit', formValues);
    setData?.((prev: any) => ({ ...prev, ...formValues }));
    reset();
    setIsAddingCard(false);
    toast.success(
      `Successfully deposited ${formValues.amount && `$${formValues.amount}`}`
    );
  };

  const submitExistingCCDeposit: SubmitHandler<CCForm> = (formValues) => {
    // console.log('Deposit with existing Credit Card', formValues);
    reset();
    toast.success(
      `Successfully deposited ${formValues.amount && `$${formValues.amount}`}`
    );
  };

  return (
    <>
      {hasExistingCard && !isAddingCard ? (
        <form
          onSubmit={handleSubmit(submitExistingCCDeposit)}
          className={styles.form}
        >
          <Card className={styles.container}>
            <div>
              <InfoBox data={infoBoxData} />
              <div className={styles.existingCard}>
                <InputRow
                  label="Card Number"
                  errorText={errors.cardNumber?.message}
                >
                  <Select
                    options={cards}
                    className={styles.select}
                    autoFocus
                    {...register('cardNumber', { required: 'Required' })}
                  />
                </InputRow>
                <Button
                  className={styles.button}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
                    event.preventDefault(); // Prevents nested embedded links to be fired
                    history.push({
                      search: QueryString.stringify({
                        ...QueryString.parse(location.search, {
                          ignoreQueryPrefix: true,
                        }),
                        action: 'editCard',
                      }),
                      state: { cardIdentifier: '8901' },
                    });
                  }}
                >
                  <Icon name="edit" />
                </Button>
                <Button
                  className={styles.button}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
                    event.preventDefault(); // Prevents nested embedded links to be fired
                    history.push({
                      search: QueryString.stringify({
                        ...QueryString.parse(location.search, {
                          ignoreQueryPrefix: true,
                        }),
                        action: 'removeCard',
                      }),
                      state: { cardIdentifier: '8901' },
                    });
                  }}
                >
                  <Icon name="bin" />
                </Button>
                <Button
                  leftIcon="plus"
                  theme="transparentDark"
                  size="small"
                  className={styles.addCardButton}
                  onClick={() => setIsAddingCard(true)}
                >
                  Add Card
                </Button>
              </div>
              <DepositAmountInput
                buttonLabel="Deposit"
                depositType="creditCard"
                hasExistingCard={hasExistingCard}
                cardSecurityHelpText={CardSecurityHelpText}
                getValues={getValues}
                setError={setError}
                register={register}
                errors={errors}
                setValue={setValue}
                isAddingCard={isAddingCard}
                setIsAddingCard={setIsAddingCard}
              />
            </div>
          </Card>
        </form>
      ) : (
        <form
          onSubmit={handleSubmit(submitAddCCDeposit)}
          className={styles.form}
        >
          <Card className={styles.container}>
            <InfoBox data={infoBoxData} />
            <div className={styles.inputContainer}>
              <div className={styles.halfWidth}>
                <InputRow
                  label="Card Number"
                  errorText={errors.cardNumber?.message}
                >
                  <Input
                    type="number"
                    id="Card Number"
                    autoComplete="cc-number"
                    className={styles.input}
                    autoFocus
                    {...register('cardNumber', { required: 'Required' })}
                  />
                </InputRow>
              </div>
              <InputRow label="Expiry" errorText={errors.expiry?.message}>
                <InputMask
                  mask="99/99"
                  {...register('expiry', { required: 'Required' })}
                  className={styles.test}
                >
                  {(inputProps: any) => (
                    <Input
                      {...inputProps}
                      id="Expiry"
                      placeholder="MM/YY"
                      autoComplete="cc-exp"
                      className={styles.input}
                      {...register('expiry', { required: 'Required' })}
                    />
                  )}
                </InputMask>
              </InputRow>
              <InputRow
                label="Security No"
                errorText={errors.cvv?.message}
                helpText={CardSecurityHelpText}
              >
                <Input
                  id="Security No"
                  autoComplete="cc-csc"
                  className={styles.input}
                  {...register('cvv', { required: 'Required' })}
                />
              </InputRow>
            </div>
          </Card>
          <Card className={styles.container}>
            <div>
              <DepositAmountInput
                buttonLabel="Add Card &amp; Deposit"
                depositType="creditCard"
                hasExistingCard={hasExistingCard}
                cardSecurityHelpText={CardSecurityHelpText}
                getValues={getValues}
                setError={setError}
                register={register}
                errors={errors}
                setValue={setValue}
                isAddingCard={isAddingCard}
                setIsAddingCard={setIsAddingCard}
              />
            </div>
          </Card>
        </form>
      )}
    </>
  );
}
