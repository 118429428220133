import clsx from 'clsx';
import useCountdown from '../../hooks/useCountdown';
import styles from './Countdown.module.css';

type Props = {
  startTime: string;
  className?: string;
};

export default function Countdown({ startTime, className = '' }: Props) {
  const countdown = useCountdown(startTime);

  return (
    <span
      className={clsx(
        styles.countdown,
        {
          [styles.warning]: countdown.warning,
          [styles.urgent]: countdown.urgent,
          [styles.posted]: countdown.posted,
        },
        className
      )}
    >
      {countdown.posted ? 'Race Closed' : countdown.label}
    </span>
  );
}
