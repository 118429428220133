import utils from '@bm/utils';
import useAppState from './useAppState/useAppState';

function useFormat() {
  const { settings, config: appStateConfig } = useAppState();
  const { oddsType } = settings;
  const config = {
    oddsType,
    locale: appStateConfig?.options?.locale || 'en-AU',
    currency: appStateConfig?.options?.currency || 'AUD',
    measurement: appStateConfig?.options?.measurement || 'METRIC',
  };

  function formatCurrency(value = 0, minimumFractionDigits = 0) {
    const sanitised = parseFloat(String(value || 0).replace(/[^-\d.]/g, ''));

    return new Intl.NumberFormat(config.locale, {
      style: 'currency',
      currency: config.currency,
      maximumFractionDigits: 2,
      minimumFractionDigits,
    }).format(sanitised);
  }

  return {
    odds: utils.format(config).odds,
    currency: formatCurrency,
    ordinal: utils.format(config).ordinal,
    weight: utils.format(config).weight,
    distance: utils.format(config).distance,
  };
}

export default useFormat;
