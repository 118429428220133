import { useCallback } from 'react';
import { BetslipEventType } from '../../constants/appEvents';
import { BetType } from '../../constants/betTypes';
import {
  AddBetslipMultiExoticDetail,
  ResetMultiSelections,
  UpdateAllStakeBetslipDetail,
  UpdateMultiRaces,
  UpdateMultiSelection,
  UpdateMultiSelectionAll,
} from '../../utils/betslip/eventDispatcher';
import useBetslip from './useBetslip';

export const multiBetTypes = [BetType.DOUBLE, BetType.PICK3, BetType.PICK6];

export default function useMultiBetslip() {
  const { dispatch } = useBetslip();

  const isMultiBetType = useCallback(
    (betType: BetType) => multiBetTypes.includes(betType),
    []
  );

  const updateMultiRaces = useCallback(
    (detail: Omit<UpdateMultiRaces, 'type'>) => {
      dispatch({ type: BetslipEventType.UPDATE_MULTI_RACES, ...detail });
    },
    [dispatch]
  );

  const resetMultiSelections = useCallback(
    (detail: Omit<ResetMultiSelections, 'type'>) => {
      dispatch({ type: BetslipEventType.RESET_MULTI_SELECTIONS, ...detail });
    },
    [dispatch]
  );

  const addMulti = useCallback(
    (detail: Omit<AddBetslipMultiExoticDetail, 'type'>) => {
      dispatch({ type: BetslipEventType.ADD_MULTI_EXOTIC, ...detail });
    },
    [dispatch]
  );

  const updateMultiSelection = useCallback(
    (detail: Omit<UpdateMultiSelection, 'type'>) => {
      dispatch({ type: BetslipEventType.UPDATE_MULTI_SELECTION, ...detail });
    },
    [dispatch]
  );

  const updateMultiSelectionAll = useCallback(
    (detail: Omit<UpdateMultiSelectionAll, 'type'>) => {
      dispatch({
        type: BetslipEventType.UPDATE_MULTI_SELECTION_ALL,
        ...detail,
      });
    },
    [dispatch]
  );

  const updateStakeAll = useCallback(
    (detail: Omit<UpdateAllStakeBetslipDetail, 'type'>) => {
      dispatch({ type: BetslipEventType.UPDATE_ALL_MULTIS_STAKE, ...detail });
    },
    [dispatch]
  );

  return {
    isMultiBetType,
    updateMultiRaces,
    resetMultiSelections,
    addMulti,
    updateMultiSelection,
    updateMultiSelectionAll,
    updateStakeAll,
  };
}
