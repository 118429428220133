import { gql, useQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PriceType } from '../../constants/priceTypes';
import { ExoticPool, RaceExotics } from './types';

export type UseExoticPools = ExoticPool[];

export default function useExoticPools(id: string | undefined) {
  const [exoticPools, setExoticPools] = useState<
    | {
        [type in PriceType]?: ExoticPool;
      }
    | undefined
  >();

  const { data } = useQuery<{ race: RaceExotics }>(
    gql`
      query race($id: ID) {
        race(id: $id) {
          id,
          exotics(sources: ["${process.env.REACT_APP_PRICE_CODE}"]) {
            type,
            poolSize,
            races{
              id
              number
            },
            dividends{
              combination,
              price
            }
          }
        }
      }
    `,
    {
      variables: {
        id,
      },
    }
  );

  useEffect(() => {
    if (data?.race?.exotics) {
      setExoticPools(
        data?.race?.exotics?.reduce(
          (aggregated, exotic) => ({
            ...aggregated,
            [exotic.type]: exotic,
          }),
          {}
        )
      );
    }
  }, [data]);

  useSubscription(
    gql`
      subscription exoticUpdates($raceId: ID) {
        exoticUpdates(raceId: $raceId, sources: ["${process.env.REACT_APP_PRICE_CODE}"]) {
          type,
          poolSize,
          dividends{
            combination,
            price
          }
        }
      }
    `,
    {
      variables: {
        raceId: id,
      },
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data && exoticPools) {
          const { exoticUpdates } = subscriptionData.data;
          setExoticPools((prevExoticPools) => ({
            ...prevExoticPools,
            [exoticUpdates.type]: {
              ...(prevExoticPools?.[exoticUpdates.type as PriceType] || {}),
              ...exoticUpdates,
            },
          }));
        }
      },
    }
  );

  return exoticPools;
}
