import { gql, useQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Pool, RaceWPS, WPSPools } from './types';

export type UseWPSPools = Pool[];

export default function useWPSPools(id: string | undefined) {
  const [pricePools, setPricePools] = useState<WPSPools>();

  const { data } = useQuery<{ race: RaceWPS }>(
    gql`
      query race($id: ID) {
        race(id: $id) {
          priceSets(sources: ["${process.env.REACT_APP_PRICE_CODE}"]) {
            type,
            poolSize
            updated,
            prices{
              tabNo,
              price
            }
          },
        }
      }
    `,
    {
      variables: {
        id,
      },
    }
  );

  useEffect(() => {
    if (data?.race?.priceSets) {
      setPricePools(
        data?.race?.priceSets.reduce(
          (aggregated, pool) => ({
            ...aggregated,
            [pool.type]: pool,
          }),
          {}
        )
      );
    }
  }, [data]);

  useSubscription(
    gql`
      subscription priceUpdates($raceId: ID) {
        priceUpdates(raceId: $raceId, sources: ["${process.env.REACT_APP_PRICE_CODE}"]) {
          type,
          poolSize,
          updated,
          prices{
            tabNo,
            price
          }
        }
      }
    `,
    {
      variables: {
        raceId: id,
      },
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data && pricePools) {
          const { priceUpdates } = subscriptionData.data;
          setPricePools((prevPools) => ({
            ...prevPools,
            [priceUpdates.type]: priceUpdates,
          }));
        }
      },
    }
  );

  return pricePools;
}
