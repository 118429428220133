import { createContext, Dispatch, SetStateAction } from 'react';

export type SignupForm = {
  email?: string;
  password?: string;
  state?: string;
  address?: string;
  primaryPhone?: string;
  socialSecurityNumber?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  accountPin?: string;
  secretWord?: string;
  userName?: string;
  zipCode?: string;
};

export type SignupFormContext = {
  data?: SignupForm;
  setData?: Dispatch<SetStateAction<SignupForm>>;
  onComplete?: () => void;
};

const SignupContext = createContext<SignupFormContext>({});

export default SignupContext;
