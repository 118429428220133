import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import Container from '../../../components/Container/Container';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import BettingSettings from './BettingSettings/BettingSettings';
import CommunicationSettings from './CommunicationSettings/CommunicationSettings';
import PersonalSettings from './PersonalSettings/PersonalSettings';

export default function Settings() {
  const route = useMemo(
    () => [
      {
        label: 'Account',
        url: '/account',
      },
      {
        label: 'Settings and Preferences',
        url: '/account/settings',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  return (
    <HomeContainer
      HeaderComponent={<h1>Settings and Preferences</h1>}
      tabs={[
        {
          label: 'Personal Details',
          url: '/account/settings/personal',
        },
        {
          label: 'Betting Preferences',
          url: '/account/settings/betting',
        },
        {
          label: 'Communication',
          url: '/account/settings/communication',
        },
      ]}
    >
      <Helmet titleTemplate="%s · Settings and Preferences · BetMakers">
        <meta name="description" content="Settings and Preferences" />
      </Helmet>
      <Container>
        <Switch>
          <Route
            exact
            path="/account/settings/personal"
            component={PersonalSettings}
          />
          <Route
            exact
            path="/account/settings/betting"
            component={BettingSettings}
          />
          <Route
            exact
            path="/account/settings/communication"
            component={CommunicationSettings}
          />
          <Redirect to="/account/settings/personal" />
        </Switch>
      </Container>
    </HomeContainer>
  );
}
