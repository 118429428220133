import { useCallback, useReducer, ReactNode } from 'react';
import BreadcrumbsContext, {
  Breadcrumb,
  INITIAL_BREADCRUMBS,
} from './BreadcrumbsContext';

const actionTypes = {
  PUSH: 'PUSH',
  POP: 'POP',
  RESET: 'RESET',
} as const;

type Action =
  | {
      type: typeof actionTypes.PUSH;
      breadcrumbs: Breadcrumb[];
    }
  | {
      type: typeof actionTypes.POP;
      count: number;
    }
  | {
      type: typeof actionTypes.RESET;
    };

function reducer(state: Breadcrumb[], action: Action) {
  switch (action.type) {
    case actionTypes.PUSH:
      return [...state, ...action.breadcrumbs];
    case actionTypes.POP:
      return state.slice(0, state.length - action.count);
    case actionTypes.RESET:
      return INITIAL_BREADCRUMBS;
    default:
      return state;
  }
}
export default function BreadcrumbsProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, INITIAL_BREADCRUMBS);

  const push = useCallback(
    (breadcrumbs: Breadcrumb[]) =>
      dispatch({
        type: actionTypes.PUSH,
        breadcrumbs,
      }),
    []
  );

  const pop = useCallback(
    (count: number) => dispatch({ type: actionTypes.POP, count }),
    []
  );

  const reset = useCallback(() => dispatch({ type: actionTypes.RESET }), []);

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs: state,
        push,
        pop,
        reset,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
}
