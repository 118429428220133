import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import Icon from '../../../../../components/Icon/Icon';
import useAppState from '../../../../../hooks/useAppState/useAppState';
import { FormHistory } from '../../../../../hooks/useRunner/types';
import styles from './Start.module.css';
import StartInfo from './StartInfo';

type Props = {
  start: FormHistory;
  startBefore: FormHistory;
};

export default function Start({ start, startBefore }: Props) {
  const {
    settings: { showShortForm },
  } = useAppState();
  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setNavExpanded((prev) => !prev)}
        className={styles.shortFormPositionInfo}
      >
        <span className={styles.minWidth}>
          <Icon name="watch" className={styles.icon} />
          <span className={styles.label}>Position: </span>
          {`${start.finishingPosition} of ${start.starters}`}
        </span>
        <span className={styles.minWidth}>
          <span className={styles.label}>Date: </span>
          {dayjs(start.date).format('DD/MM/YYYY')}
        </span>
        <span className={styles.minWidth}>
          <span className={styles.label}>Track: </span>
          {start.track}
        </span>
        <span className={styles.minWidth}>
          <span className={styles.label}>Dist: </span>
          {start.distance}
        </span>
        <span className={styles.minWidth}>
          <span className={styles.label}>Condition: </span>
          {start.trackCondition}
        </span>
        <span
          className={clsx(styles.chevronButton, {
            [styles.expanded]: navExpanded,
          })}
        >
          <Icon name="caretDown" />
        </span>
      </button>
      {navExpanded ? (
        <StartInfo start={start} startBefore={startBefore} />
      ) : null}
      <div
        className={clsx(styles.separator, {
          [styles.shortForm]: showShortForm,
        })}
      />
    </>
  );
}
