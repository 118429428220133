import Icon from '../Icon/Icon';
import Badge from './Badge';

export default function InPlayBadge() {
  return (
    <Badge Component={<Icon name="info" size="small" />}>
      This is the funds you have used for betting that have not resulted yet.
    </Badge>
  );
}
