import { useContext, useEffect } from 'react';
import BreadcrumbsContext, { Breadcrumb } from './BreadcrumbsContext';

export default function useBreadcrumbs(
  items: Breadcrumb[] = [],
  resetOnUnmount: boolean = false
) {
  const { breadcrumbs, push, pop, reset } = useContext(BreadcrumbsContext);

  useEffect(() => {
    if (items.length > 0) {
      push(items);
    }

    return () => {
      if (items.length > 0) {
        if (resetOnUnmount) {
          reset();
        } else {
          pop(items.length);
        }
      }
    };
  }, [items, resetOnUnmount, push, pop, reset]);

  return breadcrumbs;
}
