import clsx from 'clsx';
import styles from './ErrorDiv.module.css';

export type ErrorDivProps = React.HTMLProps<HTMLDivElement> & {
  errorText: string | undefined;
  children: React.ReactNode;
  className?: string;
};

export default function ErrorDiv({
  errorText,
  children,
  className,
}: ErrorDivProps) {
  return (
    <>
      <div
        className={clsx(
          {
            [styles.hasError]: !!errorText,
          },
          className
        )}
      >
        {children}
      </div>
      {errorText ? <span className={styles.errorText}>{errorText}</span> : null}
    </>
  );
}
