import clsx from 'clsx';
import QueryString from 'qs';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import Select from '../../../components/Form/Select/Select';
import Icon from '../../../components/Icon/Icon';
import InfoBox from '../../../components/InfoBox/InfoBox';
import styles from './BankDeposit.module.css';
import BankHelpText from './BankHelpText';
import DepositAmountInput from './DepositAmountInput';
import DepositContext, { BankForm } from './DepositContext';

const primaryInfoBoxData = [
  { title: 'Transaction Limit', value: '$1 - $500' },
  {
    title: 'Fees',
    value: '3.5%',
    spanText: '(Your bank may also charge you a bank cash advance fee)',
  },
  { title: 'Availability', value: 'Upon Approval' },
];

const secondaryInfoBoxData = [
  { title: 'Transaction Limit', value: '$986.00' },
  { title: 'Fees', value: '$12.80' },
  { title: 'Available', value: '$20.00' },
];

const accounts = [
  {
    value: '8899',
    label: 'Wells Fargo *****8899',
  },
  {
    value: '5432',
    label: 'Bank of America *****5432',
  },
];

export default function BankDeposit({
  hasExistingAccount,
}: {
  hasExistingAccount: boolean;
}) {
  const { data } = useContext(DepositContext);
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    setError,
    reset,
  } = useForm<BankForm>({
    defaultValues: data,
  });

  const submitExistingAccountDeposit: SubmitHandler<BankForm> = (
    formValues
  ) => {
    // console.log('Deposit with existing account', formValues);
    reset();
    toast.success(
      `Successfully deposited ${formValues.amount && `$${formValues.amount}`}`
    );
  };

  const submitAddAccountDeposit: SubmitHandler<BankForm> = (formValues) => {
    // console.log('Add Account and Deposit', formValues);
    reset();
    setIsAddingAccount(false);
    toast.success(
      `Successfully deposited ${formValues.amount && `$${formValues.amount}`}`
    );
  };

  return (
    <>
      {hasExistingAccount && !isAddingAccount ? (
        <Card
          className={clsx(styles.container, {
            [styles.singleCard]: hasExistingAccount,
          })}
        >
          <InfoBox data={primaryInfoBoxData} />
          <form
            onSubmit={handleSubmit(submitExistingAccountDeposit)}
            className={styles.form}
          >
            <div className={styles.existingAccount}>
              <InputRow label="Account" errorText={errors.accountNum?.message}>
                <Select
                  options={accounts}
                  className={styles.select}
                  {...register('accountNum', { required: 'Required' })}
                />
              </InputRow>
              <Button
                className={styles.button}
                onClick={(event) => {
                  event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
                  event.preventDefault(); // Prevents nested embedded links to be fired
                  history.push({
                    search: QueryString.stringify({
                      ...QueryString.parse(location.search, {
                        ignoreQueryPrefix: true,
                      }),
                      action: 'editAccount',
                    }),
                    state: { accountIdentifier: '8899' },
                  });
                }}
              >
                <Icon name="externalLink" />
              </Button>
              <Button
                className={styles.button}
                onClick={(event) => {
                  event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
                  event.preventDefault(); // Prevents nested embedded links to be fired
                  history.push({
                    search: QueryString.stringify({
                      ...QueryString.parse(location.search, {
                        ignoreQueryPrefix: true,
                      }),
                      action: 'removeAccount',
                    }),
                    state: { accountIdentifier: '8899' },
                  });
                }}
              >
                <Icon name="bin" />
              </Button>
              <Button
                leftIcon="plus"
                theme="transparentDark"
                size="small"
                className={styles.addAccountButton}
                onClick={() => setIsAddingAccount(true)}
              >
                Add Account
              </Button>
            </div>
            <InfoBox data={secondaryInfoBoxData} />
            <div>
              <DepositAmountInput
                buttonLabel="Deposit"
                depositType="bank"
                hasExistingAccount={hasExistingAccount}
                getValues={getValues}
                setError={setError}
                register={register}
                errors={errors}
                setValue={setValue}
                isAddingAccount={isAddingAccount}
                setIsAddingAccount={setIsAddingAccount}
              />
            </div>
          </form>
        </Card>
      ) : (
        <form
          onSubmit={handleSubmit(submitAddAccountDeposit)}
          className={styles.form}
        >
          <Card
            className={clsx(styles.container, {
              [styles.singleCard]: hasExistingAccount,
            })}
          >
            <InfoBox data={primaryInfoBoxData} />
            <div className={styles.singleRow}>
              <InputRow
                label="Routing Number"
                helpText={BankHelpText}
                errorText={errors.routingNum?.message}
              >
                <Input
                  type="number"
                  id="Routing Number"
                  autoComplete="off"
                  autoFocus
                  className={styles.input}
                  {...register('routingNum', { required: 'Required' })}
                />
              </InputRow>
              <InputRow
                label="Account Number"
                helpText={BankHelpText}
                errorText={errors.accountNum?.message}
              >
                <Input
                  type="number"
                  id="Account Number"
                  autoComplete="off"
                  className={styles.input}
                  {...register('accountNum', { required: 'Required' })}
                />
              </InputRow>
            </div>
          </Card>
          <Card className={styles.container}>
            <InfoBox data={secondaryInfoBoxData} />
            <DepositAmountInput
              buttonLabel="Add Account &amp; Deposit"
              depositType="bank"
              hasExistingAccount={hasExistingAccount}
              getValues={getValues}
              setError={setError}
              register={register}
              errors={errors}
              setValue={setValue}
              isAddingAccount={isAddingAccount}
              setIsAddingAccount={setIsAddingAccount}
            />
          </Card>
        </form>
      )}
    </>
  );
}
