import { useContext } from 'react';
import Icon from '../../../components/Icon/Icon';
import styles from './ProgressIndicator.module.css';
import SignupContext from './SignupContext';

export function CheckIcon({ active }: { active: boolean }) {
  return (
    <Icon
      name={active ? 'circleTickActive' : 'circleTickDefault'}
      className={styles.icon}
    />
  );
}
export default function ProgressIndicator() {
  const { data } = useContext(SignupContext);

  const isStepOneComplete = Boolean(
    data?.email && data?.password && data?.state
  );
  const isStepTwoComplete = Boolean(
    data?.address && data?.primaryPhone && data?.socialSecurityNumber
  );
  const isStepThreeComplete = Boolean(
    data?.firstName && data?.lastName && data?.dateOfBirth
  );

  return (
    <>
      {}
      <div className={styles.container}>
        <div className={styles.stepContainer}>
          <CheckIcon active={isStepOneComplete} /> Step 1
        </div>
        <div className={styles.stepContainer}>
          <CheckIcon active={isStepTwoComplete} /> Step 2
        </div>
        <div className={styles.stepContainer}>
          <CheckIcon active={isStepThreeComplete} /> Step 3
        </div>
      </div>
    </>
  );
}
