import { useState } from 'react';
import Draggable from 'react-draggable';
import Icon from '../../../components/Icon/Icon';
import useAppState from '../../../hooks/useAppState/useAppState';
import useWindowSize, { Size } from '../../../hooks/useWindowSize';
import styles from './GlobalVision.module.css';

export default function GlobalVision() {
  const {
    liveVision: { visionUrl, setIsGlobalVisionOpen },
    meetings,
  } = useAppState();
  const size: Size = useWindowSize();
  const [selectedMeeting, setSelectedMeeting] = useState('Select a meeting');

  return (
    <Draggable bounds="body">
      <div
        className={styles.visionPopUp}
        style={{
          top: size.height ? size?.height - 400 : 500,
        }}
      >
        <div className={styles.popUpHeader}>
          <div className={styles.flex}>
            <div className={styles.divDragIcon} title="Click and hold to drag">
              <div className={styles.dragIcon} />
            </div>
            <div className={styles.divMeetingSelect}>
              <select
                className={styles.meetingSelect}
                onChange={(event) => setSelectedMeeting(event.target.value)}
                value={selectedMeeting}
              >
                {meetings.map((option) => (
                  <option key={option.id} value={option.track.name}>
                    {option.track.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <button
            type="button"
            className={styles.closeIconContainer}
            onClick={() => setIsGlobalVisionOpen(false)}
            onTouchStart={() => setIsGlobalVisionOpen(false)}
            title="Close"
          >
            <Icon name="cross" className={styles.icon} />
          </button>
        </div>

        <iframe
          title="LiveVision"
          loading="lazy"
          name="videoFrame"
          id="videoFrame"
          frameBorder="0"
          width="400px"
          height="300px"
          src={visionUrl}
          scrolling="no"
          referrerPolicy="origin"
          allowFullScreen
        />
      </div>
    </Draggable>
  );
}
