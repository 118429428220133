import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import Modal from '../../../components/Modal/Modal';
import BankHelpText from './BankHelpText';
import { BankForm } from './DepositContext';
import styles from './DepositModal.module.css';

export default function EditAccountModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<BankForm>();

  const submit: SubmitHandler<BankForm> = (formValues) => {
    // console.log(formValues);
    reset();
    onDismiss();
    toast.success(`Account updated`);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <Card className={styles.container}>
        <h1 className={styles.heading}>Edit Account</h1>
        <form onSubmit={handleSubmit(submit)}>
          <div className={styles.inputContainer}>
            <div className={styles.halfWidth}>
              <InputRow
                label="Routing Number"
                helpText={BankHelpText}
                errorText={errors.routingNum?.message}
              >
                <Input
                  type="number"
                  id="Routing Number"
                  autoComplete="off"
                  className={styles.input}
                  {...register('routingNum', { required: 'Required' })}
                />
              </InputRow>
            </div>
            <InputRow
              label="Account Number"
              helpText={BankHelpText}
              errorText={errors.accountNum?.message}
            >
              <Input
                type="number"
                id="Account Number"
                autoComplete="off"
                className={styles.input}
                {...register('accountNum', { required: 'Required' })}
              />
            </InputRow>
          </div>
          <div className={styles.buttonContainer}>
            <Button theme="secondary" type="submit" className={styles.input}>
              Save
            </Button>
            <Button
              theme="transparentDark"
              onClick={onDismiss}
              className={styles.input}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Card>
    </Modal>
  );
}
