import clsx from 'clsx';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import useFormat from '../../hooks/useFormat';
import styles from './OddsButton.module.css';

type Props = InputHTMLAttributes<HTMLButtonElement> & {
  price?: number | null;
  label?: string;
  selected?: boolean;
};

export default function OddsButton({ price, label, selected, ...rest }: Props) {
  const [displayPrice, setDisplayPrice] = useState(price);
  const [animation, setAnimation] = useState('');

  useEffect(() => {
    if (price && !displayPrice) setDisplayPrice(price);
    if (!displayPrice || !price) return;
    if (displayPrice > price) {
      setAnimation('firm');
    } else if (displayPrice < price) {
      setAnimation('drift');
    }

    setDisplayPrice(price);
  }, [price, displayPrice]);

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      setAnimation('');
    }, 1500);

    return () => {
      clearTimeout(animationTimer);
    };
  }, [animation]);

  const format = useFormat();
  const displayLabel = label || '-';

  return (
    <>
      <button
        className={clsx(
          styles.button,
          {
            [styles.selected]: selected,
          },
          styles[animation]
        )}
        {...rest}
        type="button"
      >
        {price ? format.odds(price) : displayLabel}
      </button>
    </>
  );
}
