import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OddsboardSelectionType } from '../../constants/oddsboardTypes';
import raceStatuses from '../../constants/raceStatuses';
import { Auth } from '../../types/auth';
import { Config } from '../../types/window';
import BetslipProvider from '../useBetslip/BetslipProvider';
import useLocalStorage from '../useLocalStorage';
import { NextToJump } from '../useMeetings/types';
import useMeetings from '../useMeetings/useMeetings';
import AppStateContext, { AppSettings } from './AppStateContext';

type Props = {
  config: Config;
  children: React.ReactNode;
};

const now = dayjs();

const defaultSettings: AppSettings = {
  oddsType: 'DECIMAL',
  showRunnerInfo: false,
  showOddsboard: false,
  showShortForm: false,
  shortFormTab: 'runner',
  oddsboardSelected: OddsboardSelectionType.ALL_POOLS,
  selectedPriceType: 'tote',
  upcomingExpanded: false,
  showBalance: true,
};

function useMergedSettings<T>(baseSettings: T, newSettings: T): T {
  const value = useMemo(
    () => ({
      ...baseSettings,
      ...newSettings,
    }),
    [baseSettings, newSettings]
  );

  return value;
}

function AppProvider({ config, children }: Props) {
  const intervalRef = useRef(0);
  const [sessionStartTime] = useState(now);
  const [currentTime, setCurrentTime] = useState(now);
  const [isGlobalVisionOpen, setIsGlobalVisionOpen] = useState(false);
  const [countryFilters, setCountryFilters] = useState<string[]>([]);
  const [user, setUser] = useState<Auth>();

  const meetings = useMeetings(
    sessionStartTime.subtract(12, 'hours'),
    sessionStartTime.add(24, 'hours')
  );
  const nextToJump = useMemo<NextToJump[]>(
    () =>
      meetings
        ?.flatMap((meeting) =>
          meeting.races
            .filter((item) => item.status === raceStatuses.OPEN)
            .map((item) => ({ ...item, meeting }))
        )
        .sort((a, b) =>
          dayjs(a?.startTime).isBefore(b?.startTime || '') ? -1 : 1
        ) || [],
    [meetings]
  );

  const [settings, setSettings] = useLocalStorage<AppSettings>(
    'appSettings',
    defaultSettings
  );
  const mergedSettings = useMergedSettings(defaultSettings, settings);

  // TODO: add to firebase config
  const visionUrl =
    'https://vision.prod.thebetmakers.com/vision/stream/1?bm_id=2&theme_id=2';

  useEffect(() => {
    function updateTime() {
      setCurrentTime(dayjs());
    }

    intervalRef.current = window.setInterval(updateTime, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  if (!meetings) return null;

  return (
    <AppStateContext.Provider
      value={{
        config,
        currentTime,
        meetings,
        nextToJump,
        settings: mergedSettings,
        updateSettings: setSettings,
        user,
        setUser,
        liveVision: {
          isGlobalVisionOpen,
          setIsGlobalVisionOpen,
          visionUrl,
        },
        countryFilter: {
          countryFilters,
          setCountryFilters,
        },
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

export default function AppStateProvider({ config, children }: Props) {
  const coreApiUrl = useMemo(() => {
    const baseUrl = new URL(config.coreApiUrl);
    return `${baseUrl?.hostname}${baseUrl.pathname}` || '';
  }, [config.coreApiUrl]);

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: `https://${coreApiUrl}`,
    });

    const wsLink = new WebSocketLink({
      uri: `wss://${coreApiUrl}`,
      options: {
        reconnect: true,

        connectionParams: async () => {
          const headers = (await config?.getHeaders()) || {};
          return headers;
        },
      },
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );

    const authLink = setContext(async (_, { headers }) => {
      const additionalHeaders = (await config?.getHeaders()) || {};

      return {
        headers: {
          ...headers,
          ...additionalHeaders,
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message }) =>
          /* eslint-disable-next-line no-console */
          console.log(`[GraphQL Error]: ${message}`)
        );
      }

      if (networkError) {
        /* eslint-disable-next-line no-console */
        console.log(`[Network error]: ${JSON.stringify(networkError)}`);
      }
    });

    const link = ApolloLink.from([authLink, errorLink, splitLink]);

    return new ApolloClient({
      cache: new InMemoryCache(),
      link,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
      },
    });
  }, [config, coreApiUrl]);

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <AppProvider config={config}>
          <BetslipProvider>{children}</BetslipProvider>
          <ToastContainer position="bottom-center" />
        </AppProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}
