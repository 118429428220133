export enum AppEvent {
  BETSLIP = 'bm__betslip',
}

export enum BetslipEventType {
  ADD = 'add',
  ADD_OR_REMOVE = 'add-or-remove',
  ADD_EXOTIC = 'add-exotic',
  ADD_MULTI_EXOTIC = 'add-multi-exotic',
  REMOVE = 'remove',
  REMOVE_TRADITIONAL = 'remove-traditional',
  CLEAR = 'clear',
  UPDATE_TRADITIONAL_SINGLE_STAKE = 'update-traditional-single-stake',
  UPDATE_ALL_SINGLES_STAKE = 'update-all-singles-stake',
  UPDATE_ALL_EXOTICS_STAKE = 'update-all-exotics-stake',
  UPDATE_ALL_MULTIS_STAKE = 'update-all-multis-stake',
  RESET_EXOTIC_SELECTIONS = 'reset-exotic-selections',
  SET_EXOTIC_BOXED = 'set-exotic-boxed',
  UPDATE_EXOTIC_SELECTION = 'update-exotic-selection',
  UPDATE_EXOTIC_SELECTION_ALL = 'update-exotic-selection-all',
  RESET_MULTI_SELECTIONS = 'reset-multi-selections',
  UPDATE_MULTI_RACES = 'update-multi-races',
  UPDATE_MULTI_SELECTION = 'update-multi-selection',
  UPDATE_MULTI_SELECTION_ALL = 'update-multi-selection-all',
  TOGGLE_BETSLIP = 'toggle-betslip',
}
