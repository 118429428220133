import styles from './SignupModal.module.css';

export default function SignupHeader() {
  return (
    <div className={styles.header}>
      <h1>Create your account</h1>
      <p className={styles.subHeading}>
        Join us to wager, watch and win on the biggest events in racing.
      </p>
    </div>
  );
}
