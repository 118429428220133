import Icon from '../../../components/Icon/Icon';
import useBetslip from '../../../hooks/useBetslip/useBetslip';
import useFormat from '../../../hooks/useFormat';
import AdditionalWagers from '../AdditionalWagers/AdditionalWagers';
import SetStakeInput from './SetStakeInput';
import styles from './SingleBetCard.module.css';
import { TraditionalSingleBet } from './types';

export default function SingleBetCard({ bet }: { bet: TraditionalSingleBet }) {
  const { betType, race, meeting, selections, stake } = bet;
  const format = useFormat();
  const { removeBetByBetTypeRace } = useBetslip();

  return (
    <>
      <div className={styles.betItem}>
        <div className={styles.header}>
          <Icon name={meeting.type} className={styles.icon} size="medium" />
          <span className={styles.betType}>{betType}</span>
          <span className={styles.stake}>
            {format.currency((stake || 0) * selections.length)}
          </span>
          <div>
            <button
              type="button"
              className={styles.removeButton}
              onClick={() =>
                removeBetByBetTypeRace({
                  raceId: race.id,
                  betType,
                })
              }
            >
              <Icon name="cross" size="small" />
            </button>
          </div>
        </div>
        <span className={styles.race}>
          {meeting.track.name} R{race.number}
        </span>

        <div className={styles.selections}>
          {selections
            .reduce<{
              consecutive?: number;
              labels: string[];
            }>(
              (acc, competitor, index) => {
                const nextTabNo = selections?.[index + 1]?.tabNo;

                // Start consecutive
                if (!acc.consecutive && nextTabNo === competitor.tabNo + 1) {
                  return {
                    ...acc,
                    consecutive: competitor.tabNo,
                  };
                }

                // Ignore consecutive
                if (acc.consecutive && nextTabNo === competitor.tabNo + 1) {
                  return acc;
                }

                // Close consecutive
                if (acc.consecutive && nextTabNo !== competitor.tabNo + 1) {
                  return {
                    ...acc,
                    consecutive: undefined,
                    labels: [
                      ...acc.labels,
                      `${acc.consecutive} - ${competitor.tabNo}`,
                    ],
                  };
                }

                return {
                  ...acc,
                  labels: [
                    ...acc.labels,
                    `${competitor.tabNo}. ${competitor.name}`,
                  ],
                };
              },
              {
                labels: [],
              }
            )
            .labels.join(', ')}
        </div>
        <SetStakeInput bet={bet} />
      </div>
      <AdditionalWagers bet={bet} />
    </>
  );
}
