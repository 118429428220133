import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useRouteMatch } from 'react-router-dom';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import betTypes, { BetType } from '../../../constants/betTypes';
import useAppState from '../../../hooks/useAppState/useAppState';
import useMultiBetslip from '../../../hooks/useBetslip/useMultiBetslip';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import { RaceDetailedCompetitor } from '../../../hooks/useRace/types';
import useRace from '../../../hooks/useRace/useRace';
import useRemoteConfig from '../../../hooks/useRemoteConfig/useRemoteConfig';
import { sortFn } from '../../../utils/race/common';
import { SortByKey } from '../../../utils/race/types';
import LiveVision from '../LiveVision/LiveVision';
import styles from './Race.module.css';
import RaceBetType from './RaceBetType/RaceBetType';
import RaceContext from './RaceContext';
import RaceHeader from './RaceHeader';
import RaceResult from './RaceResult';

export default function Race() {
  const [isLiveVisionOpen, setIsLiveVisionOpen] = useState(false);
  const [sortBy, setSortBy] = useState(SortByKey.NAME);
  const [sortDesc, setSortDesc] = useState(true);
  const [multiRunnerLength, setMultiRunnerLength] = useState(0);
  const [runnerHeight, setRunnerHeight] = useState<Record<string, number>>({});

  const { meetings } = useAppState();
  const { liveVisionEnabled } = useRemoteConfig();
  const history = useHistory();
  const match = useRouteMatch<{
    raceId: string;
    betType: string;
  }>();
  const { race, loading } = useRace(match.params.raceId);
  const { isMultiBetType } = useMultiBetslip();

  const route = useMemo(() => {
    if (race) {
      return [
        {
          label: 'Racing',
          url: '/racing',
        },
        {
          label: race.meeting.track.name,
          url: `/racing/meeting/${race.meeting.id}`,
        },
        {
          label: `Race ${race?.number}`,
          url: `/racing/race/${race.id}/${match.params.betType}`,
        },
      ];
    }
    return [];
  }, [race, match]);

  useBreadcrumbs(route);

  const updateMultiRunnerLength = useCallback(
    (count: number, force?: boolean) => {
      if (force || count > multiRunnerLength) {
        setMultiRunnerLength(count);
      }
    },
    [multiRunnerLength]
  );

  useEffect(() => {
    if (race?.competitors) {
      updateMultiRunnerLength(race?.competitors.length);
    }
  }, [race?.competitors, updateMultiRunnerLength]);

  const betType = useMemo(() => {
    switch (match.params.betType) {
      case 'place':
        return BetType.PLACE;
      case 'show':
        return BetType.SHOW;
      case 'exacta':
        return BetType.EXACTA;
      case 'quinella':
        return BetType.QUINELLA;
      case 'trifecta':
        return BetType.TRIFECTA;
      case 'superfecta':
        return BetType.SUPERFECTA;
      case 'double':
        return BetType.DOUBLE;
      case 'pick3':
        return BetType.PICK3;
      case 'pick6':
        return BetType.PICK6;
      case 'win':
      default:
        return BetType.WIN;
    }
  }, [match.params.betType]);

  const boolToNum = useCallback((bool: boolean) => (bool ? 1 : 0), []);
  const sortScratchedRunnersLast = useCallback(
    (a: RaceDetailedCompetitor, b: RaceDetailedCompetitor) =>
      boolToNum(a.scratched) - boolToNum(b.scratched),
    [boolToNum]
  );

  const sortedCompetitors = useMemo(() => {
    if (isMultiBetType(betType)) {
      return race?.competitors || [];
    }

    return [...(race?.competitors || [])] // needed as .sort mutates the original array
      .sort((a, b) => sortFn(a, b, sortBy, sortDesc))
      .sort(sortScratchedRunnersLast);
  }, [
    race,
    isMultiBetType,
    betType,
    sortBy,
    sortDesc,
    sortScratchedRunnersLast,
  ]);

  if (!race) {
    return null;
  }

  const hasResults = race?.results ? race.results.length > 0 : false;
  const showLiveVision = !!(liveVisionEnabled && isLiveVisionOpen);

  const HeaderComponent = race && (
    <RaceHeader
      race={race}
      meeting={race.meeting}
      meetings={meetings}
      onChangeMeeting={(meetingId) =>
        history.push(`/racing/meeting/${meetingId}`)
      }
      onChangeRace={(raceId, type = 'win') =>
        history.push(`/racing/race/${raceId}/${type}`)
      }
      handleLiveVisionToggle={() => setIsLiveVisionOpen((prev) => !prev)}
      hasResults={hasResults}
    />
  );

  const tabs = [
    {
      label: `${betTypes.WIN} / ${betTypes.PLACE} / ${betTypes.SHOW}`,
      url: `/racing/race/${race?.id}/win`,
      desktopOnly: true,
    },
    {
      label: betTypes.WIN,
      url: `/racing/race/${race?.id}/win`,
      mobileOnly: true,
    },
    {
      label: betTypes.PLACE,
      url: `/racing/race/${race?.id}/place`,
      mobileOnly: true,
    },
    {
      label: betTypes.SHOW,
      url: `/racing/race/${race?.id}/show`,
      mobileOnly: true,
    },
    {
      label: betTypes.SUPERFECTA,
      url: `/racing/race/${race?.id}/superfecta`,
    },
    {
      label: betTypes.EXACTA,
      url: `/racing/race/${race?.id}/exacta`,
    },
    {
      label: betTypes.QUINELLA,
      url: `/racing/race/${race?.id}/quinella`,
    },
    {
      label: betTypes.TRIFECTA,
      url: `/racing/race/${race?.id}/trifecta`,
    },
    {
      label: betTypes.DOUBLE,
      url: `/racing/race/${race?.id}/double`,
    },
    {
      label: betTypes.PICK3,
      url: `/racing/race/${race?.id}/pick3`,
    },
    {
      label: betTypes.PICK6,
      url: `/racing/race/${race?.id}/pick6`,
    },
  ];

  return (
    <>
      <Helmet title={`${race?.meeting.track.name} R${race?.number}`}>
        <meta name="description" content="Racing" />
      </Helmet>
      <div
        className={clsx(styles.container, {
          [styles.locked]: loading,
        })}
      >
        {showLiveVision && (
          <LiveVision setIsLiveVisionOpen={setIsLiveVisionOpen} />
        )}
        <HomeContainer HeaderComponent={HeaderComponent} tabs={tabs}>
          <RaceContext.Provider
            value={{
              race,
              betType,
              sortBy,
              sortDesc,
              setSortBy,
              setSortDesc,
              sortedCompetitors,
              multiRunnerLength,
              updateMultiRunnerLength,
              runnerHeight,
              setRunnerHeight,
            }}
          >
            {hasResults && <RaceResult race={race} />}
            <RaceBetType />
          </RaceContext.Provider>
        </HomeContainer>
      </div>
    </>
  );
}
