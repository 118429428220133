import clsx from 'clsx';
import { Link } from 'react-router-dom';
import FixedOddsBadge from '../../../../components/Badge/FixedOddsBadge';
import Countdown from '../../../../components/Countdown/Countdown';
import CountryFlag from '../../../../components/CountryFlag/CountryFlag';
import HorizontalOverflow from '../../../../components/HorizontalOverflow/HorizontalOverflow';
import Icon from '../../../../components/Icon/Icon';
import useFormat from '../../../../hooks/useFormat';
import { NextToJump } from '../../../../hooks/useMeetings/types';
import styles from './RaceUpcoming.module.css';

export default function RaceUpcoming({
  races,
  title,
}: {
  races: NextToJump[];
  title: string;
}) {
  return (
    <HorizontalOverflow>
      <div className={styles.grid}>
        <span className={clsx(styles.sortLabel, styles.spacing)}>{title}</span>
        <span className={clsx(styles.sortLabel, styles.right)}>
          POOL JACKPOT AMOUNT
        </span>
        <span />
        <span className={clsx(styles.sortLabel, styles.right, styles.spacing)}>
          MTP
        </span>
        {races.length > 0 ? (
          races.map((race) => (
            <RaceUpcomingGridItem race={race} key={race.id} />
          ))
        ) : (
          <span className={styles.empty}>No upcoming races available.</span>
        )}
      </div>
    </HorizontalOverflow>
  );
}

function RaceUpcomingGridItem({ race }: { race: NextToJump }) {
  const format = useFormat();

  return (
    <>
      <Link to={`/racing/race/${race.id}/win`} className={styles.link}>
        <div className={styles.raceDetailContainer}>
          <Icon
            name={race.meeting.type}
            className={styles.icon}
            size="medium"
          />
          <div>
            <div className={styles.raceDetail}>
              <span className={styles.raceName}>{race.meeting.track.name}</span>
              <span className={styles.raceNumber}>R{race.number}</span>
            </div>
            <span className={styles.country}>
              <CountryFlag
                countryCode={race.meeting.track.country}
                size="small"
              />
              {race.meeting.track.country}
            </span>
          </div>
        </div>
      </Link>
      <div className={styles.racePool}>
        <span className={styles.pool}>{format.currency(race.prizeMoney)}</span>
      </div>
      <div className={styles.legend}>
        <Icon name="dollarSign" size="small" className={styles.dollarIcon} />
        {race.fixedAvailable ? (
          <span className={styles.tooltip}>
            <FixedOddsBadge />
          </span>
        ) : null}
        {race.meeting.hasVision ? (
          <span className={styles.tooltip}>
            <Icon name="watch" size="small" />
          </span>
        ) : null}
      </div>
      <div className={styles.countdown}>
        <Countdown startTime={race.startTime} />
      </div>
      <div className={styles.separator} />
    </>
  );
}
