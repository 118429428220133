import clsx from 'clsx';
import QueryString from 'qs';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import Select from '../../../components/Form/Select/Select';
import Icon from '../../../components/Icon/Icon';
import InfoBox from '../../../components/InfoBox/InfoBox';
import BankHelpText from '../Deposit/BankHelpText';
import DepositAmountInput from '../Deposit/DepositAmountInput';
import { BankForm } from '../Deposit/DepositContext';
import styles from './BankWithdraw.module.css';
import WithdrawContext, { BankWithdrawForm } from './WithdrawContext';

const infoBoxData = [
  { title: 'Transaction Limit', value: '$1 - $500' },
  {
    title: 'Fees',
    value: 'None',
  },
  {
    title: 'Availability',
    value: '5 - 8 business days',
  },
];

const accounts = [
  {
    value: '8899',
    label: 'Wells Fargo *****8899',
  },
  {
    value: '5432',
    label: 'Bank of America *****5432',
  },
];

export default function BankWithdraw({
  hasExistingAccount,
}: {
  hasExistingAccount: boolean;
}) {
  const { data } = useContext(WithdrawContext);
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    setError,
    reset,
  } = useForm<BankWithdrawForm>({
    defaultValues: data,
  });

  const submitExistingAccountWithdrawal: SubmitHandler<BankWithdrawForm> = (
    formValues
  ) => {
    // console.log('Withdrawal with existing account', formValues);
    reset();
    toast.success(`Withdrawal request made`);
  };

  const submitAddAccountWithdrawal: SubmitHandler<BankForm> = (formValues) => {
    // console.log('Add Account and Withdraw', formValues);
    reset();
    setIsAddingAccount(false);
    toast.success(`Withdrawal request made`);
  };

  return (
    <>
      {hasExistingAccount && !isAddingAccount ? (
        <Card
          className={clsx(styles.container, {
            [styles.singleCard]: hasExistingAccount,
          })}
        >
          <InfoBox data={infoBoxData} />
          <form
            onSubmit={handleSubmit(submitExistingAccountWithdrawal)}
            className={styles.form}
          >
            <div className={styles.existingAccount}>
              <InputRow label="Account" errorText={errors.accountNum?.message}>
                <Select
                  options={accounts}
                  className={styles.select}
                  {...register('accountNum', { required: 'Required' })}
                />
              </InputRow>
              <Button
                className={styles.button}
                onClick={(event) => {
                  event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
                  event.preventDefault(); // Prevents nested embedded links to be fired
                  history.push({
                    search: QueryString.stringify({
                      ...QueryString.parse(location.search, {
                        ignoreQueryPrefix: true,
                      }),
                      action: 'editAccount',
                    }),
                    state: { accountIdentifier: '8899' },
                  });
                }}
              >
                <Icon name="externalLink" />
              </Button>
              <Button
                className={styles.button}
                onClick={(event) => {
                  event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
                  event.preventDefault(); // Prevents nested embedded links to be fired
                  history.push({
                    search: QueryString.stringify({
                      ...QueryString.parse(location.search, {
                        ignoreQueryPrefix: true,
                      }),
                      action: 'removeAccount',
                    }),
                    state: { accountIdentifier: '8899' },
                  });
                }}
              >
                <Icon name="bin" />
              </Button>
              <Button
                leftIcon="plus"
                theme="transparentDark"
                size="small"
                className={styles.addAccountButton}
                onClick={() => setIsAddingAccount(true)}
              >
                Add Account
              </Button>
            </div>
            <InputRow
              label="Password (for security purposes)"
              errorText={errors.password?.message}
            >
              <Input
                type="password"
                id="Password (for security purposes)"
                autoComplete="password"
                className={styles.input}
                {...register('password', { required: 'Required' })}
              />
            </InputRow>
            <div>
              <DepositAmountInput
                isWithdrawal
                buttonLabel="Withdraw"
                depositType="bank"
                hasExistingAccount={hasExistingAccount}
                getValues={getValues}
                setError={setError}
                register={register}
                errors={errors}
                setValue={setValue}
                isAddingAccount={isAddingAccount}
                setIsAddingAccount={setIsAddingAccount}
              />
            </div>
          </form>
        </Card>
      ) : (
        <form
          onSubmit={handleSubmit(submitAddAccountWithdrawal)}
          className={styles.form}
        >
          <Card
            className={clsx(styles.container, {
              [styles.singleCard]: hasExistingAccount,
            })}
          >
            <InfoBox data={infoBoxData} />
            <div className={styles.singleRow}>
              <InputRow
                label="Routing Number"
                helpText={BankHelpText}
                errorText={errors.routingNum?.message}
              >
                <Input
                  type="number"
                  id="Routing Number"
                  autoComplete="off"
                  autoFocus
                  className={styles.input}
                  {...register('routingNum', { required: 'Required' })}
                />
              </InputRow>
              <InputRow
                label="Account Number"
                helpText={BankHelpText}
                errorText={errors.accountNum?.message}
              >
                <Input
                  type="number"
                  id="Account Number"
                  autoComplete="off"
                  className={styles.input}
                  {...register('accountNum', { required: 'Required' })}
                />
              </InputRow>
            </div>
          </Card>

          <Card className={styles.container}>
            <InputRow
              label="Password (for security purposes)"
              errorText={errors.password?.message}
            >
              <Input
                type="password"
                id="Password (for security purposes)"
                autoComplete="password"
                className={styles.input}
                {...register('password', { required: 'Required' })}
              />
            </InputRow>
            <DepositAmountInput
              isWithdrawal
              buttonLabel="Add Account &amp; Withdraw"
              depositType="bank"
              hasExistingAccount={hasExistingAccount}
              getValues={getValues}
              setError={setError}
              register={register}
              errors={errors}
              setValue={setValue}
              isAddingAccount={isAddingAccount}
              setIsAddingAccount={setIsAddingAccount}
            />
          </Card>
        </form>
      )}
    </>
  );
}
