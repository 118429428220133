import { useContext, useEffect, useRef } from 'react';
import RaceContext from '../../RaceContext';
import styles from './EmptyRunnerDetails.module.css';

export default function EmptyRunnerDetails({ tabNo }: { tabNo: number }) {
  const { setRunnerHeight } = useContext(RaceContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setRunnerHeight((prev) => ({
      ...prev,
      [tabNo]: Math.ceil(ref.current?.getBoundingClientRect().height || 0),
    }));
  }, [setRunnerHeight, tabNo]);

  return (
    <>
      <div className={styles.empty} ref={ref} />
      <div className={styles.empty} />
      <div className={styles.separator} />
    </>
  );
}
