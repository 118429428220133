import Icon from '../Icon/Icon';
import styles from './Checkbox.module.css';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export default function Checkbox({ label, ...rest }: CheckboxProps) {
  return (
    <label className={styles.checkboxWrapper}>
      <input type="checkbox" className={styles.input} {...rest} />
      <div className={styles.checkbox}>
        <Icon name="checkmark" className={styles.icon} />
      </div>
    </label>
  );
}
