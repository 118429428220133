import { createContext } from 'react';
import { BetType } from '../../constants/betTypes';
import { EventDispatcherDetail } from '../../utils/betslip/eventDispatcher';
import { Bet } from '../../utils/betslip/types';
import { Competitor } from '../useRace/types';

export type BetslipContextType = {
  showBetslip: boolean;
  betType: BetType;
  boxed: boolean;
  betslip: Bet[];
  exoticSelections: {
    [BetType.EXACTA]: [Competitor[], Competitor[]];
    [BetType.QUINELLA]: [Competitor[], Competitor[]];
    [BetType.TRIFECTA]: [Competitor[], Competitor[], Competitor[]];
    [BetType.SUPERFECTA]: [
      Competitor[],
      Competitor[],
      Competitor[],
      Competitor[]
    ];
  };
  multiSelections: {
    [BetType.DOUBLE]: {
      races: string[];
      selections: [Competitor[], Competitor[]];
    };
    [BetType.PICK3]: {
      races: string[];
      selections: [Competitor[], Competitor[], Competitor[]];
    };
    [BetType.PICK6]: {
      races: string[];
      selections: [
        Competitor[],
        Competitor[],
        Competitor[],
        Competitor[],
        Competitor[],
        Competitor[]
      ];
    };
  };
};

export const INITIAL_STATE: BetslipContextType = {
  showBetslip: false,
  betType: BetType.WIN,
  boxed: false,
  betslip: [],
  exoticSelections: {
    [BetType.EXACTA]: [[], []],
    [BetType.QUINELLA]: [[], []],
    [BetType.TRIFECTA]: [[], [], []],
    [BetType.SUPERFECTA]: [[], [], [], []],
  },
  multiSelections: {
    [BetType.DOUBLE]: {
      races: [],
      selections: [[], []],
    },
    [BetType.PICK3]: {
      races: [],
      selections: [[], [], []],
    },
    [BetType.PICK6]: {
      races: [],
      selections: [[], [], [], [], [], []],
    },
  },
};

const BetslipContext = createContext<{
  state: BetslipContextType;
  dispatch: React.Dispatch<EventDispatcherDetail>;
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

export default BetslipContext;
