import { useMutation } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import InPlayBadge from '../../../components/Badge/InPlayBadge';
import WithdrawableBadge from '../../../components/Badge/WithdrawableBadge';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import useAppState from '../../../hooks/useAppState/useAppState';
import useFormat from '../../../hooks/useFormat';
import useProxy from '../../../hooks/useProxy';
import styles from './AccountTray.module.css';

export default function AccountTray() {
  const location = useLocation();
  const history = useHistory();
  const { user, setUser, updateSettings, settings } = useAppState();
  const axios = useProxy();
  const format = useFormat();

  const auth = useMutation(
    async () => {
      const response = await axios.delete('/api/v1/falcon/auth');
      return response.status;
    },
    {
      onSuccess: () => {
        setUser(undefined);
        toast.success('You have logged out.');

        if (location.pathname.includes('account')) {
          history.push('/');
        }
      },
    }
  );

  async function onLogout() {
    await auth.mutate();
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.greeting}>Hi {user?.firstName} 👋</span>
        <Button
          theme="none"
          rightIcon={settings.showBalance ? 'eyeHide' : 'eyeShow'}
          onClick={() =>
            updateSettings((prevSettings) => ({
              ...prevSettings,
              showBalance: !prevSettings.showBalance,
            }))
          }
        >
          <span className={styles.balance}>Balance</span>
        </Button>
      </div>
      <div className={styles.balanceContainer}>
        <div className={styles.grid}>
          <span className={styles.label}>Account Balance</span>
          <span className={styles.value}>{format.currency(1000)}</span>
          <span className={styles.label}>
            In Play <InPlayBadge />
          </span>
          <span className={styles.value}>{format.currency(200)}</span>
          <span className={styles.label}>
            Withdrawable <WithdrawableBadge />
          </span>
          <span className={styles.value}>{format.currency(900)}</span>
        </div>
        <div className={styles.actions}>
          <Button
            leftIcon="DEPOSIT"
            onClick={() => history.push('/account/deposit')}
          >
            Deposit
          </Button>
          <Button
            leftIcon="WITHDRAWAL"
            onClick={() => history.push('/account/withdraw')}
          >
            Withdraw
          </Button>
        </div>
      </div>
      <div className={styles.footer}>
        <Link className={styles.link} to="/account/my-bets/pending">
          <Icon name="timer" />
          <div className={styles.buttonContent}>
            <span>Pending Bets</span>
            <Icon name="caretRight" />
          </div>
        </Link>
        <Link className={styles.link} to="/account/my-bets/transaction-history">
          <Icon name="result" />
          <div className={styles.buttonContent}>
            <span>Results</span>
            <Icon name="caretRight" />
          </div>
        </Link>
        <Link className={styles.link} to="/account/overview">
          <Icon name="account" />
          <div className={styles.buttonContent}>
            <span>My Account</span>
            <Icon name="caretRight" />
          </div>
        </Link>
        <Button
          theme="none"
          leftIcon="logout"
          className={styles.button}
          onClick={onLogout}
          disabled={auth.isLoading}
        >
          Logout
        </Button>
      </div>
    </div>
  );
}
