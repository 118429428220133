import { RefObject, useEffect, useState } from 'react';

export function useMeasure(
  ref: RefObject<HTMLElement>
): DOMRectReadOnly | null {
  const [dimensions, setDimensions] = useState<DOMRectReadOnly | null>(null);

  useEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver(([entry]) =>
        setDimensions(entry.contentRect)
      );

      const { current } = ref; // enclose current so the reference can be used in the unsubscribe
      if (current) {
        resizeObserver.observe(current);
        return () => resizeObserver.unobserve(current);
      }
    }

    return undefined;
  }, [ref]);

  return dimensions;
}
