import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import Meeting from './Meeting/Meeting';
import MeetingResult from './Meeting/MeetingResult';
import Race from './Race/Race';
import RacingHome from './RacingHome/RacingHome';
import RacingResults from './RacingHome/RacingResults/RacingResults';

export default function Racing() {
  return (
    <>
      <Helmet titleTemplate="%s · Racing · BetMakers">
        <meta name="description" content="Racing" />
      </Helmet>
      <Switch>
        <Route path="/racing/home" component={RacingHome} />
        <Route exact path="/racing/meeting/:meetingId" component={Meeting} />
        <Route
          path="/racing/results/meeting/:meetingId"
          component={MeetingResult}
        />
        <Route path="/racing/race/:raceId/:betType" component={Race} />
        <Route path="/racing/results/race/:raceId" component={RacingResults} />
        <Redirect to="/racing/home/today" />
      </Switch>
    </>
  );
}
