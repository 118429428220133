import Icon from '../Icon/Icon';
import Badge from './Badge';

export default function WithdrawableBadge() {
  return (
    <Badge Component={<Icon name="info" size="small" />}>
      To stop money laundring, there is a legal requirement to ‘turn over’ the
      funds you have deposited before you can withdraw them. To turn over funds,
      simply means to place the funds on a bet.
    </Badge>
  );
}
