import { Redirect } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import useFormat from '../../../hooks/useFormat';
import { Dividend, Result } from '../../../hooks/useRace/types';
import { UseRaceData } from '../../../hooks/useRace/useRace';
import RunnerDetails from './RaceBetType/RunnerSelection/RunnerDetails';
import styles from './RaceResult.module.css';

export default function RaceResult({ race }: { race: UseRaceData }) {
  const format = useFormat();

  function checkLastResult(i: number, pos: number) {
    return i + 1 !== pos;
  }

  function calculateQuinella(dividends: Dividend[] | undefined) {
    return dividends
      ?.find((a) => a.type === 'QUINELLA')
      ?.dividendNumbers?.join?.(', ');
  }

  function calculateQuinellaDividend(results: Result[] | undefined) {
    const dividends = results?.find((a) => a.source === 'V')?.dividends;
    return dividends?.find((b) => b.type === 'QUINELLA')?.dividend;
  }

  function calculateExacta(dividends: Dividend[] | undefined) {
    return dividends
      ?.find((a) => a.type === 'EXACTA')
      ?.dividendNumbers?.join?.(', ');
  }

  function calculateExactaDividend(results: Result[] | undefined) {
    const dividends = results?.find((a) => a.source === 'V')?.dividends;
    return dividends?.find((b) => b.type === 'EXACTA')?.dividend;
  }

  function calculateTrifecta(dividends: Dividend[] | undefined) {
    return dividends
      ?.find((a) => a.type === 'TRIFECTA')
      ?.dividendNumbers?.join?.(', ');
  }

  function calculateTrifectaDividend(results: Result[] | undefined) {
    const dividends = results?.find((a) => a.source === 'V')?.dividends;
    return dividends?.find((b) => b.type === 'TRIFECTA')?.dividend;
  }

  function calculateFirstFour(dividends: Dividend[] | undefined) {
    return dividends
      ?.find((a) => a.type === 'FIRST_FOUR')
      ?.dividendNumbers?.join?.(', ');
  }

  function calculateFirstFourDividend(results: Result[] | undefined) {
    const dividends = results?.find((a) => a.source === 'V')?.dividends;
    return dividends?.find((b) => b.type === 'FIRST_FOUR')?.dividend;
  }

  function calculateDividendWIN(pos: number, results: Result[] | undefined) {
    let dividend: number;
    dividend = 0;
    const dividends = results?.find((a) => a.source === 'V')?.dividends;
    dividends?.every(
      (b: { type: string; dividend: number; dividendNumbers: any[] }) => {
        if (b.dividendNumbers.length === 1) {
          if (b.dividendNumbers[0] === pos) {
            if (b.type === 'WIN') {
              dividend = b.dividend;
              return false;
            }
            dividend = b.dividend;
          }
        }
        return true;
      }
    );
    return dividend.toFixed(2);
  }

  function calculateDividendPLC(pos: number, results: Result[] | undefined) {
    let dividend: number;
    dividend = 0;
    const dividends = results?.find((a) => a.source === 'V')?.dividends;
    dividends?.every(
      (b: { type: string; dividend: number; dividendNumbers: any[] }) => {
        if (b.dividendNumbers.length === 1) {
          if (b.dividendNumbers[0] === pos) {
            if (b.type === 'PLACE') {
              dividend = b.dividend;
              return false;
            }
            dividend = b.dividend;
          }
        }
        return true;
      }
    );
    return dividend.toFixed(2);
  }

  function calculateDividendSHW(pos: number, results: Result[] | undefined) {
    let dividend: number;
    dividend = 0;
    const dividends = results?.find((a) => a.source === 'V')?.dividends;
    dividends?.every(
      (b: { type: string; dividend: number; dividendNumbers: any[] }) => {
        if (b.dividendNumbers.length === 1) {
          if (b.dividendNumbers[0] === pos) {
            // Change to SHOW when we have show dividends
            if (b.type === 'PLACE') {
              dividend = b.dividend;
              return false;
            }
            dividend = b.dividend;
          }
        }
        return true;
      }
    );
    return dividend.toFixed(2);
  }

  if (race?.results.length === 0) {
    return <Redirect to={`/racing/race/${race.id}/win`} />;
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.resultHeader}>RESULTS</div>
        <Button>
          <Icon name="watch" />
          <div className={styles.replayHeader}>Replay</div>
        </Button>
      </div>
      <div className={styles.resultContainer}>
        {race?.results[race?.results.length - 1]?.positions.map(
          (pos, index) => {
            const competitor = race?.competitors.find(
              (c) => c.tabNo === pos.tabNo
            );

            if (!competitor) return null;

            return (
              <div key={`${race.id}-${pos.tabNo}`}>
                <div className={styles.runnerContainer}>
                  <RunnerDetails
                    race={race}
                    competitor={competitor}
                    position={pos.position}
                    hideForm
                  />
                  <div className={styles.resultsContainer}>
                    {pos.position === 1 ? (
                      <div className={styles.resultsInfo}>
                        <div className={styles.exoticsName}>Win</div>
                        <div className={styles.exoticsPrice}>
                          {calculateDividendWIN(pos.tabNo, race?.results)}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.exoticsPrice} />
                    )}
                    {pos.position <= 2 ? (
                      <div className={styles.resultsInfo}>
                        <div className={styles.exoticsName}>Place</div>
                        <div className={styles.exoticsPrice}>
                          {calculateDividendPLC(pos.tabNo, race?.results)}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.exoticsPrice} />
                    )}
                    {pos.position <= 3 ? (
                      <div className={styles.resultsInfo}>
                        <div className={styles.exoticsName}>Show</div>
                        <div className={styles.exoticsPrice}>
                          {calculateDividendSHW(pos.tabNo, race?.results)}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.exoticsPrice} />
                    )}
                  </div>
                </div>
                {checkLastResult(
                  index,
                  race?.results[race?.results.length - 1].positions.length
                ) ? (
                  <div className={styles.resultSeparator} />
                ) : (
                  <div />
                )}
              </div>
            );
          }
        )}
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.resultHeader}>EXOTICS</div>
        <div className={styles.resultBookie}>VIC</div>
      </div>
      <div className={styles.resultContainer}>
        <div className={styles.exoticsContainer}>
          <div className={styles.runnerInfo}>
            <div className={styles.exoticsName}>Quinella</div>
            <div className={styles.exoticsResult}>
              {calculateQuinella(
                race?.results[race?.results.length - 1]?.dividends
              )}
            </div>
          </div>
          <div className={styles.runnerInfo}>
            <div className={styles.exoticsPrice}>
              {format.odds(calculateQuinellaDividend(race?.results))}
            </div>
          </div>
        </div>
        <div className={styles.resultSeparator} />
        <div className={styles.exoticsContainer}>
          <div className={styles.runnerInfo}>
            <div className={styles.exoticsName}>Exacta</div>
            <div className={styles.exoticsResult}>
              {calculateExacta(
                race?.results[race?.results.length - 1]?.dividends
              )}
            </div>
          </div>
          <div className={styles.runnerInfo}>
            <div className={styles.exoticsPrice}>
              {format.odds(calculateExactaDividend(race?.results))}
            </div>
          </div>
        </div>
        <div className={styles.resultSeparator} />
        <div className={styles.exoticsContainer}>
          <div className={styles.runnerInfo}>
            <div className={styles.exoticsName}>Trifecta</div>
            <div className={styles.exoticsResult}>
              {calculateTrifecta(
                race?.results[race?.results.length - 1]?.dividends
              )}
            </div>
          </div>
          <div className={styles.runnerInfo}>
            <div className={styles.exoticsPrice}>
              {format.odds(calculateTrifectaDividend(race?.results))}
            </div>
          </div>
        </div>
        <div className={styles.resultSeparator} />
        <div className={styles.exoticsContainer}>
          <div className={styles.runnerInfo}>
            <div className={styles.exoticsName}>First Four</div>
            <div className={styles.exoticsResult}>
              {calculateFirstFour(
                race?.results[race?.results.length - 1]?.dividends
              )}
            </div>
          </div>
          <div className={styles.runnerInfo}>
            <div className={styles.exoticsPrice}>
              {format.odds(calculateFirstFourDividend(race?.results))}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.headerContainer}>
        <div className={styles.resultHeader}>MULTIPLES</div>
        <div className={styles.resultBookie}>VIC</div>
      </div>
      <div className={styles.resultContainer}>
        <div>
          <div className={styles.multiplesContainer}>
            <div className={styles.runnerInfo}>
              <div className={styles.exoticsName}>Early Quaddies</div>
              <div className={styles.exoticsResult}>Results: 1, 2, 3, 4</div>
              <div className={styles.exoticsResult}>Subs: 3, 2, 13, 12</div>
            </div>
            <div className={styles.runnerInfo}>
              <div className={styles.exoticsPrice}>
                {format.odds(calculateQuinellaDividend(race?.results))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
