import { Helmet } from 'react-helmet-async';
import HeroCarousel from '../../components/HeroCarousel/HeroCarousel';
import NextToJump from '../../components/NextToJump/NextToJump';
import QuickLinks from '../../components/QuickLinks/QuickLinks';

export default function Home() {
  return (
    <>
      <Helmet title="Home">
        <meta name="description" content="Home" />
      </Helmet>
      <HeroCarousel
        items={[
          {
            backgroundImage:
              'https://suffolkotb.netlify.app/assets/carousel_1.jpg',
            title: 'Test 1',
          },
          {
            backgroundImage:
              'https://suffolkotb.netlify.app/assets/carousel_2.jpg',
            title: 'Test 2',
          },
          {
            backgroundImage:
              'https://suffolkotb.netlify.app/assets/carousel_3.jpg',
            title: 'Test 3',
          },
          {
            backgroundImage:
              'https://suffolkotb.netlify.app/assets/carousel_4.jpg',
            title: 'Test 4 ',
          },
          {
            backgroundImage:
              'https://suffolkotb.netlify.app/assets/carousel_5.jpg',
            title: 'Test 5',
          },
        ]}
      />
      <QuickLinks
        items={[
          {
            label: 'Racing',
            icon: 'thoroughbredFilled',
            url: '/racing/#/racing/home/today',
          },
          {
            label: 'Melbourne Cup',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Rewards',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Get $100',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Download App',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Bob Ehault Tips',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Racing 2',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Melbourne Cup 2',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Rewards 2',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Get $100 2',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Download App 2',
            icon: 'thoroughbredFilled',
            url: '/',
          },
          {
            label: 'Bob Ehault Tips 2',
            icon: 'thoroughbredFilled',
            url: '/',
          },
        ]}
      />
      <NextToJump />
    </>
  );
}
