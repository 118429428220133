import Modal from '../../../components/Modal/Modal';
import { ReactComponent as Logo } from './interbets-white.svg';
import styles from './ModalContainer.module.css';

type Props = {
  onDismiss: () => void;
  children: React.ReactNode;
  title?: string;
};

export default function ModalContainer({ title, onDismiss, children }: Props) {
  return (
    <Modal
      onDismiss={onDismiss}
      closeButtonClassName={styles.closeButton}
      className={styles.fullHeight}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Logo />
          {title ? <span className={styles.title}>{title}</span> : null}
        </div>
        {children}
      </div>
    </Modal>
  );
}
