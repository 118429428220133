import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import Icon from '../../../components/Icon/Icon';
import styles from './WithdrawMenu.module.css';
import { WithdrawViews } from './WithdrawTypes';

export default function WithdrawMenu({
  view,
  setView,
}: {
  view: WithdrawViews;
  setView: Dispatch<SetStateAction<WithdrawViews>>;
}) {
  return (
    <div className={styles.withdrawMenu}>
      <div
        className={clsx(styles.menuItemContainer, {
          [styles.selected]: view === 'bank',
        })}
        onClick={() => setView('bank')}
        role="button"
        tabIndex={0}
      >
        <div className={styles.flex}>
          <Icon name="bank" className={styles.rightMargin} />
          <div>ACH Transfer</div>
        </div>
      </div>

      <div
        className={clsx(styles.menuItemContainer, {
          [styles.selected]: view === 'check',
        })}
        onClick={() => setView('check')}
        role="button"
        tabIndex={0}
      >
        <div className={styles.flex}>
          <Icon name="creditCard" className={styles.rightMargin} />
          <div>Check via mail</div>
        </div>
      </div>
    </div>
  );
}
