import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ToggleSwitch from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { ShortFormTab } from '../../../../../hooks/useAppState/AppStateContext';
import useAppState from '../../../../../hooks/useAppState/useAppState';
import { RaceDetailedCompetitor } from '../../../../../hooks/useRace/types';
import { UseRaceData } from '../../../../../hooks/useRace/useRace';
import useRunner from '../../../../../hooks/useRunner/useRunner';
import styles from './ShortForm.module.css';
import ShortFormInfo from './ShortFormInfo';
import Start from './Start';

type Props = {
  competitor: RaceDetailedCompetitor;
  race: UseRaceData;
};

export default function ShortForm({ competitor, race }: Props) {
  const runner = useRunner(competitor.runner.id);
  const { settings, updateSettings } = useAppState();
  const [highlightRelevantRace, setHighlightRelevantRace] = useState(false);
  const handleHighlightRelevantRace = () =>
    setHighlightRelevantRace(!highlightRelevantRace);

  const shortFormTabs: Array<{
    label: string;
    key: ShortFormTab;
  }> = [
    {
      label: 'Runner',
      key: 'runner',
    },
    {
      label: 'Jockey',
      key: 'jockey',
    },
    {
      label: 'Trainer',
      key: 'trainer',
    },
  ];

  const recentStarts = useMemo(
    () =>
      runner?.competitorsConnection?.competitors?.[0].formHistory?.slice?.(
        0,
        4
      ),
    [runner?.competitorsConnection]
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.shortFormTabs}>
          {shortFormTabs.map((tab) => (
            <Link
              key={tab.label}
              to="#"
              className={clsx(styles.shortFormTab, {
                [styles.selected]: tab.key === settings.shortFormTab,
              })}
              onClick={() =>
                updateSettings((prevSettings) => ({
                  ...prevSettings,
                  shortFormTab: tab.key,
                }))
              }
            >
              {tab.label}
            </Link>
          ))}
        </div>

        {settings.shortFormTab === 'runner' && (
          <>
            <ShortFormInfo competitor={competitor} race={race} />
            <div className={styles.shortFormRunnerInfo}>
              <span className={clsx(styles.label, styles.greyLabel)}>
                Recent Starts{' '}
              </span>
              <span
                className={clsx(
                  styles.label,
                  styles.greyLabel,
                  styles.highlightRelevantRace
                )}
              >
                Highlight Relevance to this race
              </span>
              <span>
                <ToggleSwitch
                  value={highlightRelevantRace}
                  changeValue={handleHighlightRelevantRace}
                />
              </span>
            </div>
            <div
              className={clsx(styles.separator, styles.marginBottom, {
                [styles.shortForm]: settings.showShortForm,
              })}
            />
            {recentStarts && recentStarts?.length > 0
              ? recentStarts.map((start, index) => (
                  <Start
                    key={start.date}
                    start={start}
                    startBefore={recentStarts[index + 1]}
                  />
                ))
              : null}
          </>
        )}
      </div>
    </div>
  );
}
