import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions/FormActions';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import SignupContext, { SignupForm } from './SignupContext';
import SignupHeader from './SignupHeader';
import styles from './SignupModal.module.css';

export default function SignupStep2() {
  const history = useHistory();
  const { data, setData } = useContext(SignupContext);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SignupForm>({ defaultValues: data });

  const submit: SubmitHandler<SignupForm> = (formValues) => {
    setData?.((prev) => ({ ...prev, ...formValues }));
    history.push('/step3');
  };

  return (
    <>
      <SignupHeader />
      <form onSubmit={handleSubmit(submit)} className={styles.form}>
        <InputRow label="Address" errorText={errors.address?.message}>
          <Input
            id="Address"
            autoComplete="address"
            autoFocus
            {...register('address', { required: 'Required' })}
          />
        </InputRow>
        <InputRow label="Zip Code" errorText={errors.zipCode?.message}>
          <Input
            id="Zip Code"
            autoComplete="zipCode"
            autoFocus
            {...register('zipCode', { required: 'Required' })}
          />
        </InputRow>
        <InputRow label="Phone Number" errorText={errors.primaryPhone?.message}>
          <Input
            type="tel"
            id="Phone Number"
            autoComplete="tel"
            {...register('primaryPhone', { required: 'Required' })}
          />
        </InputRow>
        <InputRow
          label="Social security number"
          errorText={errors.socialSecurityNumber?.message}
        >
          <Input
            type="password"
            id="Social security number"
            maxLength={9}
            autoComplete="off"
            {...register('socialSecurityNumber', { required: 'Required' })}
          />
        </InputRow>
        <FormActions>
          <Button type="submit" disabled={isSubmitting} theme="secondary">
            Next
          </Button>
        </FormActions>
      </form>
    </>
  );
}
