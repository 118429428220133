import Icon from '../Icon/Icon';
import Badge from './Badge';
import styles from './Badge.module.css';

export default function MandatoryPayoutBadge({ helper }: { helper?: boolean }) {
  return (
    <Badge
      Component={
        <div className={styles.helper}>
          <Icon name="mandatoryPayout" size="small" />
          {helper && (
            <span className={styles.helperText}>Mandatory Payout</span>
          )}
        </div>
      }
    >
      {!helper && <span className={styles.badgeTitle}>Mandatory Payout</span>}
      <span className={styles.details}>
        A Mandatory Payout is when a carry over jackpot has not been won
        previously and the race track must pay out the jackpot.
      </span>
    </Badge>
  );
}
