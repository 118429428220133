import QueryString from 'qs';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import BreadcrumbsProvider from '../../hooks/useBreadcrumbs/BreadcrumbsProvider';
import useRemoteConfig from '../../hooks/useRemoteConfig/useRemoteConfig';
import Account from '../Account/Account';
import EditAccountModal from '../Account/Deposit/EditAccountModal';
import EditCardModal from '../Account/Deposit/EditCardModal';
import RemoveAccountModal from '../Account/Deposit/RemoveAccountModal';
import RemoveCardModal from '../Account/Deposit/RemoveCardModal';
import Home from '../Home/Home';
import Racing from '../Racing/Racing';
import Sports from '../Sports/Sports';
import Layout from './Layout';
import ForgotPasswordModal from './LoginModal/ForgotPasswordModal';
import LoginModal from './LoginModal/LoginModal';
import ResetPasswordModal from './LoginModal/ResetPasswordModal';
import SignupModal from './SignupModal/SignupModal';

export default function App() {
  const location = useLocation();
  const history = useHistory();
  const { racingEnabled, sportsEnabled } = useRemoteConfig();
  const { action, ...remainingQuery } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const dismissModal = useCallback(
    () => history.push({ search: QueryString.stringify(remainingQuery) }),
    [history, remainingQuery]
  );

  return (
    <Layout>
      <Helmet titleTemplate="%s · BetMakers" />
      <BreadcrumbsProvider>
        <Switch>
          <Route exact path="/" component={Home} />
          {racingEnabled && <Route path="/racing" component={Racing} />}
          {sportsEnabled && <Route path="/sports" component={Sports} />}
          <Route path="/account" component={Account} />
        </Switch>
      </BreadcrumbsProvider>
      {action === 'login' && <LoginModal onDismiss={dismissModal} />}
      {action === 'signup' && <SignupModal onDismiss={dismissModal} />}
      {action === 'forgot' && <ForgotPasswordModal onDismiss={dismissModal} />}
      {action === 'reset' && <ResetPasswordModal onDismiss={dismissModal} />}
      {action === 'removeCard' && <RemoveCardModal onDismiss={dismissModal} />}
      {action === 'editCard' && <EditCardModal onDismiss={dismissModal} />}
      {action === 'removeAccount' && (
        <RemoveAccountModal onDismiss={dismissModal} />
      )}
      {action === 'editAccount' && (
        <EditAccountModal onDismiss={dismissModal} />
      )}
    </Layout>
  );
}
