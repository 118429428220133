import meetingTypes from '../../../../../constants/meetingTypes';
import useAppState from '../../../../../hooks/useAppState/useAppState';
import { RaceDetailedCompetitor } from '../../../../../hooks/useRace/types';
import { UseRaceData } from '../../../../../hooks/useRace/useRace';
import styles from './RunnerInfo.module.css';

export default function RunnerInfo({
  race,
  competitor,
}: {
  race: UseRaceData;
  competitor: RaceDetailedCompetitor;
}) {
  const {
    settings: { showRunnerInfo },
  } = useAppState();

  if (!showRunnerInfo) return null;

  switch (race.meeting.type) {
    case meetingTypes.THOROUGHBRED:
      return <Horse competitor={competitor} />;
    case meetingTypes.HARNESS:
      return <Harness competitor={competitor} />;
    case meetingTypes.GREYHOUND:
      return <Greyhound competitor={competitor} />;
    default:
      return null;
  }
}

function Horse({ competitor }: { competitor: RaceDetailedCompetitor }) {
  return (
    <div className={styles.container}>
      <div className={styles.runnerContainer}>
        <span className={styles.long}>
          <span className={styles.label}>Sex: </span>
          {competitor.sex}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Dam: </span>
          {competitor.dam}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Color: </span>
          {competitor.colour}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Sire: </span>
          {competitor.sire}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Age: </span>
          {competitor.age}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Owners: </span>
          {competitor.owners}
        </span>
      </div>
    </div>
  );
}

function Harness({ competitor }: { competitor: RaceDetailedCompetitor }) {
  return (
    <div className={styles.container}>
      <div className={styles.runnerContainer}>
        <span className={styles.long}>
          <span className={styles.label}>Sex: </span>
          {competitor.sex}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Dam: </span>
          {competitor.dam}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Color: </span>
          {competitor.colour}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Sire: </span>
          {competitor.sire}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>A: </span>
          {competitor.age}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Equip: </span>-
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Owners: </span>
          {competitor.owners}
        </span>
      </div>
    </div>
  );
}

function Greyhound({ competitor }: { competitor: RaceDetailedCompetitor }) {
  return (
    <div className={styles.container}>
      <div className={styles.runnerContainer}>
        <span className={styles.long}>
          <span className={styles.label}>Sex: </span>
          {competitor.sex}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Dam: </span>
          {competitor.dam}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Color: </span>
          {competitor.colour}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Sire: </span>
          {competitor.sire}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>A: </span>
          {competitor.age}
        </span>
        <span className={styles.long}>
          <span className={styles.label}>Owners: </span>
          {competitor.owners}
        </span>
      </div>
    </div>
  );
}
