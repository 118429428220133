import QueryString from 'qs';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions/FormActions';
import Modal from '../../../../components/Modal/Modal';
import ModalCard from '../../../../components/Modal/ModalCard';

type Props = {
  onDismiss: () => void;
  saveChanges: (value: boolean) => void;
};

export default function ChangeEmailModal({ onDismiss, saveChanges }: Props) {
  const history = useHistory();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSubmit(value: boolean) {
    if (value) {
      toast.success('Changes saved');
      saveChanges(true);
      history.push({
        search: QueryString.stringify({
          ...QueryString.parse(location.search, {
            ignoreQueryPrefix: true,
          }),
          action: undefined,
        }),
      });
      onDismiss();
      setIsSubmitting(false);
      return true;
    }
    toast.warning('Changes discarded');
    saveChanges(false);
    history.push({
      search: QueryString.stringify({
        ...QueryString.parse(location.search, {
          ignoreQueryPrefix: true,
        }),
        action: undefined,
      }),
    });
    onDismiss();
    setIsSubmitting(false);
    return true;
  }

  return (
    <Modal onDismiss={onDismiss}>
      <ModalCard
        title="Save changes?"
        description="You have changed some of the custom bet amounts. Would you like to save these changes?"
      >
        <FormActions>
          <Button
            disabled={isSubmitting}
            onClick={() => handleSubmit(false)}
            theme="transparentDark"
          >
            Discard Changes
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={() => handleSubmit(true)}
            theme="secondary"
          >
            Save Changes
          </Button>
        </FormActions>
      </ModalCard>
    </Modal>
  );
}
