import axios, { AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions/FormActions';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import useAppState from '../../../hooks/useAppState/useAppState';
import { Auth } from '../../../types/auth';
import SignupContext, { SignupForm } from './SignupContext';
import SignupHeader from './SignupHeader';
import styles from './SignupModal.module.css';

export default function SignupStep3() {
  const history = useHistory();
  const { data, setData } = useContext(SignupContext);
  const [errorMessage, setErrorMessage] = useState('');
  const { setUser } = useAppState();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SignupForm>();

  function handleSuccess() {
    auth.mutate({ username: data?.userName, password: data?.password });
    history.push('/success');
  }
  const registration = useMutation<null, unknown, SignupForm>(
    'Registration',
    async (values) => {
      setErrorMessage('');
      const response = await axios.post<SignupForm, AxiosResponse>(
        'api/v1/falcon/register/register',
        values
      );
      return response.data;
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (error) => {
        setErrorMessage(`error ${error}`);
      },
    }
  );

  const auth = useMutation<Auth, unknown, any | undefined>(
    'Auth',
    async (values) => {
      setErrorMessage('');
      const response = await axios.post<SignupForm, AxiosResponse<Auth>>(
        '/api/v1/falcon/auth',
        values
      );
      return response.data;
    },
    {
      onSuccess: (values) => {
        setUser(values);
        toast.success('You have logged in.');
      },
      onError: () => {
        setErrorMessage('Incorrect username or password. Please try again.');
      },
    }
  );

  const submit: SubmitHandler<SignupForm> = async (formValues) => {
    setData?.((prev) => ({ ...prev, ...formValues }));
    await registration.mutate({ ...data, ...formValues });
  };

  return (
    <>
      {errorMessage}
      <SignupHeader />
      <form onSubmit={handleSubmit(submit)} className={styles.form}>
        <div className={styles.doubleColumn}>
          <InputRow label="First Name" errorText={errors.firstName?.message}>
            <Input
              id="First Name"
              autoComplete="given-name"
              autoFocus
              {...register('firstName', { required: 'Required' })}
            />
          </InputRow>
          <InputRow label="Last Name" errorText={errors.lastName?.message}>
            <Input
              id="Last Name"
              autoComplete="family-name"
              {...register('lastName', { required: 'Required' })}
            />
          </InputRow>
        </div>
        <InputRow
          label="Username (optional)"
          errorText={errors.userName?.message}
        >
          <Input
            type="text"
            id="Username (optional)"
            autoComplete="off"
            {...register('userName')}
          />
        </InputRow>
        <InputRow
          label="Date Of  Birth - Must be 18+"
          errorText={errors.dateOfBirth?.message}
        >
          <Input
            type="date"
            id="Date Of  Birth - Must be 18+"
            autoComplete="bday"
            {...register('dateOfBirth', { required: 'Required' })}
          />
        </InputRow>
        <p className={styles.subText}>
          By Clicking ‘Create Account’ - I agree that I am over 18 and agree to
          receive promotional material from Interbets and its related entities.
          I agree to the Interbets terms and conditions.
        </p>
        <FormActions>
          <Button type="submit" disabled={isSubmitting} theme="secondary">
            Create Account
          </Button>
        </FormActions>
      </form>
    </>
  );
}
