import clsx from 'clsx';
import { isEqual } from 'lodash';
import QueryString from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';
import CardLabel from '../../../../components/Card/CardLabel';
import Select from '../../../../components/Form/Select/Select';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import { OddsDisplayType } from '../../../../hooks/useAppState/AppStateContext';
import useAppState from '../../../../hooks/useAppState/useAppState';
import useFormat from '../../../../hooks/useFormat';
import styles from './BettingSettings.module.css';
import ChangeBetAmountsModal from './ChangeBetAmountsModal';

const defaultValues = [1, 5, 10, 25, 50, 100, 500];

interface CustomBetValues {
  wps: number[];
  exactaQuinella: number[];
  trifecta: number[];
  superfecta: number[];
  pentafecta: number[];
  dbl: number[];
}

const defaultCustomBetValues = {
  wps: [],
  exactaQuinella: [],
  trifecta: [],
  superfecta: [],
  pentafecta: [],
  dbl: [],
};

function SetStakeButtons({
  values,
  setValues,
}: {
  values: number[];
  setValues: (values: number[]) => void;
}) {
  const format = useFormat();

  function handleSetValues(value: number) {
    const updatedValues = values.includes(value)
      ? values.filter((v) => v !== value)
      : [...values, value];
    setValues(updatedValues);
  }

  return (
    <div className={styles.stakeButtons}>
      {defaultValues.map((val) => (
        <Button
          key={val}
          onClick={() => handleSetValues(val)}
          className={clsx(styles.button, {
            [styles.selected]: values.includes(val),
          })}
        >
          +{format.currency(val)}
        </Button>
      ))}
    </div>
  );
}

export default function BettingSettings() {
  const { settings, updateSettings } = useAppState();
  const [confirmBets, setConfirmBets] = useState(false);
  const [confirmCancelBets, setConfirmCancelBets] = useState(false);
  const [customiseBetAmounts, setCustomiseBetAmounts] = useState(false);
  const [customBetValues, setCustomBetValues] = useState<CustomBetValues>(
    defaultCustomBetValues
  );
  const [customBetValuesBeforeChanges, setCustomBetValuesBeforeChanges] =
    useState<CustomBetValues | null>(null);
  const location = useLocation();
  const history = useHistory();
  const { action, ...remainingQuery } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const dismissModal = useCallback(
    () => history.push({ search: QueryString.stringify(remainingQuery) }),
    [history, remainingQuery]
  );

  useEffect(() => {
    setCustomBetValuesBeforeChanges(customBetValues);
  }, [customiseBetAmounts, customBetValues]);

  const OddsDisplyOptions = [
    {
      label: 'Decimal EG: 8.5',
      value: 'DECIMAL',
      eg: 'EG: 8.5',
    },
    {
      label: 'American EG: +750',
      value: 'AMERICAN',
      eg: 'EG: +750',
    },
    {
      label: 'Fractional EG: 15/2',
      value: 'FRACTIONAL',
      eg: 'EG: 15/2',
    },
  ];

  function handleChangeOddsDisplay(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const value = event.target.value as OddsDisplayType;
    updateSettings((prevSettings) => ({ ...prevSettings, oddsType: value }));
  }

  function handleCancel() {
    if (
      isEqual(customBetValues, customBetValuesBeforeChanges) &&
      customBetValuesBeforeChanges
    ) {
      setCustomiseBetAmounts(false);
    } else {
      history.push({
        search: QueryString.stringify({
          ...QueryString.parse(location.search, {
            ignoreQueryPrefix: true,
          }),
          action: 'confirm_cancel',
        }),
      });
    }
  }

  function handleSave() {
    setCustomiseBetAmounts(false);
  }

  function handleReset() {
    setCustomBetValues(defaultCustomBetValues);
  }

  function handleSetCustomBetValues(betType: string) {
    return (values: number[]) =>
      setCustomBetValues((prev) => ({
        ...prev,
        [betType]: values,
      }));
  }

  function handleSaveChanges(value: boolean) {
    if (!value && customBetValuesBeforeChanges)
      setCustomBetValues(customBetValuesBeforeChanges);
    setCustomiseBetAmounts(false);
  }
  return (
    <>
      <Helmet title="Betting">
        <meta name="description" content="Betting Settings" />
      </Helmet>
      <CardLabel label="PERSONAL DETAILS" />
      <div>
        <Card className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.description}>
              <span className={styles.heading}>
                Show &apos;Confirm Bets&apos; step
              </span>
              <span className={styles.value}>
                Show the Bet confirmation step so it places in 2 steps.
              </span>
              <span className={styles.value}>Or tuen off to bet faster</span>
            </div>
            <ToggleSwitch
              value={confirmBets}
              changeValue={() => setConfirmBets((prev) => !prev)}
            />
          </div>
        </Card>
        <Card className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.description}>
              <span className={styles.heading}>
                Show &apos;Confirm Bet Cancellation&apos; step
              </span>
              <span className={styles.value}>
                Show the Bet Cancellation step when cancelling a bet to
              </span>
              <span className={styles.value}>
                prevent accidentally canceling bets. Or turn off to cancel bets
                faster.
              </span>
            </div>
            <ToggleSwitch
              value={confirmCancelBets}
              changeValue={() => setConfirmCancelBets((prev) => !prev)}
            />
          </div>
        </Card>
        <Card className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.description}>
              <span className={styles.heading}>Odds Display</span>
            </div>
            <Select
              value={settings.oddsType}
              options={OddsDisplyOptions}
              onChange={handleChangeOddsDisplay}
              className={styles.select}
            />
          </div>
        </Card>
        <Card className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.description}>
              <span className={styles.heading}>Customise Bet Amounts</span>
              <span className={styles.value}>
                This will customise your betslip stake and presets. You can
                change
              </span>
              <span className={styles.value}>these at any time</span>
            </div>
            <ToggleSwitch
              value={customiseBetAmounts}
              changeValue={() => setCustomiseBetAmounts((prev) => !prev)}
            />
          </div>
          <div className={styles.seperator} />
          <div
            className={clsx(styles.bottomContent, {
              [styles.disabled]: !customiseBetAmounts,
            })}
          >
            <div className={styles.bottomHeading}>Win - Place - Show</div>
            <SetStakeButtons
              values={customBetValues.wps}
              setValues={handleSetCustomBetValues('wps')}
            />
            <div className={styles.bottomHeading}>Exacta - Quinella</div>
            <SetStakeButtons
              values={customBetValues.exactaQuinella}
              setValues={handleSetCustomBetValues('exactaQuinella')}
            />
            <div className={styles.bottomHeading}>Trifecta</div>
            <SetStakeButtons
              values={customBetValues.trifecta}
              setValues={handleSetCustomBetValues('trifecta')}
            />
            <div className={styles.bottomHeading}>Superfecta</div>
            <SetStakeButtons
              values={customBetValues.superfecta}
              setValues={handleSetCustomBetValues('superfecta')}
            />
            <div className={styles.bottomHeading}>Pentafecta</div>
            <SetStakeButtons
              values={customBetValues.pentafecta}
              setValues={handleSetCustomBetValues('pentafecta')}
            />
            <div className={styles.bottomHeading}>DBL - Pick Pools</div>
            <SetStakeButtons
              values={customBetValues.dbl}
              setValues={handleSetCustomBetValues('dbl')}
            />
            <div className={styles.bottomContentActions}>
              <div className={styles.actionButtons}>
                <Button
                  theme="transparentDark"
                  size="large"
                  onClick={handleReset}
                >
                  Reset to Defaults
                </Button>
                <Button size="large" theme="secondary" onClick={handleSave}>
                  Save
                </Button>
              </div>
              <Button size="large" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </div>
      {action === 'confirm_cancel' ? (
        <ChangeBetAmountsModal
          onDismiss={dismissModal}
          saveChanges={handleSaveChanges}
        />
      ) : null}
    </>
  );
}
