import clsx from 'clsx';
import { useState } from 'react';
import Icon from '../../../../components/Icon/Icon';
import RacingFilters from '../../../../components/RacingFilters/RacingFilters';
import { MeetingType } from '../../../../constants/meetingTypes';
import useCountryFilterNTJ from '../../../../hooks/useCountryFilterNTJ';
import { NextToJump as NextToJumpType } from '../../../../hooks/useMeetings/types';
import { sortNTJRaces } from '../../../../utils/race/common';
import { SortByKey, SortOrder } from '../../../../utils/race/types';
import PoolsNavigation from './PoolsNavigation';
import UpcomingRace from './UpcomingRace';
import styles from './UpcomingRaces.module.css';

export default function UpcomingRaces() {
  const countryFilteredNTJ: NextToJumpType[] = useCountryFilterNTJ();
  const [typeFilters, setTypeFilter] = useState<MeetingType[]>([]);
  const [sortBy, setSortBy] = useState(SortByKey.MTP);
  const [sortDesc, setSortDesc] = useState(true);

  const filteredMeetings =
    typeFilters.length > 0
      ? countryFilteredNTJ?.filter((race: NextToJumpType) =>
          typeFilters.includes(race.meeting.type)
        )
      : countryFilteredNTJ;

  const sortedRaces = [...filteredMeetings] // needed as .sort mutates the original array
    .sort((a, b) => sortNTJRaces(a, b, sortBy, sortDesc))
    .slice(0, 6);

  const handleChangeSort = (sortValue: SortByKey) => {
    if (sortValue === sortBy) {
      setSortDesc(!sortDesc);
    } else {
      setSortBy(sortValue);
      setSortDesc(true);
    }
  };

  const renderSortIcon = (sortValue: string) => {
    if (sortValue !== sortBy) {
      return 'sort';
    }

    return sortDesc ? SortOrder.DESCENDING : SortOrder.ASCENDING;
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <RacingFilters
          hideAll
          filters={typeFilters}
          setFilters={setTypeFilter}
          size="small"
          className={styles.filters}
        />
        <PoolsNavigation />
      </div>
      <div className={styles.sort}>
        <span
          className={clsx(styles.sortButton, {
            [styles.selected]: sortBy === SortByKey.TRACK,
          })}
          onClick={() => handleChangeSort(SortByKey.TRACK)}
          role="button"
          tabIndex={-1}
        >
          TRACK&nbsp;
          <Icon name={renderSortIcon(SortByKey.TRACK)} size="small" />
        </span>
        <span
          className={clsx(styles.sortButton, {
            [styles.selected]: sortBy === SortByKey.MTP,
          })}
          onClick={() => handleChangeSort(SortByKey.MTP)}
          role="button"
          tabIndex={-1}
        >
          MTP&nbsp;
          <Icon name={renderSortIcon(SortByKey.MTP)} size="small" />
        </span>
      </div>
      <div className={styles.content}>
        {sortedRaces.map((race: NextToJumpType) => (
          <UpcomingRace
            key={race.id}
            race={race}
            type={race.meeting.type}
            meeting={race.meeting}
          />
        ))}
      </div>
    </div>
  );
}
