import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { PriceType } from '../../constants/priceTypes';
import { Pool, PoolHistoryQuery } from './types';
import useWPSPools from './useWPSPool';

export default function usePoolHistory(id: string, type: PriceType) {
  const [poolHistory, setPoolHistory] = useState<Pool[]>([]);
  const wpsPools = useWPSPools(id);

  const { data } = useQuery<{ race: PoolHistoryQuery }>(
    gql`
      query race($id: ID, $type: PriceType!) {
        race(id: $id) {
          id,
          poolHistory(source: "${process.env.REACT_APP_PRICE_CODE}", priceType: $type) {
            type,
            poolSize,
            updated,
            prices{
              tabNo,
              price
            }
          }
        }
      }
    `,
    {
      variables: {
        id,
        type,
      },
    }
  );

  function sort(pools: Pool[]) {
    return [...pools].sort((a, b) =>
      dayjs(a.updated).isBefore(b.updated) ? -1 : 1
    );
  }

  useEffect(() => {
    if (data?.race?.poolHistory) {
      setPoolHistory(sort(data.race.poolHistory));
    }
  }, [data]);

  useEffect(() => {
    const poolUpdate = wpsPools?.[type];
    if (poolHistory?.slice(-1)[0]?.updated === poolUpdate?.updated) return;
    setPoolHistory((prev) => (poolUpdate ? [...prev, poolUpdate] : [...prev]));
  }, [poolHistory, wpsPools, type]);

  return poolHistory;
}
