import clsx from 'clsx';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import betTypes from '../../constants/betTypes';
import priceTypes from '../../constants/priceTypes';
import useAppState from '../../hooks/useAppState/useAppState';
import useSingleBetslip from '../../hooks/useBetslip/useSingleBetslip';
import useFormat from '../../hooks/useFormat';
import { NextToJump } from '../../hooks/useMeetings/types';
import usePrices from '../../hooks/usePrices/usePrices';
import useRace from '../../hooks/useRace/useRace';
import { getBetType, getPrice } from '../../utils/betslip/common';
import Countdown from '../Countdown/Countdown';
import Icon from '../Icon/Icon';
import OddsButton from '../OddsButton/OddsButton';
import Silk from '../Silk/Silk';
import styles from './NextToJumpRace.module.css';

export default function NextToJumpRace({
  nextToJump,
}: {
  nextToJump: NextToJump;
}) {
  const { race } = useRace(nextToJump.id);
  const prices = usePrices(race);
  const { addOrRemoveBet, inBetslip } = useSingleBetslip();
  const { settings } = useAppState();

  const raceName = `R${nextToJump.number} ${nextToJump.meeting.track.name}`;

  const format = useFormat();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Icon name={nextToJump.meeting.type} className={styles.icon} />
        <div className={styles.raceDetails}>
          <Link
            to={`/racing/race/${nextToJump.id}/win`}
            className={styles.raceName}
            title={raceName}
          >
            {raceName}
          </Link>
          <span className={styles.pool}>
            Pool {format.currency(nextToJump.prizeMoney)}
          </span>
        </div>
        <div className={styles.countdown}>
          <Countdown startTime={nextToJump.startTime} />
        </div>
      </div>
      <div className={styles.runners}>
        <div className={styles.grid}>
          <div className={styles.separator} />
          <span className={styles.header}>RUNNERS</span>
          <span className={clsx(styles.header, styles.center)}>
            {settings.selectedPriceType === 'fixed' ? 'FIXED' : 'TOTE'}
          </span>
          <div className={styles.separator} />
          {race?.competitors
            .filter((competitor) => !competitor.scratched)
            .slice(0, 6)
            .map((competitor) => {
              const competitorName = `${competitor.tabNo}. ${competitor.name}`;
              const bet = {
                race,
                meeting: race?.meeting,
                competitor,
              };

              return (
                <Fragment key={competitor.tabNo}>
                  <div className={styles.runner}>
                    <Silk
                      meeting={nextToJump.meeting}
                      race={nextToJump}
                      competitor={competitor}
                    />
                    <span className={styles.runnerDetail}>
                      <span
                        className={styles.runnerName}
                        title={competitorName}
                      >
                        {competitorName}
                      </span>
                      <span className={styles.jockey} title={competitor.jockey}>
                        {competitor.jockey}
                      </span>
                    </span>
                  </div>
                  <div className={styles.oddsContainer}>
                    <OddsButton
                      price={getPrice(
                        settings.selectedPriceType,
                        priceTypes.WIN,
                        prices?.[competitor.tabNo]
                      )}
                      onClick={() =>
                        addOrRemoveBet({
                          betType: getBetType(
                            settings.selectedPriceType,
                            betTypes.WIN
                          ),
                          traditional: true,
                          ...bet,
                        })
                      }
                      selected={inBetslip({
                        betType: betTypes.WIN,
                        ...bet,
                      })}
                      disabled={competitor.scratched}
                    />
                  </div>
                  <div className={styles.separator} />
                </Fragment>
              );
            })}
        </div>
      </div>
      <div className={styles.footer}>
        <Link
          to={`/racing/race/${nextToJump.id}/win`}
          className={styles.viewRaceCard}
        >
          View Race Card
        </Link>
      </div>
    </div>
  );
}
