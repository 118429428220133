import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Pools } from './types';
import useExoticPools from './useExoticPools';
import useWPSPool from './useWPSPool';

export default function usePools(id: string) {
  const [pools, setPools] = useState<Pools>();
  const [lastUpdated, setLastUpdated] = useState<Date | undefined>();
  const wpsPools = useWPSPool(id);
  const exoticPools = useExoticPools(id);

  useEffect(() => {
    if (wpsPools && exoticPools) {
      setPools({ ...wpsPools, ...exoticPools });
    }
  }, [wpsPools, exoticPools]);

  useEffect(() => {
    if (!pools) return;
    const lu = latest(pools);
    setLastUpdated(lu);
  }, [pools]);

  function latest(p: Pools | {}): Date | undefined {
    const poolsArray = Object.values(p);
    const sorted = [...poolsArray]
      .filter((pool) => pool?.updated)
      .sort((a, b) => (dayjs(a?.updated).isBefore(b?.updated || '') ? -1 : 1));
    return sorted.slice(-1)[0]?.updated;
  }

  return { pools, lastUpdated };
}
