import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';

export default function ResponsibleGambling() {
  const route = useMemo(
    () => [
      {
        label: 'Account',
        url: '/account',
      },
      {
        label: 'Responsible Gambling',
        url: '/account/responsible-gambling',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  return (
    <HomeContainer HeaderComponent={<h1>Responsible Gambling</h1>}>
      <Helmet title="Responsible Gambling">
        <meta name="description" content="Responsible Gambling" />
      </Helmet>
    </HomeContainer>
  );
}
