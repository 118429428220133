import firebase from 'firebase/app';
import { ReactNode, useEffect, useState } from 'react';
import RemoteConfigContext, { RemoteConfig } from './RemoteConfigContext';

// If process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_INTERVAL is not set, ensure that we do not run an interval at 0's.
const REMOTE_CONFIG_INTERVAL = process.env
  .REACT_APP_FIREBASE_REMOTE_CONFIG_INTERVAL
  ? +process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_INTERVAL
  : 60000;

export default function RemoteConfigProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfig>();

  useEffect(() => {
    firebase.remoteConfig().settings = {
      fetchTimeoutMillis: 60000,
      minimumFetchIntervalMillis: REMOTE_CONFIG_INTERVAL,
    };

    async function init() {
      await firebase.remoteConfig().fetchAndActivate();
      const newConfig = await firebase.remoteConfig().getAll();
      const parseValue = (key: string, value: firebase.remoteConfig.Value) => {
        // All feature flags will be structured as such `featureEnabled`
        if (key.includes('Enabled')) {
          return value.asBoolean();
        }

        return value.asString();
      };
      const parsedConfig = Object.keys(newConfig).reduce<RemoteConfig>(
        (aggregated, key) => ({
          ...aggregated,
          [key]: parseValue(key, newConfig[key]),
        }),
        {}
      );

      setRemoteConfig(parsedConfig);
    }

    init();

    if (REMOTE_CONFIG_INTERVAL > 0) {
      const interval = window.setInterval(init, REMOTE_CONFIG_INTERVAL);

      return () => window.clearInterval(interval);
    }

    return () => {};
  }, []);

  return (
    <RemoteConfigContext.Provider value={remoteConfig}>
      {children}
    </RemoteConfigContext.Provider>
  );
}
