import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';
import useFormat from '../../../hooks/useFormat';
import styles from './CurrencyInput.module.css';

type Props = InputHTMLAttributes<HTMLInputElement>;

export default function CurrencyInput({
  type = 'text',
  className = '',
  ...rest
}: Props) {
  const format = useFormat();

  return (
    <div className={styles.inputPrefix}>
      <span className={styles.currencyCode}>
        {format.currency(0).split('0')}
      </span>
      <input type={type} className={clsx(styles.input, className)} {...rest} />
    </div>
  );
}
