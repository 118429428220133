import { Helmet } from 'react-helmet-async';

export default function Sports() {
  return (
    <>
      <Helmet title="Sports">
        <meta name="description" content="Sports" />
      </Helmet>
    </>
  );
}
