import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import { CollapsableHeight } from '../../../components/Collapsable/Collapsable';
import Countdown from '../../../components/Countdown/Countdown';
import HorizontalOverflow from '../../../components/HorizontalOverflow/HorizontalOverflow';
import Icon from '../../../components/Icon/Icon';
import ShadowButton from '../../../components/ShadowButton/ShadowButton';
import { MultiBetType } from '../../../constants/betTypes';
import raceStatuses from '../../../constants/raceStatuses';
import useBetslip from '../../../hooks/useBetslip/useBetslip';
import useFormat from '../../../hooks/useFormat';
import { Meeting } from '../../../hooks/useRace/types';
import { UseRaceData } from '../../../hooks/useRace/useRace';
import useRemoteConfig from '../../../hooks/useRemoteConfig/useRemoteConfig';
import {
  betTypeLookup,
  isMultiExoticBetType,
} from '../../../utils/betslip/common';
import styles from './RaceHeader.module.css';

export default function RaceHeader({
  race,
  meeting,
  meetings,
  hasResults,
  onChangeMeeting,
  onChangeRace,
  handleLiveVisionToggle,
}: {
  race: UseRaceData;
  meeting: Meeting;
  meetings: Meeting[];
  hasResults?: boolean;
  onChangeMeeting: (meetingId: string) => void;
  onChangeRace: (raceId: string, betType: string) => void;
  handleLiveVisionToggle?: () => void;
}) {
  const { liveVisionEnabled } = useRemoteConfig();
  const [showRaceDescription, setShowRaceDescription] = useState(false);
  const format = useFormat();
  const match = useRouteMatch<{
    betType: string;
  }>();
  const betType = match?.params?.betType;
  const betTypeString = betTypeLookup?.(betType);
  const {
    state: { multiSelections },
  } = useBetslip();

  // Bang added to hasVision here just to enable watch button.
  // Need to remove bang when hasVision property isn't always false (presume currently mocked)
  const showLiveVisionButton =
    liveVisionEnabled && !race?.meeting?.hasVision && !hasResults;

  function handleChangeMeeting(event: React.ChangeEvent<HTMLSelectElement>) {
    onChangeMeeting(event.target.value);
  }

  function handleChangeRace(id: string) {
    onChangeRace(id, betType);
  }

  function checkMeetings(meet: Meeting[]) {
    if (meet.some((el) => el.track.name === race?.meeting.track.name))
      return meet;
    return [
      race?.meeting || { id: '', track: { name: '' } },
      ...meetings.filter((m) => m.id !== race?.meeting.id),
    ];
  }

  return (
    <div>
      <div className={styles.header}>
        <select
          value={meeting.id}
          onChange={handleChangeMeeting}
          className={styles.select}
        >
          {checkMeetings(meetings).map((m) => (
            <option key={m.id} value={m.id}>
              {m.track.name}
            </option>
          ))}
        </select>
        <div className={styles.actions}>
          <ShadowButton size="medium" className={styles.actionButton}>
            <Icon name="shop" className={styles.actionButtonIcon} />
            <span className={styles.buttonLabel}>Shop</span>
          </ShadowButton>
          {showLiveVisionButton && (
            <ShadowButton
              theme="primary"
              onClick={handleLiveVisionToggle}
              className={styles.actionButton}
            >
              <Icon name="watch" className={styles.actionButtonIcon} />
              <span className={styles.buttonLabel}>
                {meeting.track.name || 'Watch'}
              </span>
            </ShadowButton>
          )}
        </div>
      </div>
      <HorizontalOverflow>
        <div className={styles.raceNumbers}>
          {meeting.races.map((currentRace) => {
            const multiBet =
              multiSelections?.[betTypeString as string as MultiBetType];
            const isMultiBetRace =
              isMultiExoticBetType(betType) &&
              multiBet?.races.includes(currentRace.id);
            const isMultiBetValid =
              isMultiExoticBetType(betType) &&
              multiBet?.selections?.[multiBet?.races.indexOf(currentRace.id)]
                ?.length > 0;

            return (
              <Button
                key={currentRace.id}
                theme="none"
                onClick={() => handleChangeRace(currentRace.id)}
                className={clsx(styles.raceNumber, {
                  [styles.selected]: race?.id === currentRace.id,
                  [styles.closed]: currentRace.status !== raceStatuses.OPEN,
                  [styles.inMultiExotic]: isMultiBetRace,
                })}
              >
                <span>{currentRace.number}</span>
                {isMultiBetValid ? (
                  <div className={styles.checkmarkWrapper}>
                    <Icon name="checkmark" className={styles.checkmark} />
                  </div>
                ) : null}
              </Button>
            );
          })}
        </div>
      </HorizontalOverflow>
      <div className={styles.description}>
        <span>{race?.name}</span>
        <span>{dayjs(race?.startTime).format('h:mma')}</span>
        {race?.startTime ? (
          <Countdown startTime={race.startTime} className={styles.countdown} />
        ) : null}
      </div>
      <div className={styles.details}>
        <span>
          <span className={styles.label}>Purse: </span>
          {format.currency(race?.prizeMoney)}
        </span>
        <span>
          <span className={styles.label}>Surface: </span>
          {meeting.track.surface}
        </span>
        <span>
          <span className={styles.label}>Dist: </span>
          {format.distance(race?.distance)}
        </span>
        <span>
          <span className={styles.label}>Turf: </span>
          {race?.trackCondition}
        </span>
        <span>
          <ShadowButton
            theme="default"
            size="small"
            onClick={() => setShowRaceDescription((prev) => !prev)}
            selected={showRaceDescription}
            className={styles.descriptionButton}
          >
            <span>Race Description</span>
            <Icon
              name="caretDown"
              className={clsx(styles.chevron, {
                [styles.selected]: showRaceDescription,
                [styles.chevronSelected]: showRaceDescription,
              })}
            />
          </ShadowButton>
        </span>
      </div>
      <CollapsableHeight open={showRaceDescription}>
        <div className={styles.raceDescription}>
          <span>
            <span className={styles.label}>Race Description: </span>
            {race?.comment}
          </span>
        </div>
      </CollapsableHeight>
    </div>
  );
}
