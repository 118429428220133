import clsx from 'clsx';
import { useContext } from 'react';
import Button from '../../../../../components/Button/Button';
import { MultiBetType } from '../../../../../constants/betTypes';
import useBetslip from '../../../../../hooks/useBetslip/useBetslip';
import useMultiBetslip from '../../../../../hooks/useBetslip/useMultiBetslip';
import { RaceDetailedCompetitor } from '../../../../../hooks/useRace/types';
import RaceContext from '../../RaceContext';
import styles from './SelectionHeader.module.css';

export default function MultiSelectionHeader({
  selections = [],
  column,
}: {
  selections: RaceDetailedCompetitor[];
  column: number;
}) {
  const {
    state: { multiSelections },
  } = useBetslip();
  const { updateMultiSelectionAll } = useMultiBetslip();
  const { betType } = useContext(RaceContext);
  const selectedBet = multiSelections[betType as string as MultiBetType];

  function handleSelectAll() {
    updateMultiSelectionAll({
      betType,
      index: column,
      selections,
    });
  }

  return (
    <span className={clsx(styles.header, styles.actions)}>
      <Button
        onClick={handleSelectAll}
        className={clsx(styles.button, {
          [styles.selected]:
            selectedBet?.selections?.[column]?.length === selections.length,
        })}
      >
        <span>ALL</span>
      </Button>
    </span>
  );
}
