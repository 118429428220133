import { gql, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PriceType } from '../../constants/priceTypes';
import { Price, RaceDetailed } from '../useRace/types';

/**
 * {
 *  1: {
 *    WIN: 2.9,
 *    PLACE: 1.3,
 *    WIN_FIXED_ODDS: 2.5,
 *    PLACE_FIXED_ODDS: 1.2
 *  },
 *  2: {
 *    ...
 *  }
 * }
 */
export default function usePrices(race?: RaceDetailed) {
  const [prices, setPrices] = useState<
    | {
        [tabNo: string]: {
          [priceType in PriceType]: number;
        };
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (race) {
      setPrices(
        race.competitors.reduce(
          (aggregated, competitor) => ({
            ...aggregated,
            [competitor?.tabNo]: competitor?.prices.reduce(
              (aggregatedPrices, price) => ({
                ...aggregatedPrices,
                [price.type]: price.price,
              }),
              {}
            ),
          }),
          {}
        )
      );
    }
    return () => setPrices(undefined);
  }, [race]);

  useSubscription<{
    priceUpdates: {
      updated: boolean;
      prices: Price[];
    };
  }>(
    gql`
      subscription priceUpdates($id: ID) {
        priceUpdates(raceId: $id, sources: ["${process.env.REACT_APP_PRICE_CODE}"]) {
          updated
          prices {
            tabNo
            price
            type
          }
        }
      }
    `,
    {
      variables: {
        id: race?.id,
      },
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data) {
          const { priceUpdates } = subscriptionData.data;
          setPrices((prevPrices) => ({
            ...prevPrices,
            ...priceUpdates.prices.reduce(
              (aggregated, price) => ({
                ...aggregated,
                [price.tabNo]: {
                  ...(prevPrices?.[price.tabNo] || {}),
                  [price.type]: price.price,
                },
              }),
              {}
            ),
          }));
        }
      },
    }
  );

  return prices;
}
