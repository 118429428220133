import useAppState from './useAppState/useAppState';
import { NextToJump } from './useMeetings/types';

// Returns array of nextToJump races filtered by preferredCountry, International or neither

export default function useCountryFilterNTJ() {
  const {
    nextToJump,
    config,
    countryFilter: { countryFilters },
  } = useAppState();

  if (!config?.options?.preferredCountry) return nextToJump;

  const { preferredCountry } = config.options;

  if (!countryFilters.length || countryFilters.length === 2) {
    return nextToJump;
  }

  if (preferredCountry && countryFilters.includes(preferredCountry)) {
    return nextToJump.filter(
      (race: NextToJump) => race.meeting.track.country === preferredCountry
    );
  }

  if (countryFilters.includes('Intl')) {
    return nextToJump.filter(
      (race: NextToJump) => race.meeting.track.country !== preferredCountry
    );
  }

  return nextToJump;
}
