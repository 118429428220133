import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Modal from '../../../components/Modal/Modal';
import styles from './DepositModal.module.css';

type LocationState = {
  cardIdentifier: number;
};

export default function RemoveCardModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const location = useLocation<LocationState>();
  const { cardIdentifier } = location?.state;

  const handleConfirm = () => {
    onDismiss();
    toast.success(`Card removed`);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <Card className={styles.container}>
        <h1 className={styles.heading}>Remove Card</h1>
        <p className={styles.subText}>
          Are you sure you want to remove card
          {cardIdentifier ? ` ending in ${cardIdentifier}` : ''}?
        </p>
        <div className={styles.buttonContainer}>
          <Button
            theme="secondary"
            onClick={handleConfirm}
            className={styles.input}
          >
            Remove Card
          </Button>
          <Button
            theme="transparentDark"
            onClick={onDismiss}
            className={styles.input}
          >
            Cancel
          </Button>
        </div>
      </Card>
    </Modal>
  );
}
