export const betStatusCodeV2 = {
  1: 'BET_PLACED',
  2: 'GENERIC_ERROR',
};

export const betStatusCodeEngV2 = {
  BET_PLACED: 'Bet Placed',
  GENERIC_ERROR: 'Something went wrong processing your bet.',
  RACE_CLOSED: 'Betting is closed on this event',
};

export const betStatusV2 = {
  BET_PLACED: 'success',
  GENERIC_ERROR: 'warning',
  RACE_CLOSED: 'error',
};

export enum BetFilters {
  WIN = 'WIN',
  LOSS = 'LOSS',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
}

export enum TransactionFilters {
  DEPOSIT = 'Cash Deposit',
  WITHDRAWAL = 'Cash Withdrawal',
  CANCELED = 'Ticket cancel Sell',
  WIN = 'Winning Ticket',
  LOSS = 'Loosing Ticket',
  REJECTED = 'Rejected Ticket',
}

export const TransactionTypes = {
  'Cash Deposit': 'DEPOSIT',
  'Cash Withdrawal': 'WITHDRAWAL',
  'Winning Ticket': 'WIN',
  'Loosing Ticket': 'LOSS',
  'Ticket cancel Sell': 'CANCELED',
  'Rejected Ticket': 'REJECTED',
};

export type AllFilters = TransactionFilters;
