import { Redirect, Route, Switch } from 'react-router-dom';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import RacingResults from './RacingResults/RacingResults';
import RacingToday from './RacingToday/RacingToday';
import RacingUpcoming from './RacingUpcoming/RacingUpcoming';

export default function RacingHome() {
  return (
    <HomeContainer
      HeaderComponent={<h1>Racing</h1>}
      tabs={[
        {
          label: 'Todays Racing',
          url: '/racing/home/today',
        },
        {
          label: 'Upcoming Races',
          url: '/racing/home/upcoming',
        },
        {
          label: 'Results',
          url: '/racing/home/results',
        },
      ]}
    >
      <Switch>
        <Route exact path="/racing/home/today" component={RacingToday} />
        <Route exact path="/racing/home/upcoming" component={RacingUpcoming} />
        <Route exact path="/racing/home/results" component={RacingResults} />
        <Redirect to="/racing/home/today" />
      </Switch>
    </HomeContainer>
  );
}
