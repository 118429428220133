export enum OddsboardType {
  WIN_PLACE_SHOW = 'Win - Place - Show',
  EXACTA = 'Exacta',
  DOUBLE = 'Double',
  WILL_PAYS = 'Will Pays',
  ALL_POOLS = 'All Pools',
}

export enum OddsboardSelectionType {
  WIN_PLACE_SHOW = 'WIN_PLACE_SHOW',
  EXACTA = 'EXACTA',
  DOUBLE = 'DOUBLE',
  WILL_PAYS = 'WILL_PAYS',
  ALL_POOLS = 'ALL_POOLS',
}

const oddsboardTypes = { ...OddsboardType };

export default oddsboardTypes;
