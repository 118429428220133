import uniqBy from 'lodash/uniqBy';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CountryFilters from '../../../../components/CountryFilters/CountryFilters';
import RacingFilters from '../../../../components/RacingFilters/RacingFilters';
import { MeetingType } from '../../../../constants/meetingTypes';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs/useBreadcrumbs';
import useCountryFilterNTJ from '../../../../hooks/useCountryFilterNTJ';
import RaceUpcoming from './RaceUpcoming';
import styles from './RacingUpcoming.module.css';

export default function RacingUpcoming() {
  const route = useMemo(
    () => [
      {
        label: 'Racing',
        url: '/racing',
      },
      {
        label: 'Upcoming Racing',
        url: '/racing/upcoming',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  const countryFilteredRaces = uniqBy(
    useCountryFilterNTJ(),
    (race) => race.meeting.id
  );
  const [typeFilters, setTypeFilters] = useState<MeetingType[]>([]);

  return (
    <>
      <Helmet title="Upcoming">
        <meta name="description" content="Upcoming Racing" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <RacingFilters filters={typeFilters} setFilters={setTypeFilters} />
            <CountryFilters />
          </div>
        </div>
        {typeFilters.includes(MeetingType.THOROUGHBRED) ||
        typeFilters.length === 0 ? (
          <RaceUpcoming
            races={countryFilteredRaces.filter(
              (race) => race.meeting.type === MeetingType.THOROUGHBRED
            )}
            title="HORSE RACING"
          />
        ) : null}
        {typeFilters.includes(MeetingType.GREYHOUND) ||
        typeFilters.length === 0 ? (
          <RaceUpcoming
            races={countryFilteredRaces.filter(
              (race) => race.meeting.type === MeetingType.GREYHOUND
            )}
            title="GREYHOUND RACING"
          />
        ) : null}
        {typeFilters.includes(MeetingType.HARNESS) ||
        typeFilters.length === 0 ? (
          <RaceUpcoming
            races={countryFilteredRaces.filter(
              (race) => race.meeting.type === MeetingType.HARNESS
            )}
            title="HARNESS RACING"
          />
        ) : null}
      </div>
    </>
  );
}
