import {
  ChangeEvent,
  Dispatch,
  MouseEventHandler,
  SetStateAction,
} from 'react';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Form/Input/Input';
import Icon from '../../../../components/Icon/Icon';
import styles from './Filters.module.css';

export default function Filters({
  endDate,
  startDate,
  setEndDate,
  setStartDate,
  filtersOpen,
  showDownload,
  handleDownload,
}: {
  endDate: string;
  startDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  setStartDate: Dispatch<SetStateAction<string>>;
  filtersOpen: boolean;
  showDownload?: boolean;
  handleDownload?: MouseEventHandler<HTMLButtonElement>;
}) {
  function handleDateFromChange(event: ChangeEvent<HTMLInputElement>) {
    setStartDate(event.target.value);
  }

  function handleDateToChange(event: ChangeEvent<HTMLInputElement>) {
    setEndDate(event.target.value);
  }

  return (
    <div className={styles.content}>
      {filtersOpen ? (
        <div className={styles.filterContent}>
          <div className={styles.dateInputs}>
            <div>
              <label htmlFor="startDate">From</label>
              <div>
                <Input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={handleDateFromChange}
                  className={styles.dateInput}
                />
              </div>
            </div>

            <div>
              <label htmlFor="endDate">To</label>
              <div>
                <Input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={handleDateToChange}
                  className={styles.dateInput}
                />
              </div>
            </div>
          </div>
          {showDownload ? (
            <div>
              <Button
                className={styles.downloadButton}
                onClick={handleDownload}
              >
                <span>Download</span>
                <Icon
                  name="download"
                  size="small"
                  className={styles.downloadIcon}
                />
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export function FilterButton({
  selected,
  setSelected,
}: {
  selected: boolean;
  setSelected: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className={styles.filterButton}>
      <Button onClick={() => setSelected((prev) => !prev)} selected={selected}>
        <Icon name="filter" size="small" />{' '}
        <span className={styles.buttonTitle}>Filters</span>
      </Button>
    </div>
  );
}
