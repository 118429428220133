import axios from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import Card from '../../../../components/Card/Card';
import useFormat from '../../../../hooks/useFormat';
import Filters from '../Filters/Filters';
import styles from './Statements.module.css';

const now = dayjs();

export default function Statements() {
  const startOfDay = now.startOf('date');
  const startString = startOfDay.subtract(30, 'days').format('YYYY-MM-DD');
  const endString = startOfDay.add(12, 'hours').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState<string>(startString);
  const [endDate, setEndDate] = useState<string>(endString);
  const format = useFormat();
  const historyData = useQuery(['BetHistory', endDate, startDate], async () => {
    const response = await axios.get('/api/v1/falcon/user/reports/wagering', {
      params: {
        endDate,
        startDate,
      },
    });
    return response.data;
  });

  const { data, refetch } = useQuery(
    ['Download pdf', endDate, startDate],
    async () => {
      const response = await axios.get(
        '/api/v1/falcon/user/reports/wagering/pdf',
        {
          params: {
            endDate,
            startDate,
          },
          responseType: 'blob',
        }
      );
      return response.data;
    },
    { enabled: false }
  );

  const download = async () => {
    const result = await refetch();
    const blob = new Blob([result.data], { type: 'application/pdf' });
    const blobData = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobData;
    link.download = 'file.pdf';
    link.click();
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  };

  return (
    <>
      <Helmet title="statements">
        <meta name="description" content="Statements" />
      </Helmet>
      <div>
        <Filters
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          filtersOpen
          showDownload
          handleDownload={download}
        />
      </div>
      <Card>
        <div className={styles.container} id="statement">
          <div className={styles.row}>
            <span>{`${historyData?.data?.user?.firstName} ${historyData?.data?.user?.lastName}`}</span>
            <span>{`Date: ${dayjs(now).format('DD MMM YYYY')}`}</span>
          </div>
          <div className={styles.row}>
            <span>{historyData?.data?.user?.address}</span>
          </div>
          <div className={styles.row}>
            <span>{`${historyData?.data?.user?.city}, ${historyData?.data?.user?.state} ${historyData?.data?.user?.postalCode}`}</span>
          </div>
          <div className={styles.heading}>Account Wagering Statement</div>
          <div className={styles.row}>
            <span>{`Date Range: ${dayjs(startDate).format(
              'DD MMM YYYY'
            )} - ${dayjs(endDate).format('DD MMM YYYY')}`}</span>
          </div>
          <div className={styles.seperator} />
          <div className={styles.row}>
            <span>Account Number</span>
            <span>{historyData?.data?.user?.accountNumber}</span>
          </div>
          <div className={styles.row}>
            <span>Social Security</span>
            <span>{`XXX-XX-${historyData?.data?.user?.ssn4}`}</span>
          </div>
          <div className={styles.row}>
            <span>Amout Wagered</span>
            <span>
              {format.currency(historyData?.data?.details?.amountWagered)}
            </span>
          </div>
          <div className={styles.row}>
            <span>Refunds</span>
            <span>{format.currency(historyData?.data?.details?.refunds)}</span>
          </div>
          <div className={styles.row}>
            <span>Winnings</span>
            <span>{format.currency(historyData?.data?.details?.winnings)}</span>
          </div>
          <div className={styles.row}>
            <span>Net Profit / Loss</span>
            <span>
              {parseFloat(historyData?.data?.details?.netProfit) > 0
                ? '+'
                : '-'}
              {format.currency(
                historyData?.data?.details?.netProfit?.replace(/-+/, '')
              )}
            </span>
          </div>
          <div className={styles.row}>
            <span>Amount Deposited</span>
            <span>
              {format.currency(historyData?.data?.details?.amountDeposited)}
            </span>
          </div>
          <div className={styles.row}>
            <span>Amount Withdrawn</span>
            <span>
              {format.currency(historyData?.data?.details?.amountWithdrawn)}
            </span>
          </div>
          <div className={styles.row}>
            <span>Positive Adjustments</span>
            <span>
              {format.currency(historyData?.data?.details?.positiveAdjustments)}
            </span>
          </div>
          <div className={styles.row}>
            <span>Federal Withholdings</span>
            <span>
              {format.currency(historyData?.data?.details?.federalWithholdings)}
            </span>
          </div>
          <div className={styles.row}>
            <span>State Withholdings</span>
            <span>
              {format.currency(historyData?.data?.details?.stateWithholdings)}
            </span>
          </div>
        </div>
      </Card>
    </>
  );
}
