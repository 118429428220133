import clsx from 'clsx';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import { BetType, MultiBetType } from '../../../../../constants/betTypes';
import { PriceType } from '../../../../../constants/priceTypes';
import useBetslip from '../../../../../hooks/useBetslip/useBetslip';
import useMultiBetslip from '../../../../../hooks/useBetslip/useMultiBetslip';
import useFormat from '../../../../../hooks/useFormat';
import {
  Competitor,
  RaceDetailedCompetitor,
} from '../../../../../hooks/useRace/types';
import { UseRaceData } from '../../../../../hooks/useRace/useRace';
import styles from './RunnerSelection.module.css';

export default function MultiColumnBuilder({
  betType,
  race,
  competitor,
}: {
  betType: BetType;
  race: UseRaceData;
  competitor: RaceDetailedCompetitor;
}) {
  const {
    state: { multiSelections },
  } = useBetslip();
  const selectedBet = multiSelections[betType as string as MultiBetType];
  const format = useFormat();
  const { updateMultiSelection } = useMultiBetslip();

  function isSelected() {
    const raceIndex = selectedBet.races.indexOf(race?.id);
    return !!selectedBet.selections[raceIndex]?.some(
      (c: Competitor) => c.tabNo === competitor.tabNo
    );
  }

  return (
    <div className={clsx(styles.wrapper, styles.column, styles.gap)}>
      <Checkbox
        checked={isSelected()}
        disabled={competitor.scratched}
        onChange={() =>
          updateMultiSelection({
            raceId: race.id,
            competitor,
            betType,
          })
        }
      />
      <span className={styles.odds}>
        {format.odds(
          competitor.prices.find((price) => price.type === PriceType.WIN)?.price
        )}
      </span>
    </div>
  );
}
