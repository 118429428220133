import QueryString from 'qs';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import useAppState from '../../../hooks/useAppState/useAppState';
import useBetslip from '../../../hooks/useBetslip/useBetslip';
import useClickOutside from '../../../hooks/useClickOutside';
import useFormat from '../../../hooks/useFormat';
import useRemoteConfig from '../../../hooks/useRemoteConfig/useRemoteConfig';
import AccountTray from './AccountTray';
import styles from './Header.module.css';

export default function SiteHeader() {
  const history = useHistory();
  const location = useLocation();
  const { user, liveVision, settings } = useAppState();
  const {
    state: { betslip },
  } = useBetslip();
  const { isGlobalVisionOpen, setIsGlobalVisionOpen, visionUrl } = liveVision;
  const { liveVisionEnabled } = useRemoteConfig();
  const format = useFormat();
  const [showAccountTray, setShowAccountTray] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLInputElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(
    buttonRef.current,
    tooltipRef.current,
    {
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    }
  );

  useClickOutside(tooltipRef, () => setShowAccountTray(false), showAccountTray);

  const liveVisionActive = liveVisionEnabled && visionUrl;

  function handleClick(event: React.MouseEvent) {
    event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
    event.preventDefault(); // Prevents nested embedded links to be fired
    setShowAccountTray((prev) => !prev);
  }

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        {user ? (
          <>
            {liveVisionActive && (
              <button
                type="button"
                className={styles.button}
                onClick={() => setIsGlobalVisionOpen(!isGlobalVisionOpen)}
              >
                <div className={styles.liveButton}>
                  <Icon name="watch" />
                  <span>Watch Live</span>
                </div>
              </button>
            )}
            <button
              type="button"
              className={styles.button}
              onClick={handleClick}
              ref={buttonRef}
            >
              {settings.showBalance ? (
                <div className={styles.balanceButton}>
                  <span>Balance</span>
                  <div className={styles.value}>
                    <span>{format.currency(0)}</span>
                    <Icon name="caretDown" size="small" />
                  </div>
                </div>
              ) : (
                <div className={styles.liveButton}>
                  <Icon name="account" />
                  <div className={styles.value}>
                    <span>Account</span>
                    <Icon name="caretDown" size="small" />
                  </div>
                </div>
              )}
            </button>
            <div className={styles.betsContainer}>
              <Link
                to="/account/my-bets/pending"
                className={styles.pendingBetsButton}
              >
                <span>Bets</span>
                <span className={styles.value}>12</span>
              </Link>
              <div className={styles.betslipButton}>
                <span>Betslip</span>
                <span className={styles.value}>{betslip.length}</span>
              </div>
            </div>
            {showAccountTray
              ? createPortal(
                  <div
                    ref={tooltipRef}
                    className={styles.tooltip}
                    style={popperStyles.popper}
                    {...attributes.popper}
                  >
                    <div
                      ref={setArrowRef}
                      className={styles.arrow}
                      style={popperStyles.arrow}
                      data-popper-arrow
                    />
                    <AccountTray />
                  </div>,
                  document.getElementById('bm-root') || document.body
                )
              : null}
          </>
        ) : (
          <>
            <Button
              size="large"
              theme="primary"
              className={styles.authButton}
              onClick={(event) => {
                event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
                event.preventDefault(); // Prevents nested embedded links to be fired
                history.push({
                  search: QueryString.stringify({
                    ...QueryString.parse(location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    action: 'login',
                  }),
                });
              }}
            >
              Login
            </Button>
            <Button
              size="large"
              className={styles.authButton}
              onClick={(event) => {
                event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
                event.preventDefault(); // Prevents nested embedded links to be fired
                history.push({
                  search: QueryString.stringify({
                    ...QueryString.parse(location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    action: 'signup',
                  }),
                });
              }}
            >
              Sign Up
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
