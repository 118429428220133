import clsx from 'clsx';
import { useContext, useEffect, useRef } from 'react';
import Button from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import HorizontalOverflow from '../../../../components/HorizontalOverflow/HorizontalOverflow';
import { MultiBetType } from '../../../../constants/betTypes';
import useAppState from '../../../../hooks/useAppState/useAppState';
import useBetslip from '../../../../hooks/useBetslip/useBetslip';
import useExoticBetslip from '../../../../hooks/useBetslip/useExoticBetslip';
import useMultiBetslip from '../../../../hooks/useBetslip/useMultiBetslip';
import useSingleBetslip from '../../../../hooks/useBetslip/useSingleBetslip';
import { useMeasure } from '../../../../hooks/useMeasure';
import useOnScreen from '../../../../hooks/useOnScreen';
import useRemoteConfig from '../../../../hooks/useRemoteConfig/useRemoteConfig';
import useWindowSize from '../../../../hooks/useWindowSize';
import ExoticBuilder from '../ExoticBuilder/ExoticBuilder';
import Oddsboard from '../Oddsboard/Oddsboard';
import RaceContext from '../RaceContext';
import MultiExoticSelectionColumn from './MultiExoticSelectionColumn/MultiExoticSelectionColumn';
import styles from './RaceBetType.module.css';
import RaceCard from './RaceCard';

export default function RaceBetType() {
  const { oddsboardEnabled } = useRemoteConfig();
  const { settings, updateSettings } = useAppState();
  const {
    state: { boxed, multiSelections },
  } = useBetslip();
  const { isSingleBetType } = useSingleBetslip();
  const { isExoticBetType, toggleBoxed } = useExoticBetslip();
  const { isMultiBetType, updateMultiRaces } = useMultiBetslip();
  const raceContext = useContext(RaceContext);
  const windowSize = useWindowSize();
  const exoticBuilderBottomPos =
    (windowSize.width || 0) > 1024 ? '0px' : '65px';
  const exoticBuilderRef = useRef<HTMLDivElement>(null);
  const exoticBuilderRect = useMeasure(exoticBuilderRef);
  const exoticBuilderVisible = useOnScreen(exoticBuilderRef);

  const multiBet =
    multiSelections?.[raceContext.betType as string as MultiBetType];
  const { race } = raceContext;

  useEffect(() => {
    if (isMultiBetType(raceContext.betType)) {
      if (!race) return;

      if (
        (multiBet.selections.flatMap((selection) => selection).length === 0 &&
          multiBet.races?.[0] !== race.id) ||
        !multiBet.races?.includes(race.id)
      ) {
        const nextRaces = race.meeting.races.filter(
          (r) => r.number >= race.number
        );

        updateMultiRaces({
          betType: raceContext.betType,
          races: nextRaces.map((r) => r.id),
        });

        raceContext.updateMultiRunnerLength(race.competitors.length, true);
      }
    }
  }, [raceContext, isMultiBetType, updateMultiRaces, multiBet, race]);

  if (!race) return null;

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <HorizontalOverflow>
          <div className={styles.buttons}>
            <Button
              selected={settings.showRunnerInfo}
              onClick={() =>
                updateSettings((prevSettings) => ({
                  ...prevSettings,
                  showRunnerInfo: !prevSettings.showRunnerInfo,
                }))
              }
            >
              Runner Info
            </Button>

            <Button
              selected={settings.showShortForm}
              onClick={() =>
                updateSettings((prevSettings) => ({
                  ...prevSettings,
                  showShortForm: !prevSettings.showShortForm,
                }))
              }
              size="medium"
            >
              Short Form
            </Button>
            {oddsboardEnabled && (
              <Button
                selected={settings.showOddsboard}
                size="medium"
                onClick={() =>
                  updateSettings((prevSettings) => ({
                    ...prevSettings,
                    showOddsboard: !prevSettings.showOddsboard,
                  }))
                }
              >
                Odds Board
              </Button>
            )}
          </div>
        </HorizontalOverflow>
        <div className={styles.secondaryButtons}>
          {isSingleBetType(raceContext.betType) ? (
            <>
              <Button
                onClick={() =>
                  updateSettings((prevSettings) => ({
                    ...prevSettings,
                    selectedPriceType: 'fixed',
                  }))
                }
                className={clsx(styles.toggleButton, {
                  [styles.selected]: settings.selectedPriceType === 'fixed',
                })}
              >
                FIXED
              </Button>
              <Button
                onClick={() =>
                  updateSettings((prevSettings) => ({
                    ...prevSettings,
                    selectedPriceType: 'tote',
                  }))
                }
                className={clsx(styles.toggleButton, {
                  [styles.selected]: settings.selectedPriceType === 'tote',
                })}
              >
                TOTE
              </Button>
            </>
          ) : null}
          {isExoticBetType(raceContext.betType) ? (
            <div className={styles.checkboxRow}>
              <Checkbox
                checked={boxed}
                onChange={() => toggleBoxed({ betType: raceContext.betType })}
                id="boxed"
              />
              <label htmlFor="boxed">Box</label>
            </div>
          ) : null}
        </div>
      </div>

      <HorizontalOverflow>
        <div className={styles.raceCardContainer}>
          {multiBet?.races
            .slice(0, multiBet?.races.indexOf(race.id))
            .map((raceId) => (
              <MultiExoticSelectionColumn key={raceId} raceId={raceId} />
            ))}

          <RaceCard className={styles.raceCard} />

          {multiBet?.races
            .slice(multiBet?.races.indexOf(race.id) + 1)
            .map((raceId) => (
              <MultiExoticSelectionColumn key={raceId} raceId={raceId} />
            ))}
        </div>
      </HorizontalOverflow>
      {isExoticBetType(raceContext.betType) || isMultiBetType ? (
        <>
          <div ref={exoticBuilderRef} />
          <div
            className={clsx(styles.builder, {
              [styles.fixed]: !exoticBuilderVisible,
            })}
            style={{
              width: exoticBuilderRect?.width,
              bottom: exoticBuilderBottomPos,
            }}
          >
            <ExoticBuilder race={race} meeting={race?.meeting} />
          </div>
        </>
      ) : null}
      {settings.showOddsboard ? <Oddsboard race={race} /> : null}
    </div>
  );
}
