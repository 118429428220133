import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';
import Icon from '../../../../components/Icon/Icon';
import useProxy from '../../../../hooks/useProxy';
import { BetHistoryType } from '../../../../types/betHistoryType';
import styles from './MyBetCard.module.css';

export default function MyBetCard({ bet }: { bet: BetHistoryType }) {
  const [expanded, setExpanded] = useState(false);
  const axios = useProxy();

  const cancelBet = () => {
    axios.post<AxiosResponse>('/api/v1/falcon/bet/active/delete', {
      ids: [bet.id],
    });
  };

  return (
    <Card className={clsx(styles.card, { [styles.expanded]: expanded })}>
      <div className={styles.cardContent}>
        <div className={styles.description}>
          <div className={styles.heading}>
            <div className={styles.headingContent}>
              <div className={styles.headingContent}>
                <Icon name="DEPOSIT" className={styles.cardIcon} />
                <span> {bet.bet.selection}</span>
              </div>
            </div>
            <div>
              {bet.bet.status === 'ACTIVE' ? (
                <Button
                  theme="transparentDark"
                  className={styles.actionButton}
                  onClick={() => cancelBet()}
                >
                  Cancel bet
                </Button>
              ) : null}
              <Button
                theme="transparentDark"
                size="small"
                className={styles.actionButton}
                onClick={() => setExpanded((prev) => !prev)}
              >
                <Icon name="caretDown" className={styles.chevron} />
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            <span>{bet?.bet.type?.logicalId}</span>
            <div className={styles.betDetail}>
              <div className={styles.raceDetail}>
                <span
                  className={styles.highlight}
                >{`${bet.track.name}, R${bet.track.card.race.number}`}</span>
                <span>{bet.track.card.race.date}</span>
              </div>
              <div>
                <span>{`Cost: $${bet.bet.stake}`}</span>
                {bet.bet.payout ? (
                  <span
                    className={styles.win}
                  >{` Win: +$${bet.bet.payout.win} `}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomContent}>
        <div>
          <span className={styles.bold}>Receipt No: </span>
          <span>{bet.ticketNumber}</span>
        </div>
        <div>
          <span className={styles.bold}>Wager Date: </span>
          <span>{dayjs(bet.createdOn).format('YYYY-MM-DD')}</span>
        </div>
        <div>
          <span className={styles.bold}>Status: </span>
          <span>{bet.bet.status}</span>
        </div>
        <div>
          <span className={styles.bold}>Bet Type: </span>
          <span>{bet?.bet.type?.logicalId}</span>
        </div>
      </div>
    </Card>
  );
}
