import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Countdown from '../../../../components/Countdown/Countdown';
import Icon, { IconName } from '../../../../components/Icon/Icon';
import useAppState from '../../../../hooks/useAppState/useAppState';
import useFormat from '../../../../hooks/useFormat';
import usePools from '../../../../hooks/usePools/usePools';
import { Meeting, Race } from '../../../../hooks/useRace/types';
import styles from './UpcomingRace.module.css';

export default function UpcomingRace({
  race,
  type,
  meeting,
}: {
  race: Race;
  type: IconName;
  meeting: Meeting;
}) {
  const format = useFormat();
  const pools = usePools(race.id);
  const { settings } = useAppState();
  const [poolValue, setPoolValue] = useState<number>(0);

  useEffect(() => {
    if (settings.oddsboardSelected === 'WIN_PLACE_SHOW') {
      setPoolValue(
        (pools?.pools?.WIN?.poolSize || 0) +
          (pools?.pools?.PLACE?.poolSize || 0)
      );
    }
    if (settings.oddsboardSelected === 'ALL_POOLS') {
      setPoolValue(
        Object.values(pools.pools || {}).reduce(
          (acc, pool) => acc + pool?.poolSize || 0,
          0
        )
      );
    }
    if (settings.oddsboardSelected === 'WILL_PAYS') {
      setPoolValue(0);
    }
    if (settings.oddsboardSelected === 'EXACTA') {
      setPoolValue(pools?.pools?.EXACTA?.poolSize || 0);
    }
    if (settings.oddsboardSelected === 'DOUBLE') {
      setPoolValue(pools?.pools?.DAILY_DOUBLE?.poolSize || 0);
    }
  }, [pools, settings.oddsboardSelected]);

  return (
    <div className={styles.header} key={race.id}>
      <Icon name={type} className={styles.icon} />
      <div className={styles.raceDetails}>
        <span className={styles.raceName}>{meeting.track.name}</span>
        <span>{`Race ${race.number}`}</span>
      </div>
      <div className={styles.racePool}>
        <span className={styles.pool}>{format.currency(poolValue)}</span>
        <div className={styles.countdown}>
          <Countdown startTime={race.startTime} />
        </div>
      </div>
      <Link to={`/racing/race/${race.id}/win`} className={styles.link} />
    </div>
  );
}
