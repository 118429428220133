import Icon from '../../../components/Icon/Icon';
import styles from './EmptyBetslip.module.css';

export function EmptyBetslip() {
  return (
    <div className={styles.container}>
      <div className={styles.circle}>
        <Icon name="betslipFilled" className={styles.icon} />
      </div>
      <div className={styles.primaryText}>Betslip is empty</div>
      <div className={styles.secondaryText}>
        Add a selection to get started.
      </div>
    </div>
  );
}
