import { BetslipEventType } from '../../constants/appEvents';
import { MultiBetType } from '../../constants/betTypes';
import {
  findExoticBetIndex,
  findSingleBetIndex,
  isExoticBet,
  isMultiBet,
  isSingleBet,
} from '../../utils/betslip/common';
import eventDispatcher, {
  EventDispatcherDetail,
} from '../../utils/betslip/eventDispatcher';
import { Bet, ExoticBetType } from '../../utils/betslip/types';
import { Competitor } from '../useRace/types';
import { INITIAL_STATE } from './BetslipContext';

export default function betslipReducer(
  state = INITIAL_STATE,
  action: EventDispatcherDetail
) {
  const removeIndexFromBetslip = (
    updatingBetslip: Bet[],
    indexToRemove: number
  ) => updatingBetslip.filter((_, index) => index !== indexToRemove);

  eventDispatcher(action);

  switch (action.type) {
    case BetslipEventType.ADD_OR_REMOVE: {
      const { traditional, ...bet } = action;
      const existingBetIndex = findSingleBetIndex(state.betslip, bet);
      if (existingBetIndex !== -1) {
        return {
          ...state,
          betslip: removeIndexFromBetslip(state.betslip, existingBetIndex),
        };
      }

      return {
        ...state,
        betslip: [
          {
            ...bet,
            // Traditional betslip will always get the same stake from the same race
            stake: traditional
              ? state.betslip.find(
                  (b) => b.betType === bet.betType && b.race.id === bet.race.id
                )?.stake
              : 0,
          },
          ...state.betslip,
        ],
      };
    }
    case BetslipEventType.ADD_MULTI_EXOTIC:
    case BetslipEventType.ADD_EXOTIC: {
      const bet = action;

      const existingBetIndex = findExoticBetIndex(state.betslip, bet);
      if (existingBetIndex !== -1) {
        return {
          ...state,
          betslip: removeIndexFromBetslip(state.betslip, existingBetIndex),
        };
      }

      return {
        ...state,
        showBetslip: state.betslip.length === 0, // Show betslip on first bet
        betslip: [
          {
            ...bet,
            stake: 0,
          },
          ...state.betslip,
        ],
      };
    }
    case BetslipEventType.REMOVE: {
      return {
        ...state,
        betslip: removeIndexFromBetslip(state.betslip, action.index),
      };
    }
    case BetslipEventType.REMOVE_TRADITIONAL: {
      return {
        ...state,
        betslip: state.betslip.filter(
          (bet) =>
            !(bet.betType === action.betType && bet.race.id === action.raceId)
        ),
      };
    }
    case BetslipEventType.UPDATE_TRADITIONAL_SINGLE_STAKE: {
      return {
        ...state,
        betslip: state.betslip.map((bet) => {
          if (bet.race.id === action.raceId && bet.betType === action.betType) {
            return {
              ...bet,
              stake: action.stake,
            };
          }

          return bet;
        }),
      };
    }
    case BetslipEventType.UPDATE_ALL_SINGLES_STAKE: {
      return {
        ...state,
        betslip: state.betslip.map((bet) => {
          if (isSingleBet(bet)) {
            return {
              ...bet,
              stake: action.stake,
            };
          }

          return bet;
        }),
      };
    }
    case BetslipEventType.UPDATE_ALL_EXOTICS_STAKE: {
      return {
        ...state,
        betslip: state.betslip.map((bet) => {
          if (isExoticBet(bet)) {
            return {
              ...bet,
              stake: action.stake,
            };
          }

          return bet;
        }),
      };
    }
    case BetslipEventType.UPDATE_ALL_MULTIS_STAKE: {
      return {
        ...state,
        betslip: state.betslip.map((bet) => {
          if (isMultiBet(bet)) {
            return {
              ...bet,
              stake: action.stake,
            };
          }

          return bet;
        }),
      };
    }
    case BetslipEventType.CLEAR:
      return {
        ...state,
        betslip: INITIAL_STATE.betslip,
      };
    case BetslipEventType.RESET_EXOTIC_SELECTIONS:
      return {
        ...state,
        exoticSelections: INITIAL_STATE.exoticSelections,
      };
    case BetslipEventType.SET_EXOTIC_BOXED: {
      if (!state.boxed) {
        const selections = Array.from(
          new Set(
            state.exoticSelections[action.betType as ExoticBetType].flat()
          )
        );

        return {
          ...state,
          boxed: !state.boxed,
          exoticSelections: {
            ...state.exoticSelections,
            [action.betType]: state.exoticSelections[
              action.betType as ExoticBetType
            ].map(() => selections),
          },
        };
      }

      return {
        ...state,
        boxed: !state.boxed,
      };
    }
    case BetslipEventType.UPDATE_EXOTIC_SELECTION: {
      const { betType, index, competitor } = action;
      const exoticBet = state.exoticSelections[betType as ExoticBetType];
      if (!exoticBet) return state;

      return {
        ...state,
        exoticSelections: {
          ...state.exoticSelections,
          [betType]: state.exoticSelections[betType as ExoticBetType].map(
            (selection, i) => {
              if (state.boxed || i === index) {
                if (
                  selection.some(
                    (c: Competitor) => c.tabNo === competitor.tabNo
                  )
                ) {
                  return selection.filter((c) => c.tabNo !== competitor.tabNo);
                }
                return [...selection, competitor];
              }
              return selection;
            }
          ),
        },
      };
    }
    case BetslipEventType.UPDATE_EXOTIC_SELECTION_ALL: {
      const { betType, index, selections } = action;
      const exoticBet = state.exoticSelections[betType as ExoticBetType];
      if (!exoticBet) return state;

      return {
        ...state,
        exoticSelections: {
          ...state.exoticSelections,
          [betType]: exoticBet.map((selection, i) => {
            if (state.boxed || i === index) {
              if (selection.length !== selections.length) {
                return selections;
              }

              return [];
            }

            return selection;
          }),
        },
      };
    }
    case BetslipEventType.RESET_MULTI_SELECTIONS: {
      return {
        ...state,
        multiSelections: INITIAL_STATE.multiSelections,
      };
    }
    case BetslipEventType.UPDATE_MULTI_RACES: {
      const { betType } = action;
      const multiBet = state.multiSelections[betType as string as MultiBetType];
      if (!multiBet) return state;

      return {
        ...state,
        multiSelections: {
          ...state.multiSelections,
          [betType]: {
            ...multiBet,
            selections:
              INITIAL_STATE.multiSelections[betType as string as MultiBetType]
                .selections,
            races: action.races.slice(
              0,
              INITIAL_STATE.multiSelections[betType as string as MultiBetType]
                .selections.length
            ),
          },
        },
      };
    }
    case BetslipEventType.UPDATE_MULTI_SELECTION: {
      const { betType, competitor } = action;
      const multiBet = state.multiSelections[betType as string as MultiBetType];
      if (!multiBet) return state;

      const raceIndex = multiBet.races.indexOf(action.raceId);

      if (raceIndex === -1) return state;

      return {
        ...state,
        multiSelections: {
          ...state.multiSelections,
          [betType]: {
            ...multiBet,
            selections: multiBet.selections.map((selection, index) => {
              if (raceIndex === index) {
                if (
                  selection.some(
                    (c: Competitor) => c.tabNo === competitor.tabNo
                  )
                ) {
                  return selection.filter((c) => c.tabNo !== competitor.tabNo);
                }

                return [...selection, competitor];
              }

              return selection;
            }),
          },
        },
      };
    }
    case BetslipEventType.UPDATE_MULTI_SELECTION_ALL: {
      const { betType, index, selections } = action;
      const multiBet = state.multiSelections[betType as string as MultiBetType];
      if (!multiBet) return state;

      return {
        ...state,
        multiSelections: {
          ...state.multiSelections,
          [betType]: {
            ...multiBet,
            selections: multiBet.selections.map((selection, i) => {
              if (index === i) {
                if (selection.length !== selections.length) {
                  return selections;
                }

                return [];
              }

              return selection;
            }),
          },
        },
      };
    }
    case BetslipEventType.TOGGLE_BETSLIP:
      return {
        ...state,
        showBetslip: !state.showBetslip,
      };
    default:
      return state;
  }
}
