import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccountOverview from './AccountOverview/AccountOverview';
import Deposit from './Deposit/Deposit';
import MyBets from './MyBets/MyBets';
import TransactionHistory from './MyBets/TransactionHistory/TransactionHistory';
import PlayersCard from './PlayersCard/PlayersCard';
import ResponsibleGambling from './ResponsibleGambling/ResponsibleGambling';
import Settings from './Settings/Settings';
import Withdraw from './Withdraw/Withdraw';

export default function Account() {
  return (
    <>
      <Helmet titleTemplate="%s · Account · BetMakers">
        <meta name="description" content="Account" />
      </Helmet>
      <Switch>
        <Route exact path="/account/overview" component={AccountOverview} />
        <Route path="/account/my-bets" component={MyBets} />
        <Route
          exact
          path="/account/transaction-history"
          component={TransactionHistory}
        />
        <Route exact path="/account/deposit" component={Deposit} />
        <Route exact path="/account/withdraw" component={Withdraw} />
        <Route path="/account/settings" component={Settings} />
        <Route exact path="/account/players-card" component={PlayersCard} />
        <Route
          exact
          path="/account/responsible-gambling"
          component={ResponsibleGambling}
        />
        <Redirect to="/account/overview" />
      </Switch>
    </>
  );
}
