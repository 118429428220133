import Icon from '../../../components/Icon/Icon';
import useBetslip from '../../../hooks/useBetslip/useBetslip';
import useFormat from '../../../hooks/useFormat';
import styles from './ExoticBetCard.module.css';
import SetStakeInput from './SetStakeInput';
import { TraditionalExoticBet } from './types';

export default function ExoticBetCard({ bet }: { bet: TraditionalExoticBet }) {
  const { race, meeting, stake } = bet;
  const format = useFormat();
  const { removeBetByBetTypeRace } = useBetslip();

  return (
    <div className={styles.betItem}>
      <div className={styles.header}>
        <div className={styles.flexRow}>
          <Icon name={meeting.type} className={styles.icon} />
          <div>
            <span className={styles.betType}>{bet.betType}</span>
            <span className={styles.race}>
              {meeting.track.name} Race {race.number}
            </span>
          </div>
        </div>

        <div className={styles.flexRow}>
          <span className={styles.stake}>{format.currency(stake || 0)}</span>
          <div>
            <button
              type="button"
              className={styles.removeButton}
              onClick={() =>
                removeBetByBetTypeRace({
                  raceId: race.id,
                  betType: bet.betType,
                })
              }
            >
              <Icon name="cross" size="small" />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.selections}>
        {bet.selections.map((selection, index) => (
          <div key={format.ordinal(index)}>
            {`${format.ordinal(index + 1)}: `}
            {selection.map((s) => s.tabNo).toString()}
          </div>
        ))}
      </div>
      <SetStakeInput bet={bet} />
    </div>
  );
}
