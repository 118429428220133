import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import Icon from '../../../../components/Icon/Icon';
import useProxy from '../../../../hooks/useProxy';
import { BetHistoryType } from '../../../../types/betHistoryType';
import { SortOrder } from '../../../../utils/race/types';
import Filters, { FilterButton } from '../Filters/Filters';
import MyBetCard from '../MyBetCard/MyBetCard';
import styles from './PendingBets.module.css';

const now = dayjs();
export enum SortByKey {
  DATE_PLACED = 'Date Placed',
  UPCOMING = 'Upcoming',
  BET_AMOUNT = 'Bet Amount',
}

export default function PendingBets() {
  const startOfDay = now.startOf('date');
  const startString = startOfDay.subtract(12, 'hours').format('YYYY-MM-DD');
  const endString = startOfDay.add(12, 'hours').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState<string>(startString);
  const [endDate, setEndDate] = useState<string>(endString);
  const [sortBy, setSortBy] = useState(SortByKey.DATE_PLACED);
  const [sortDesc, setSortDesc] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const axios = useProxy();

  const betHistoryData = useQuery<{ historicBets: BetHistoryType[] }>(
    ['BetHistory', endDate, startDate],
    async () => {
      const response = await axios.get('/api/v3/falcon/bet/history', {
        params: {
          'dateRange.endDate': endDate,
          'dateRange.startDate': startDate,
          status: 'ACTIVE',
        },
      });
      return response.data;
    }
  );

  const handleChangeSort = (sortValue: SortByKey) => {
    if (sortValue === sortBy) {
      setSortDesc((prev) => !prev);
    } else {
      setSortBy(sortValue);
      setSortDesc(true);
    }
  };

  const renderSortIcon = (sortValue: SortByKey) => {
    if (sortValue !== sortBy) {
      return 'sort';
    }

    return sortDesc ? SortOrder.DESCENDING : SortOrder.ASCENDING;
  };

  const bets = betHistoryData?.data?.historicBets || [];

  const sortFn = (a: BetHistoryType, b: BetHistoryType) => {
    const truthy = sortDesc ? -1 : 1;
    const falsy = sortDesc ? 1 : -1;
    switch (sortBy) {
      case SortByKey.DATE_PLACED:
        return a.createdOn < b.createdOn ? truthy : falsy;
      case SortByKey.UPCOMING:
        return a.track.card.race.date < b.track.card.race.date ? truthy : falsy;
      case SortByKey.BET_AMOUNT:
        return parseFloat(a.bet.totalCost) < parseFloat(b.bet.totalCost)
          ? truthy
          : falsy;
      default:
        return 1;
    }
  };

  const sortedBets = [...bets].sort((a, b) => sortFn(a, b));
  return (
    <>
      <Helmet title="Pending">
        <meta name="description" content="Pending Bets" />
      </Helmet>
      <div className={styles.filters}>
        <div className={styles.filtersSelect}>
          <FilterButton selected={filtersOpen} setSelected={setFiltersOpen} />
        </div>
        <Filters
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          filtersOpen={filtersOpen}
        />
      </div>
      <div className={styles.sort}>
        <span
          className={clsx(styles.header, styles.actions, {
            [styles.selected]: sortBy === SortByKey.DATE_PLACED,
          })}
          onClick={() => handleChangeSort(SortByKey.DATE_PLACED)}
          role="button"
          tabIndex={-1}
        >
          DATE PLACED&nbsp;
          <Icon name={renderSortIcon(SortByKey.DATE_PLACED)} size="small" />
        </span>
        <span
          className={clsx(styles.header, styles.actions, {
            [styles.selected]: sortBy === SortByKey.UPCOMING,
          })}
          onClick={() => handleChangeSort(SortByKey.UPCOMING)}
          role="button"
          tabIndex={-1}
        >
          UPCOMING&nbsp;
          <Icon name={renderSortIcon(SortByKey.UPCOMING)} size="small" />
        </span>
        <span
          className={clsx(styles.header, styles.actions, {
            [styles.selected]: sortBy === SortByKey.BET_AMOUNT,
          })}
          onClick={() => handleChangeSort(SortByKey.BET_AMOUNT)}
          role="button"
          tabIndex={-1}
        >
          BET AMOUNT&nbsp;
          <Icon name={renderSortIcon(SortByKey.BET_AMOUNT)} size="small" />
        </span>
      </div>
      <div>
        {sortedBets?.map((bet) => (
          <MyBetCard bet={bet} />
        ))}
      </div>
    </>
  );
}
