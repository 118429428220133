import clsx from 'clsx';
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Icon, { IconName } from '../../../components/Icon/Icon';
import { OddsDisplayType } from '../../../hooks/useAppState/AppStateContext';
import useAppState from '../../../hooks/useAppState/useAppState';
import useBetslip from '../../../hooks/useBetslip/useBetslip';
import { useMeasure } from '../../../hooks/useMeasure';
import useRemoteConfig from '../../../hooks/useRemoteConfig/useRemoteConfig';
import styles from './Navigation.module.css';
import UpcomingRaces from './UpcomingRaces/UpcomingRaces';

type NavigationItem = {
  label: string;
  url: string;
  component?: React.ReactNode;
};

type RootNavigationItem = NavigationItem & {
  icon?: IconName;
  children?: NavigationItem[];
  hideOnMobile?: boolean;
};

export default function Navigation() {
  const history = useHistory();
  const location = useLocation();
  const { racingEnabled, oddsSwitcherEnabled } = useRemoteConfig();
  const { updateSettings, settings, config } = useAppState();
  const { toggleBetslip } = useBetslip();

  const [navExpanded, setNavExpanded] = useState<{ [key: string]: boolean }>(
    {}
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const oddsDisplay = settings?.oddsType || 'DECIMAL';
  const setOddsDisplay = (value: OddsDisplayType) => {
    updateSettings((prevSettings) => ({ ...prevSettings, oddsType: value }));
  };

  const mobileNavRef = useRef<HTMLDivElement>(null);
  const mobileNavRect = useMeasure(mobileNavRef);

  const oddsDisplayOptions = [
    {
      text: 'Decimal',
      value: 'DECIMAL',
    },
    {
      text: 'American',
      value: 'AMERICAN',
    },
    {
      text: 'Fractional',
      value: 'FRACTIONAL',
    },
  ];

  // TODO: Memoized as we will eventually have navigation based on authenticated status
  const navigation: RootNavigationItem[] = useMemo(() => {
    const items: (RootNavigationItem | null)[] = [
      {
        icon: 'homeFilled',
        label: 'Home',
        url: '/',
      },
      racingEnabled
        ? {
            icon: 'thoroughbredFilled',
            label: 'Racing',
            url: '/racing',
            children: [
              {
                label: 'Todays Racing',
                url: '/racing/home/today',
              },
              {
                label: 'Upcoming Races',
                url: '/racing/home/upcoming',
              },
              {
                label: 'Results',
                url: '/racing/home/results',
              },
            ],
          }
        : null,
      {
        icon: 'accountFilled',
        label: 'Account',
        url: '/account',
        children: [
          {
            label: 'Overview',
            url: '/account/overview',
          },
          {
            label: 'My Bets',
            url: '/account/my-bets',
          },
          {
            label: 'Deposit',
            url: '/account/deposit',
          },
          {
            label: 'Withdraw',
            url: '/account/withdraw',
          },
          {
            label: 'Settings',
            url: '/account/settings',
          },
          {
            label: 'Player’s Card',
            url: '/account/players-card',
          },
          {
            label: 'Responsible Gambling',
            url: '/account/responsible-gambling',
          },
        ],
      },
      racingEnabled
        ? {
            icon: 'timerFilled',
            label: 'Upcoming',
            url: '#',
            children: [],
            component: <UpcomingRaces />,
            hideOnMobile: true,
          }
        : null,
    ];
    return items.filter(Boolean) as RootNavigationItem[];
  }, [racingEnabled]);

  useEffect(() => {
    const foundNavWithChildren = navigation.find(
      (navItem) => location.pathname.includes(navItem.url) && navItem.children
    );
    if (foundNavWithChildren) {
      setNavExpanded((prev) => ({
        ...prev,
        [foundNavWithChildren.label]: true,
      }));
    }
  }, [location.pathname, navigation]);

  useEffect(() => {
    setNavExpanded((prev) => ({
      ...prev,
      Upcoming: settings.upcomingExpanded,
    }));
  }, [settings.upcomingExpanded]);

  useEffect(() => {
    function handleResize() {
      setMenuOpen(false);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  function toggleNavExpanded(event: MouseEvent<HTMLElement>, label: string) {
    event.stopPropagation();
    event.preventDefault();
    setNavExpanded((prev) => ({ ...prev, [label]: !prev[label] }));
    if (label === 'Upcoming')
      updateSettings((prevSettings) => ({
        ...prevSettings,
        upcomingExpanded: !settings.upcomingExpanded,
      }));
  }

  function changeOddsDisplay(optionSelected: string) {
    const isOddsDisplayType = (input: string): input is OddsDisplayType =>
      ['DECIMAL', 'AMERICAN', 'FRACTIONAL'].includes(input);

    if (isOddsDisplayType(optionSelected)) setOddsDisplay(optionSelected);
  }

  function childSelected(navItem: RootNavigationItem) {
    return navItem.children?.some((child) => child.url === location.pathname);
  }

  return (
    <>
      {menuOpen
        ? createPortal(
            <div
              className={styles.background}
              onClick={() => setMenuOpen(false)}
              role="presentation"
            />,
            document.querySelector('.bm-root') || document.body
          )
        : null}
      <div
        className={clsx(styles.mainNavigationContainer, {
          [styles.open]: menuOpen,
        })}
        style={{
          height: `calc(100% - ${mobileNavRect?.height}px)`,
        }}
      >
        <div className={styles.logoContainer}>
          <Link to="/" className={styles.logoLink}>
            <img
              src={`${config?.contentUrl}/betmakers.svg`}
              alt="BetMakers"
              className={styles.logo}
            />
          </Link>
        </div>
        <nav className={styles.navigation}>
          <ul className={styles.navList}>
            {navigation.map((navItem) => {
              const expanded =
                navExpanded[navItem.label] || childSelected(navItem);

              return (
                <li key={navItem.label} className={styles.navListItem}>
                  {navItem.url !== '#' ? (
                    <Link
                      to={navItem.url}
                      className={clsx(styles.navItem, {
                        [styles.selected]:
                          location.pathname === navItem.url ||
                          (navItem.url.length > 1 &&
                            location.pathname.startsWith(navItem.url)) ||
                          childSelected(navItem),
                      })}
                    >
                      <NavItem
                        navItem={navItem}
                        expanded={!!expanded}
                        onClick={toggleNavExpanded}
                      />
                    </Link>
                  ) : (
                    <button
                      type="button"
                      onClick={(event) =>
                        toggleNavExpanded(event, navItem.label)
                      }
                      className={styles.navItem}
                    >
                      <NavItem
                        navItem={navItem}
                        expanded={!!expanded}
                        onClick={toggleNavExpanded}
                      />
                    </button>
                  )}
                  {navItem.children && expanded ? (
                    <ul className={styles.navList}>
                      {navItem.children.map((child) => (
                        <li key={child.label} className={styles.navListItem}>
                          <Link
                            to={child.url}
                            className={clsx(styles.childNavItem, {
                              [styles.selected]: location.pathname.startsWith(
                                child.url
                              ),
                            })}
                          >
                            <span />
                            <span className={styles.navItemLabel}>
                              {child.label}
                            </span>
                            <span />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  {navExpanded[navItem.label] && navItem?.component
                    ? navItem.component
                    : null}
                </li>
              );
            })}
          </ul>
        </nav>
        {oddsSwitcherEnabled ? (
          <div className={styles.divOddsSwitcher}>
            <select
              className={styles.oddsSwitcher}
              onChange={(event) => changeOddsDisplay(event.target.value)}
              value={oddsDisplay}
            >
              {oddsDisplayOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  Odds: {option.text}
                </option>
              ))}
            </select>
          </div>
        ) : null}
      </div>
      <div className={styles.mobileNavigationContainer} ref={mobileNavRef}>
        <div className={styles.mobileNavigation}>
          {navigation
            .filter((navItem) => !navItem.hideOnMobile)
            .slice(0, navigation.length / 2 + 1)
            .map((navItem) => (
              <button
                type="button"
                key={navItem.label}
                className={clsx(styles.mobileNavButton, {
                  [styles.selected]:
                    location.pathname === navItem.url ||
                    (navItem.url.length > 1 &&
                      location.pathname.startsWith(navItem.url)) ||
                    childSelected(navItem),
                })}
                onClick={() => history.push(navItem.url)}
              >
                {navItem.icon ? (
                  <Icon name={navItem.icon} className={styles.icon} />
                ) : null}
                <span className={styles.label}>{navItem.label}</span>
              </button>
            ))}
          <button
            type="button"
            className={styles.mobileNavButton}
            onClick={toggleBetslip}
          >
            <Icon name="betslipFilled" className={styles.icon} />
            <span className={styles.label}>Betslip</span>
          </button>
          {navigation
            .filter((navItem) => !navItem.hideOnMobile)
            .slice(navigation.length / 2 + 1)
            .map((navItem) => (
              <button
                type="button"
                key={navItem.label}
                className={clsx(styles.mobileNavButton, {
                  [styles.selected]:
                    location.pathname === navItem.url ||
                    (navItem.url.length > 1 &&
                      location.pathname.startsWith(navItem.url)) ||
                    childSelected(navItem),
                })}
                onClick={() => history.push(navItem.url)}
              >
                {navItem.icon ? (
                  <Icon name={navItem.icon} className={styles.icon} />
                ) : null}
                <span className={styles.label}>{navItem.label}</span>
              </button>
            ))}
          <button
            type="button"
            className={styles.mobileNavButton}
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            <Icon
              name="hamburger"
              className={clsx(styles.icon, styles.hamburger)}
            />
            <span className={styles.label}>Menu</span>
          </button>
        </div>
      </div>
    </>
  );
}

function NavItem({
  navItem,
  onClick,
  expanded,
}: {
  navItem: RootNavigationItem;
  onClick: (event: MouseEvent<HTMLElement>, label: string) => void;
  expanded: boolean;
}) {
  return (
    <>
      {navItem.icon ? (
        <Icon name={navItem.icon} className={styles.icon} />
      ) : null}
      <span className={styles.navItemLabel}>{navItem.label}</span>
      {navItem.children ? (
        <div
          onClick={(event) => onClick(event, navItem.label)}
          className={clsx(styles.chevronButton, {
            [styles.expanded]: expanded,
          })}
          role="presentation"
          tabIndex={-1}
        >
          <Icon name="caretDown" />
        </div>
      ) : null}
    </>
  );
}
