import clsx from 'clsx';
import { useContext } from 'react';
import Button from '../../../../../components/Button/Button';
import { ExoticBetType } from '../../../../../constants/betTypes';
import useBetslip from '../../../../../hooks/useBetslip/useBetslip';
import useExoticBetslip from '../../../../../hooks/useBetslip/useExoticBetslip';
import RaceContext from '../../RaceContext';
import styles from './SelectionHeader.module.css';

function AllButton({ column }: { column: number }) {
  const {
    state: { boxed, exoticSelections },
  } = useBetslip();
  const { updateExoticSelectionAll } = useExoticBetslip();
  const { sortedCompetitors, betType } = useContext(RaceContext);
  const selectedBet = exoticSelections[betType as string as ExoticBetType];

  function handleSelectAll() {
    const index = boxed ? 0 : column;
    const selections = sortedCompetitors.filter((c) => !c.scratched);

    updateExoticSelectionAll({
      betType,
      index,
      selections,
    });
  }

  function isSelected(index: number) {
    return (
      selectedBet?.[index]?.length ===
      sortedCompetitors.filter((c) => !c.scratched)?.length
    );
  }

  return (
    <Button
      onClick={handleSelectAll}
      className={clsx(styles.button, {
        [styles.selected]: isSelected(column),
      })}
    >
      <span>{boxed ? 'SELECT ALL' : 'ALL'}</span>
    </Button>
  );
}

export default function ExoticSelectionHeader() {
  const {
    state: { exoticSelections, boxed },
  } = useBetslip();
  const { betType } = useContext(RaceContext);
  const selectedBet = exoticSelections[betType as string as ExoticBetType];

  return boxed ? (
    <span className={clsx(styles.header, styles.actions)}>
      <AllButton column={0} />
    </span>
  ) : (
    <>
      <span className={clsx(styles.header, styles.actions)}>
        {selectedBet.map((selection, i) => (
          <AllButton key={i.toString()} column={i} />
        ))}
      </span>
    </>
  );
}
