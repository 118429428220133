import clsx from 'clsx';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './HorizontalOverflow.module.css';

type Props = {
  scrollWidth?: number;
  children: ReactNode;
};

export default function HorizontalOverflow({
  scrollWidth = 120,
  children,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  useEffect(() => {
    function init() {
      if (scrollContainerRef.current) {
        setShowLeft(scrollContainerRef.current.scrollLeft > 0);
        setShowRight(
          scrollContainerRef.current.clientWidth <
            scrollContainerRef.current.scrollWidth
        );
      }
    }

    if (scrollContainerRef.current) {
      init();
      window.addEventListener('resize', init);
      return () => window.removeEventListener('resize', init);
    }

    return () => {};
  }, [scrollContainerRef]);

  function handleScroll(event: React.UIEvent<HTMLDivElement>) {
    setShowLeft(event.currentTarget.scrollLeft > 0);
    setShowRight(
      event.currentTarget.scrollLeft <
        event.currentTarget.scrollWidth - event.currentTarget.clientWidth
    );
  }

  return (
    <div className={styles.container} ref={containerRef}>
      <button
        type="button"
        className={clsx(styles.arrow, styles.left, {
          [styles.visible]: showLeft,
        })}
        onClick={() => {
          if (scrollContainerRef.current) {
            scrollContainerRef.current?.scrollTo({
              left: scrollContainerRef.current.scrollLeft - scrollWidth,
              behavior: 'smooth',
            });
          }
        }}
      >
        <Icon name="caretLeft" size="small" />
      </button>

      <div
        className={styles.scrollContainer}
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        {children}
      </div>

      <button
        type="button"
        className={clsx(styles.arrow, styles.right, {
          [styles.visible]: showRight,
        })}
        onClick={() => {
          if (scrollContainerRef.current) {
            scrollContainerRef.current?.scrollTo({
              left: scrollContainerRef.current.scrollLeft + scrollWidth,
              behavior: 'smooth',
            });
          }
        }}
      >
        <Icon name="caretRight" size="small" />
      </button>
    </div>
  );
}
