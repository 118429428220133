import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import Container from '../../../components/Container/Container';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import PendingBets from './PendingBets/PendingBets';
import Statements from './Statements/Statements';
import TransactionHistory from './TransactionHistory/TransactionHistory';

export default function MyBets() {
  const route = useMemo(
    () => [
      {
        label: 'Account',
        url: '/account',
      },
      {
        label: 'My Bets',
        url: '/account/my-bets',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  return (
    <HomeContainer
      HeaderComponent={<h1>My Bets</h1>}
      tabs={[
        {
          label: 'Pending',
          url: '/account/my-bets/pending',
        },
        {
          label: 'Resulted',
          url: '/account/my-bets/resulted',
        },
        {
          label: 'Transactions',
          url: '/account/my-bets/transaction-history',
        },
        {
          label: 'ROI Calculator',
          url: '/account/my-bets/roi',
        },
        {
          label: 'Statements',
          url: '/account/my-bets/statements',
        },
      ]}
    >
      <Helmet title="My Bets">
        <meta name="description" content="My Bets" />
      </Helmet>
      <Container>
        <Switch>
          <Route
            exact
            path="/account/my-bets/pending"
            component={PendingBets}
          />
          <Route
            exact
            path="/account/my-bets/transaction-history"
            component={TransactionHistory}
          />
          <Route
            exact
            path="/account/my-bets/statements"
            component={Statements}
          />
          <Redirect to="/account/my-bets/pending" />
        </Switch>
      </Container>
    </HomeContainer>
  );
}
