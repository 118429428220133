import QueryString from 'qs';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import CardLabel from '../../../../components/Card/CardLabel';
import Icon from '../../../../components/Icon/Icon';
import ChangeEmailModal from './ChangeEmailModal';
import ChangeMobileModal from './ChangeMobileModal';
import ChangePasswordModal from './ChangePasswordModal';
import ChangePinModal from './ChangePinModal';
import ChangeSecretWordModal from './ChangeSecretWordModal';
import styles from './PersonalSettings.module.css';

export default function PersonalSettings() {
  const location = useLocation();
  const history = useHistory();
  const { action, ...remainingQuery } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const dismissModal = useCallback(
    () => history.push({ search: QueryString.stringify(remainingQuery) }),
    [history, remainingQuery]
  );

  return (
    <>
      <Helmet title="Personal">
        <meta name="description" content="Personal Settings" />
      </Helmet>
      <CardLabel label="PERSONAL DETAILS" />
      <div className={styles.row}>
        <div>
          <Card className={styles.card}>
            <div className={styles.detail}>
              <span className={styles.label}>First Name</span>
              <span className={styles.value}>John</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Last Name</span>
              <span className={styles.value}>Smith</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>User Name</span>
              <span className={styles.value}>Johnsmith21</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Password</span>
              <span className={styles.value}>*******</span>
              <Link
                to={{
                  search: QueryString.stringify({
                    ...QueryString.parse(location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    action: 'change_password',
                  }),
                }}
                className={styles.link}
              >
                <Icon name="edit" />
              </Link>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Email</span>
              <span className={styles.value}>johnsmith@smithyjohns.com</span>
              <Link
                to={{
                  search: QueryString.stringify({
                    ...QueryString.parse(location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    action: 'change_email',
                  }),
                }}
                className={styles.link}
              >
                <Icon name="edit" />
              </Link>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Mobile Number</span>
              <span className={styles.value}>732-***-**59</span>
              <Link
                to={{
                  search: QueryString.stringify({
                    ...QueryString.parse(location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    action: 'change_mobile',
                  }),
                }}
                className={styles.link}
              >
                <Icon name="edit" />
              </Link>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Address</span>
              <span className={styles.value}>
                801 Sugar Drive, Buffalo, NY 14215
              </span>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Account Pin</span>
              <span className={styles.value}>****</span>
              <Link
                to={{
                  search: QueryString.stringify({
                    ...QueryString.parse(location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    action: 'change_pin',
                  }),
                }}
                className={styles.link}
              >
                <Icon name="edit" />
              </Link>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Secret Word</span>
              <span className={styles.value}>****</span>
              <Link
                to={{
                  search: QueryString.stringify({
                    ...QueryString.parse(location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    action: 'change_secret',
                  }),
                }}
                className={styles.link}
              >
                <Icon name="edit" />
              </Link>
            </div>
            <div className={styles.detail}>
              <span className={styles.label}>Tote ID</span>
              <span className={styles.value}>****</span>
            </div>
          </Card>
        </div>
        <div>
          <Card className={styles.card}>
            <Link
              to={{
                search: QueryString.stringify({
                  ...QueryString.parse(location.search, {
                    ignoreQueryPrefix: true,
                  }),
                  action: 'change_password',
                }),
              }}
              className={styles.secretDetail}
            >
              <Icon name="lock" />
              <span>Change Password</span>
              <Icon name="caretRight" />
            </Link>
            <Link
              to={{
                search: QueryString.stringify({
                  ...QueryString.parse(location.search, {
                    ignoreQueryPrefix: true,
                  }),
                  action: 'change_pin',
                }),
              }}
              className={styles.secretDetail}
            >
              <Icon name="lock" />
              <span>Change Pin</span>
              <Icon name="caretRight" />
            </Link>
            <Link
              to={{
                search: QueryString.stringify({
                  ...QueryString.parse(location.search, {
                    ignoreQueryPrefix: true,
                  }),
                  action: 'change_secret',
                }),
              }}
              className={styles.secretDetail}
            >
              <Icon name="lock" />
              <span>Change Secret Word</span>
              <Icon name="caretRight" />
            </Link>
          </Card>
        </div>
      </div>
      {action === 'change_password' ? (
        <ChangePasswordModal onDismiss={dismissModal} />
      ) : null}
      {action === 'change_email' ? (
        <ChangeEmailModal onDismiss={dismissModal} />
      ) : null}
      {action === 'change_mobile' ? (
        <ChangeMobileModal onDismiss={dismissModal} />
      ) : null}
      {action === 'change_pin' ? (
        <ChangePinModal onDismiss={dismissModal} />
      ) : null}
      {action === 'change_secret' ? (
        <ChangeSecretWordModal onDismiss={dismissModal} />
      ) : null}
    </>
  );
}
