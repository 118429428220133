export enum SortByKey {
  NAME = 'name',
  WIN = 'win',
  PLACE = 'place',
  SHOW = 'show',
  ML = 'ml',
  MTP = 'mtp',
  TRACK = 'tr',
  POOL_AMOUNT = 'pool-amount',
}

export enum SortOrder {
  ASCENDING = 'sortAscending',
  DESCENDING = 'sortDescending',
}

export type ExoticSelection = {
  [key: string]: number[];
};
