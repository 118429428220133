import styles from './InfoBox.module.css';

export type InfoBoxItem = {
  title: string;
  value: string;
  spanText?: string;
};

export default function InfoBox({ data }: { data: InfoBoxItem[] }) {
  return (
    <div className={styles.innerContainer}>
      <table>
        <tbody>
          {data.map((row) => (
            <tr className={styles.tableRow} key={row.title}>
              <td className={styles.leftColumn}>{row.title}</td>
              <td className={styles.rightColumn}>
                {`${row.value} `}
                {row.spanText && (
                  <span className={styles.regularFontWeight}>
                    {row.spanText}
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
