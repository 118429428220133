import axios from 'axios';
import { useMemo } from 'react';
import useAppState from './useAppState/useAppState';

export default function useProxy() {
  const { config } = useAppState();
  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: config?.proxyUrl,
        headers: {
          'X-G440-Tenant': 'SPORTECH',
          'X-G440-Platform': 'web',
        },
      }),
    [config?.proxyUrl]
  );

  return axiosInstance;
}
