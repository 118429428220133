import { gql, useQuery } from '@apollo/client';
import { Meeting } from '../useRace/types';

export default function useMeeting(id: string) {
  const { data } = useQuery<{
    meeting: Meeting;
  }>(
    gql`
      query meeting($id: ID) {
        meeting(id: $id) {
          id
          externalIDs(sources: "gbs") {
            id
          }
          races {
            id
            number
            status
          }
          track {
            name
            surface
          }
        }
      }
    `,
    {
      variables: {
        id,
      },
    }
  );

  return data?.meeting;
}
