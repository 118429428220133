import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

/**
 *
 * Copied implimentation out of BAW legacy platform
 *
 * this extends the react useState to also send/get values in localStorage
 * Limitations: dispite it storage in localStorage it doesnt subscribe to the
 * Storage Event API so it wont sync changes when same key is used in different
 * components / windows / tabs. however it could be extended to do so
 * previously we used "use-persisted-state": "^0.3.0" which did do this
 * however it had a incompatibility with safari / reactNative when running on mac
 * devices (ends up trying to parse non JSON data and crashes and fills our error logs)
 * is a feature we can look at adding in the future.
 *
 * I've added a forceRead function which can be used to manual resync
 * hooks sharing the same key
 *
 * Also credit to https://usehooks.com/useLocalStorage/ which had a good example
 *
 */
function useLocalStorage<T>(
  key: string,
  initialValue?: T | (() => T)
): [T, Dispatch<SetStateAction<T>>] {
  const initialize = () => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  };

  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(initialize);

  // if key changes then reinitialize
  useEffect(
    () => setStoredValue(initialize),
    // will fix once moved to a TS file
    // eslint-disable-next-line
    [key]
  );

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((input: T) => T)) => {
      setStoredValue((prev) => {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(prev) : value;
        try {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
          // wasn't able to save to localstorage
        }
        return valueToStore;
      });
    },
    [key]
  );

  return [storedValue, setValue];
}

export default useLocalStorage;
