import { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import raceStatuses from '../../../constants/raceStatuses';
import useMeeting from '../../../hooks/useMeetings/useMeeting';

// Meetings will always redirect to the latest race card
export default function Meeting() {
  const match = useRouteMatch<{
    meetingId: string;
  }>();
  const history = useHistory();
  const { meetingId } = match.params;
  const meeting = useMeeting(meetingId);

  useEffect(() => {
    if (meeting) {
      const nextRace =
        meeting.races.filter((race) => race.status === raceStatuses.OPEN)[0] ||
        meeting.races[meeting.races.length - 1];

      if (!nextRace) {
        history.push('/racing');
      }

      history.push(`/racing/results/race/${nextRace.id}`);
    }
  }, [meeting, history, meetingId]);

  return null;
}
