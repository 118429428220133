import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { OddsboardSelectionType } from '../../../../constants/oddsboardTypes';
import useAppState from '../../../../hooks/useAppState/useAppState';
import { Race } from '../../../../hooks/useRace/types';
import RaceContext from '../RaceContext';
import AllPools from './AllPools';
import Double from './Double';
import Exacta from './Exacta';
import styles from './Oddsboard.module.css';
import OddsboardTabs from './OddsboardTabs';
import WPSBoard from './WPSBoard';

export default function Oddsboard({ race }: { race: Race }) {
  const { settings } = useAppState();
  const [updatedAt, setUpdatedAt] = useState<Date | undefined>(undefined);

  const raceContext = useContext(RaceContext);

  const timeZone = dayjs.tz.guess();
  const updatedString = `at ${dayjs(updatedAt)
    .tz(timeZone)
    .format('h:mma z')} (${timeZone})`;
  const noUpdateString = 'time unavailable';

  if (!raceContext?.race) return null;

  return (
    <div className={styles.container}>
      <div className={styles.title}>Oddsboard</div>
      <div className={styles.subText}>
        {`Information updated ${updatedAt ? updatedString : noUpdateString}`}
      </div>
      <OddsboardTabs />

      {settings.oddsboardSelected === OddsboardSelectionType.WIN_PLACE_SHOW && (
        <WPSBoard race={raceContext.race} />
      )}
      {settings.oddsboardSelected === OddsboardSelectionType.EXACTA && (
        <Exacta race={raceContext.race} />
      )}
      {settings.oddsboardSelected === OddsboardSelectionType.DOUBLE && (
        <Double race={raceContext.race} />
      )}
      {settings.oddsboardSelected === OddsboardSelectionType.WILL_PAYS && (
        <div>I am Will Pays</div>
      )}
      {settings.oddsboardSelected === OddsboardSelectionType.ALL_POOLS && (
        <AllPools race={raceContext.race} setUpdatedAt={setUpdatedAt} />
      )}
    </div>
  );
}
