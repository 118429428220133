import axios from 'axios';
import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions/FormActions';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import Select from '../../../components/Form/Select/Select';
import SignupContext, { SignupForm } from './SignupContext';
import SignupHeader from './SignupHeader';
import styles from './SignupModal.module.css';

export default function SignupStep1() {
  const history = useHistory();
  const { data, setData } = useContext(SignupContext);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SignupForm>({ defaultValues: data });

  const submit: SubmitHandler<SignupForm> = (formValues) => {
    setData?.((prev) => ({ ...prev, ...formValues }));
    history.push('/step2');
  };

  const statesAvailable = useQuery(['States'], async () => {
    const response = await axios.get(
      '/api/v1/falcon/geographic/states?bettingPossible=true'
    );
    return response.data;
  });

  return (
    <>
      <SignupHeader />
      <form onSubmit={handleSubmit(submit)} className={styles.form}>
        <InputRow label="Email Address" errorText={errors.email?.message}>
          <Input
            id="Email Address"
            autoComplete="email"
            autoFocus
            {...register('email', {
              required: 'Required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            })}
          />
        </InputRow>
        <InputRow label="Password" errorText={errors.password?.message}>
          <Input
            type="password"
            id="Password"
            autoComplete="off"
            placeholder="8 Characters + 1 Capital letter"
            {...register('password', { required: 'Required' })}
          />
        </InputRow>
        <InputRow label="Account Pin" errorText={errors.accountPin?.message}>
          <Input
            type="password"
            id="Account Pin"
            autoComplete="off"
            placeholder=""
            maxLength={4}
            {...register('accountPin', { required: 'Required' })}
          />
        </InputRow>
        <InputRow label="Secret Word" errorText={errors.secretWord?.message}>
          <Input
            type="password"
            id="Secret Word"
            autoComplete="off"
            placeholder=""
            {...register('secretWord', { required: 'Required' })}
          />
        </InputRow>
        <InputRow label="State" errorText={errors.state?.message}>
          <Select
            className={styles.select}
            options={statesAvailable?.data?.map((s: any) => ({
              label: s.name,
              value: s.abbreviation,
            }))}
            id="State"
            {...register('state', { required: 'Required' })}
          />
        </InputRow>
        <FormActions>
          <Button type="submit" disabled={isSubmitting} theme="secondary">
            Next
          </Button>
        </FormActions>
      </form>
    </>
  );
}
