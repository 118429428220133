import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import InPlayBadge from '../../../components/Badge/InPlayBadge';
import WithdrawableBadge from '../../../components/Badge/WithdrawableBadge';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import CardLabel from '../../../components/Card/CardLabel';
import Container from '../../../components/Container/Container';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import Icon from '../../../components/Icon/Icon';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import useFormat from '../../../hooks/useFormat';
import styles from './AccountOverview.module.css';

export default function AccountOverview() {
  const history = useHistory();
  const route = useMemo(
    () => [
      {
        label: 'Account',
        url: '/account',
      },
      {
        label: 'Overview',
        url: '/account/overview',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  const format = useFormat();

  return (
    <HomeContainer HeaderComponent={<h1>Account</h1>}>
      <Helmet title="Overview">
        <meta name="description" content="Account Overview" />
      </Helmet>
      <Container>
        <div className={styles.header}>
          <Card className={styles.card}>
            <div className={styles.accountContainer}>
              <h2 className={styles.title}>Hi John</h2>
              <p>
                <span className={styles.label}>Email:</span>
                <span className={styles.value}>john.smith@betmakers.com</span>
              </p>
              <p>
                <span className={styles.label}>Username:</span>
                <span className={styles.value}>johnsmith</span>
              </p>
              <p>
                <span className={styles.label}>Tote ID:</span>
                <span className={styles.value}>1268348</span>
              </p>
            </div>
          </Card>
          <Card className={styles.card}>
            <div className={styles.balanceContainer}>
              <div className={styles.grid}>
                <span className={styles.label}>Account Balance</span>
                <span className={styles.value}>{format.currency(1000)}</span>
                <span className={styles.label}>
                  In Play <InPlayBadge />
                </span>
                <span className={styles.value}>{format.currency(200)}</span>
                <span className={styles.label}>
                  Withdrawable <WithdrawableBadge />
                </span>
                <span className={styles.value}>{format.currency(900)}</span>
              </div>
              <div className={styles.actions}>
                <Button
                  theme="secondary"
                  onClick={() => history.push('/account/deposit')}
                >
                  Deposit
                </Button>
                <Button
                  theme="transparentDark"
                  onClick={() => history.push('/account/my-bets/pending')}
                >
                  Pending Bets
                </Button>
                <Button
                  theme="transparentDark"
                  onClick={() => history.push('/account/withdraw')}
                >
                  Withdraw
                </Button>
              </div>
            </div>
          </Card>
        </div>

        <div className={styles.navigationContainer}>
          <CardLabel label="ACCOUNT" />
          <Card className={styles.navigation}>
            <Link to="/account/my-bets" className={styles.link}>
              <span>My Bets</span>
              <Icon name="caretRight" />
            </Link>
            <Link
              to="/account/my-bets/transaction-history"
              className={styles.link}
            >
              <span>Transaction History</span>
              <Icon name="caretRight" />
            </Link>
            <Link to="/account/deposit" className={styles.link}>
              <span>Deposit</span>
              <Icon name="caretRight" />
            </Link>
            <Link to="/account/withdraw" className={styles.link}>
              <span>Withdraw</span>
              <Icon name="caretRight" />
            </Link>
            <Link to="/account/settings" className={styles.link}>
              <span>Settings and preferences</span>
              <Icon name="caretRight" />
            </Link>
            <Link to="/account/players-card" className={styles.link}>
              <span>Player’s Card</span>
              <Icon name="caretRight" />
            </Link>
            <Link to="/account/responsible-gambling" className={styles.link}>
              <span>Responsible Gambling</span>
              <Icon name="caretRight" />
            </Link>
          </Card>
        </div>
      </Container>
    </HomeContainer>
  );
}
