import { MeetingType } from '../../constants/meetingTypes';
import useAppState from '../../hooks/useAppState/useAppState';
import { Competitor, Meeting, Race } from '../../hooks/useRace/types';
import styles from './Silk.module.css';

const greyHoundSilksLookup = {
  AUS: { src: '/AUSGreysSprite_lrg.png', offset: 64, qty: 10 },
  GBR: { src: '/UKGreysSprite_lrg.png', offset: 64, qty: 8 },
  USA: { src: '/USGreysSprite_lrg.png', offset: 64, qty: 9 },
};

export default function Silk({
  meeting,
  race,
  competitor,
}: {
  meeting: Meeting;
  race: Race;
  competitor: Competitor;
}) {
  const { config } = useAppState();
  const getGreyhoundSilkSettings = () => {
    const silkSrcSettings = greyHoundSilksLookup?.[meeting.track.country];
    if (!silkSrcSettings) return null;

    const { src, offset, qty } = silkSrcSettings;
    const calculatedOffset = ((competitor.tabNo - 1) % qty) * offset;

    return { src, calculatedOffset };
  };

  const greyhoundSilkSettings = getGreyhoundSilkSettings();

  return (
    <div className={styles.container}>
      <span className={styles.silkContainer}>
        {meeting.type === MeetingType.GREYHOUND ? (
          <img
            className={styles.greyhoundSilkSpritesheet}
            src={`${config?.contentUrl}${greyhoundSilkSettings?.src}`}
            alt={competitor?.name}
            style={{
              top: `-${greyhoundSilkSettings?.calculatedOffset}px`,
            }}
            onError={(event) => {
              // eslint-disable-next-line no-param-reassign
              event.currentTarget.src = '/default_silk.png';
            }}
          />
        ) : (
          <img
            className={styles.silkSpritesheet}
            src={`${process.env.REACT_APP_SILKS_URL}/${
              meeting.externalIDs?.[0].id
            }_${race.number}_SPRITE_${64}x${64}.png`}
            alt={competitor?.name}
            style={{
              left: `-${(competitor?.tabNo - 1) * 64}px`,
            }}
            onError={(event) => {
              // eslint-disable-next-line no-param-reassign
              event.currentTarget.src = '/default_silk.png';
            }}
          />
        )}
      </span>
    </div>
  );
}
