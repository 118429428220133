import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import { MeetingType } from '../../constants/meetingTypes';
import Icon, { IconSize } from '../Icon/Icon';
import styles from './RacingFilters.module.css';

export default function RacingFilters({
  hideAll = false,
  filters = [],
  setFilters,
  size,
  className = '',
}: {
  hideAll?: boolean;
  filters?: MeetingType[];
  setFilters: Dispatch<SetStateAction<MeetingType[]>>;
  size?: IconSize;
  className?: string;
}) {
  const maxLength = 3;

  function toggleFilters(meetingType: MeetingType) {
    setFilters((prev) => {
      if (prev.includes(meetingType)) {
        return prev.filter((type) => type !== meetingType);
      }

      const newFilters = [...prev, meetingType];
      return newFilters.length === maxLength ? [] : newFilters;
    });
  }

  return (
    <div className={clsx(styles.container, className)}>
      {!hideAll ? (
        <button
          type="button"
          className={clsx(styles.button, {
            [styles.selected]:
              filters.length === 0 || filters.length === maxLength,
            [styles.smallButton]: size === 'small',
          })}
          onClick={() => setFilters([])}
        >
          ALL
        </button>
      ) : null}
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: filters.includes(MeetingType.THOROUGHBRED),
          [styles.smallButton]: size === 'small',
        })}
        onClick={() => toggleFilters(MeetingType.THOROUGHBRED)}
      >
        <Icon name={MeetingType.THOROUGHBRED} size={size} />
      </button>
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: filters.includes(MeetingType.GREYHOUND),
          [styles.smallButton]: size === 'small',
        })}
        onClick={() => toggleFilters(MeetingType.GREYHOUND)}
      >
        <Icon name={MeetingType.GREYHOUND} size={size} />
      </button>
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: filters.includes(MeetingType.HARNESS),
          [styles.smallButton]: size === 'small',
        })}
        onClick={() => toggleFilters(MeetingType.HARNESS)}
      >
        <Icon name={MeetingType.HARNESS} size={size} />
      </button>
    </div>
  );
}
