import useRemoteConfig from '../../hooks/useRemoteConfig/useRemoteConfig';
import HorizontalOverflow from '../HorizontalOverflow/HorizontalOverflow';
import Icon, { IconName } from '../Icon/Icon';
import styles from './QuickLinks.module.css';

type QuickLinkItem = {
  label: string;
  url: string;
  icon: IconName;
};

export default function QuickLinks({ items = [] }: { items: QuickLinkItem[] }) {
  const { quickLinksEnabled } = useRemoteConfig();

  if (!quickLinksEnabled) return null;

  return (
    <div className={styles.container}>
      <HorizontalOverflow>
        {items.map((item) => (
          <a href={item.url} className={styles.link} key={item.label}>
            <Icon name={item.icon} className={styles.icon} size="medium" />
            <span className={styles.label}>{item.label}</span>
          </a>
        ))}
      </HorizontalOverflow>
    </div>
  );
}
