import { Dispatch, ReactNode, SetStateAction } from 'react';
import Button from '../../../components/Button/Button';
import ErrorDiv from '../../../components/ErrorDiv/ErrorDiv';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import useFormat from '../../../hooks/useFormat';
import styles from './DepositAmountInput.module.css';
import DepositCurrencyInput from './DepositCurrencyInput';

const buttonAmounts = [10, 20, 50, 100, 500];

export default function DepositAmountInput({
  buttonLabel,
  hasExistingCard = false,
  hasExistingAccount = false,
  isWithdrawal = false,
  cardSecurityHelpText,
  isAddingCard,
  isAddingAccount,
  depositType,
  setIsAddingCard,
  setIsAddingAccount,
  getValues,
  setError,
  register,
  errors,
  setValue,
}: {
  buttonLabel: string;
  hasExistingCard?: boolean;
  hasExistingAccount?: boolean;
  isWithdrawal?: boolean;
  cardSecurityHelpText?: ReactNode;
  isAddingCard?: boolean;
  isAddingAccount?: boolean;
  depositType: 'creditCard' | 'bank';
  setIsAddingCard?: Dispatch<SetStateAction<boolean>>;
  setIsAddingAccount?: Dispatch<SetStateAction<boolean>>;
  getValues: any;
  setError: any;
  register: any;
  errors: any;
  setValue: any;
}) {
  const format = useFormat();

  return (
    <div className={styles.container}>
      <ErrorDiv
        className={styles.depositInputRow}
        errorText={errors.amount?.message}
      >
        <InputRow
          label={`${isWithdrawal ? 'Withdraw' : 'Deposit'} Amount`}
          helpText={isWithdrawal ? null : 'Minimum $1 + 3.5% Fees'}
        >
          <DepositCurrencyInput
            placeholder={isWithdrawal ? 'Minimum $1' : 'Minimum $1 + 3.5% Fees'}
            register={register}
          />
        </InputRow>
        {depositType === 'creditCard' && hasExistingCard && !isAddingCard && (
          <div>
            <InputRow
              label="Security No"
              helpText={cardSecurityHelpText}
              errorText={errors.cvv?.message}
            >
              <Input
                className={styles.securityNoInput}
                id="Security No"
                autoComplete="cc-csc"
                {...register('cvv', { required: 'Required' })}
              />
            </InputRow>
          </div>
        )}
        {depositType === 'bank' &&
          hasExistingAccount &&
          !isAddingAccount &&
          !isWithdrawal && (
            <InputRow
              label="Account number - For security"
              errorText={errors.accountConfirm?.message}
            >
              <Input
                className={styles.securityNoInput}
                id="accountConfirm"
                autoComplete="off"
                {...register('accountConfirm', { required: 'Required' })}
              />
            </InputRow>
          )}
      </ErrorDiv>

      <div className={styles.amountButtonContainer}>
        {buttonAmounts.map((amt: number) => (
          <Button
            key={amt}
            type="button"
            theme="none"
            className={styles.button}
            onClick={(e) => {
              const currentValue = getValues('amount');
              // @ts-ignore
              const buttonValue = e.target.outerText.replace('+$', '');
              const totalValue = Number(currentValue) + Number(buttonValue);
              setValue('amount', totalValue);
              setError('amount', false);
            }}
          >
            +{format.currency(amt)}
          </Button>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        {isAddingCard && setIsAddingCard && (
          <Button
            theme="transparentDark"
            className={styles.submitButton}
            onClick={() => setIsAddingCard(false)}
          >
            Cancel
          </Button>
        )}
        {isAddingAccount && setIsAddingAccount && (
          <Button
            theme="transparentDark"
            className={styles.submitButton}
            onClick={() => setIsAddingAccount(false)}
          >
            Cancel
          </Button>
        )}
        <Button type="submit" theme="secondary" className={styles.submitButton}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}
