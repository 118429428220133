import clsx from 'clsx';
import dayjs from 'dayjs';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import FixedOddsBadge from '../../../../components/Badge/FixedOddsBadge';
import Countdown from '../../../../components/Countdown/Countdown';
import HorizontalOverflow from '../../../../components/HorizontalOverflow/HorizontalOverflow';
import raceStatuses from '../../../../constants/raceStatuses';
import useFormat from '../../../../hooks/useFormat';
import { Meeting, Race } from '../../../../hooks/useRace/types';
import styles from './RacingGrid.module.css';

type Props = {
  meetings: Meeting[];
};

export default function RacingGrid({ meetings = [] }: Props) {
  const maxRaces = meetings.reduce(
    (max, meeting) => (meeting.races.length > max ? meeting.races.length : max),
    0
  );

  if (meetings.length === 0) {
    return (
      <span className={styles.noMeetingsAvailable}>No meetings available.</span>
    );
  }

  return (
    <div className={styles.container}>
      <HorizontalOverflow>
        <div className={styles.desktop}>
          <div
            className={styles.grid}
            style={{ gridTemplateColumns: `repeat(${maxRaces + 1}, 1fr)` }}
          >
            <div className={styles.header} />
            {Array.from(Array(maxRaces), (_, x) => x).map((x) => (
              <div key={x} className={styles.header}>
                {x + 1}
              </div>
            ))}
            {meetings.map((meeting) => (
              <Fragment key={meeting.id}>
                <Link
                  className={clsx(styles.race, styles.track)}
                  to={`/racing/meeting/${meeting.id}`}
                >
                  <span className={styles.name}>{meeting.track.name}</span>
                  <span className={styles.country}>
                    {meeting.track.country}
                  </span>
                </Link>
                {meeting.races.map((race) => (
                  <Link
                    key={race.id}
                    className={clsx(styles.race, {
                      [styles.open]: race.status === raceStatuses.OPEN,
                    })}
                    to={`/racing/race/${race.id}/win`}
                  >
                    {race.status === raceStatuses.FINAL ? (
                      <span>
                        {race.results?.[race.results.length - 1]?.positions
                          .map((pos) => pos.tabNo)
                          .join(',')}
                      </span>
                    ) : (
                      <Countdown startTime={race.startTime} />
                    )}
                    <div className={styles.badges}>
                      <RaceBadges race={race} />
                    </div>
                  </Link>
                ))}
                {Array.from(
                  Array(maxRaces - meeting.races.length),
                  (_, x) => x
                ).map((x) => (
                  <div key={x} className={styles.race} />
                ))}
              </Fragment>
            ))}
          </div>
        </div>
      </HorizontalOverflow>
      <div className={styles.mobile}>
        <div className={styles.list}>
          {meetings.map((meeting) => (
            <MobileMeeting key={meeting.id} meeting={meeting} />
          ))}
        </div>
      </div>
    </div>
  );
}

function MobileMeeting({ meeting }: { meeting: Meeting }) {
  const [expanded, setExpanded] = useState(false);

  const format = useFormat();

  const nextRace =
    meeting.races.filter((race) => race.status === raceStatuses.OPEN)?.[0] ||
    meeting.races[meeting.races.length - 1];

  return (
    <>
      <div
        className={styles.meeting}
        onClick={() => setExpanded((prev) => !prev)}
        role="button"
        tabIndex={-1}
      >
        <div className={styles.track}>
          <span className={styles.name}>{meeting.track.name}</span>
          &nbsp;
          <span className={styles.country}>{meeting.track.country}</span>
        </div>
        <div className={styles.secondary}>
          <div className={styles.badges}>
            <RaceBadges race={nextRace} />
          </div>
          <div className={styles.lozenge}>
            <span className={styles.raceNumber}>R{nextRace.number}</span>
            {nextRace.status === raceStatuses.OPEN ? (
              <Countdown startTime={nextRace.startTime} />
            ) : (
              <span>Resulted</span>
            )}
          </div>
        </div>
      </div>
      {expanded
        ? meeting.races.map((race) => (
            <Link
              key={race.id}
              className={styles.race}
              to={`/racing/race/${race.id}`}
            >
              <div className={styles.circle}>
                <span>R{race.number}</span>
              </div>
              <div className={styles.column}>
                <div className={styles.row}>
                  <span className={styles.trackName}>{meeting.track.name}</span>
                  <div className={styles.secondary}>
                    {race.status === raceStatuses.FINAL ? (
                      <span className={styles.results}>Resulted</span>
                    ) : (
                      <>
                        <RaceBadges race={race} />
                        &nbsp;
                        <Countdown startTime={race.startTime} />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.row}>
                  <span className={styles.prizeMoney}>
                    Win Pool: {format.currency(race.prizeMoney)}
                  </span>
                  <span className={styles.time}>
                    {dayjs(race.startTime).format('h:mma')}
                  </span>
                </div>
              </div>
            </Link>
          ))
        : null}
    </>
  );
}

function RaceBadges({ race }: { race: Race }) {
  return (
    <>
      {race.status === raceStatuses.OPEN && race.fixedAvailable ? (
        <FixedOddsBadge />
      ) : null}
    </>
  );
}
