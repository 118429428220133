import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import useRemoteConfig from '../../hooks/useRemoteConfig/useRemoteConfig';
import Icon from '../Icon/Icon';
import styles from './HeroCarousel.module.css';

type CarouselItem = {
  backgroundImage: string;
  title?: string;
  description?: string;
  callToActions?: {
    label: string;
    url: string;
    theme?: string;
  }[];
};

export default function HeroCarousel({
  items = [],
}: {
  items: CarouselItem[];
}) {
  const { heroCarouselEnabled } = useRemoteConfig();
  const autoplayRef = useRef(0);
  const [selected, setSelected] = useState(0);
  const [autoplay, setAutoplay] = useState(true);

  const goToNext = useCallback(() => {
    setSelected((prev) => (prev < items.length - 1 ? prev + 1 : 0));
  }, [items]);

  function goToPrevious() {
    setSelected((prev) => (prev > 0 ? prev - 1 : items.length - 1));
  }

  useEffect(() => {
    if (heroCarouselEnabled) {
      window.clearTimeout(autoplayRef.current);

      if (autoplay) {
        autoplayRef.current = window.setTimeout(goToNext, 7000);
      }

      return () => {
        clearTimeout(autoplayRef.current);
      };
    }

    return () => {};
  }, [autoplay, selected, goToNext, heroCarouselEnabled]);

  function goToSelected(i: number) {
    setSelected(i);
    setAutoplay(false);
  }

  if (!heroCarouselEnabled) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.carousel}
        style={{
          width: `${items.length * 100}%`,
          transform: `translateX(-${(selected / items.length) * 100}%)`,
        }}
      >
        {items.map((carouselItem) => (
          <div key={carouselItem.title} className={styles.carouselItem}>
            <div
              className={styles.backgroundImage}
              style={{
                backgroundImage: `url(${carouselItem.backgroundImage})`,
              }}
            />
            <div className={styles.tint} />
            <div className={styles.carouselContent}>
              {carouselItem.title ? (
                <span className={styles.title}>{carouselItem.title}</span>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.quickContainer}>
        <button
          type="button"
          className={clsx(styles.arrowButton, styles.leftButton)}
          onClick={goToPrevious}
        >
          <Icon name="caretLeft" />
        </button>
        {items.map((carouselItem, i) => (
          <button
            key={carouselItem.title}
            type="button"
            aria-label={`${i} carousel item`}
            className={clsx(styles.quickButton, {
              [styles.selected]: selected === i,
            })}
            onClick={() => goToSelected(i)}
          />
        ))}
        <button
          type="button"
          className={clsx(styles.arrowButton, styles.rightButton)}
          onClick={goToNext}
        >
          <Icon name="caretRight" />
        </button>
      </div>
    </div>
  );
}
