import clsx from 'clsx';
import React from 'react';
import OddsButton from '../../../../../components/OddsButton/OddsButton';
import { BetType } from '../../../../../constants/betTypes';
import { PriceType } from '../../../../../constants/priceTypes';
import useAppState from '../../../../../hooks/useAppState/useAppState';
import useBetslip from '../../../../../hooks/useBetslip/useBetslip';
import useSingleBetslip from '../../../../../hooks/useBetslip/useSingleBetslip';
import { Prices } from '../../../../../hooks/usePrices/types';
import { RaceDetailedCompetitor } from '../../../../../hooks/useRace/types';
import { UseRaceData } from '../../../../../hooks/useRace/useRace';
import {
  getBetType,
  getPrice,
  hasSingleBet,
  isExoticBetType,
  isMultiExoticBetType,
} from '../../../../../utils/betslip/common';
import ExoticColumnBuilder from './ExoticColumnBuilder';
import MultiColumnBuilder from './MultiColumnBuilder';
import RunnerDetails from './RunnerDetails';
import styles from './RunnerSelection.module.css';

type Props = {
  race: UseRaceData;
  competitor: RaceDetailedCompetitor;
  betTypes: BetType[];
  prices?: Prices;
};

export default function RunnerSelection({
  race,
  competitor,
  betTypes,
  prices,
}: Props) {
  const {
    state: { betslip },
  } = useBetslip();
  const { addOrRemoveBet } = useSingleBetslip();
  const { settings } = useAppState();

  const bet = {
    race,
    meeting: race.meeting,
    competitor,
  };
  function OddsButtonContainer(
    betType:
      | BetType.WIN
      | BetType.PLACE
      | BetType.SHOW
      | BetType.WIN_FIXED_ODDS
      | BetType.PLACE_FIXED_ODDS
      | BetType.SHOW_FIXED_ODDS,
    priceType: PriceType,
    isWinPlaceShow?: boolean
  ) {
    const price = getPrice(settings.selectedPriceType, priceType, prices);

    return (
      <div
        className={clsx(styles.wrapper, {
          [styles.desktopOnly]: isWinPlaceShow,
        })}
      >
        <OddsButton
          price={price}
          onClick={() =>
            addOrRemoveBet({
              traditional: true,
              betType: getBetType(settings.selectedPriceType, betType),
              ...bet,
            })
          }
          selected={hasSingleBet(betslip, { betType, ...bet })}
          disabled={competitor.scratched}
        />
      </div>
    );
  }

  const isWinPlaceShow =
    betTypes.includes(BetType.WIN) &&
    betTypes.includes(BetType.PLACE) &&
    betTypes.includes(BetType.SHOW) &&
    betTypes.includes(BetType.WIN_FIXED_ODDS) &&
    betTypes.includes(BetType.PLACE_FIXED_ODDS) &&
    betTypes.includes(BetType.SHOW_FIXED_ODDS);

  return (
    <>
      <RunnerDetails race={race} competitor={competitor} />

      {betTypes.includes(BetType.WIN) &&
        OddsButtonContainer(BetType.WIN, PriceType.WIN)}
      {betTypes.includes(BetType.PLACE) &&
        OddsButtonContainer(BetType.PLACE, PriceType.PLACE, isWinPlaceShow)}
      {betTypes.includes(BetType.SHOW) &&
        OddsButtonContainer(BetType.SHOW, PriceType.SHOW, isWinPlaceShow)}
      {isExoticBetType(betTypes[0]) ? (
        <ExoticColumnBuilder betType={betTypes[0]} competitor={competitor} />
      ) : null}
      {isMultiExoticBetType(betTypes[0]) ? (
        <MultiColumnBuilder
          betType={betTypes[0]}
          competitor={competitor}
          race={race}
        />
      ) : null}
    </>
  );
}
