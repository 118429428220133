import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import SignupHeaderSuccess from './SignupHeaderSuccess';
import styles from './SignupModal.module.css';

export default function SignupSuccess() {
  return (
    <>
      <SignupHeaderSuccess />
      <Button type="submit" theme="secondary" className={styles.button}>
        Add Funds
      </Button>
      <div className={clsx(styles.secondary, styles.header)}>
        or{' '}
        <Link to="/" className="link">
          Skip this step
        </Link>
      </div>
    </>
  );
}
