import range from 'lodash/range';
import { useContext, useEffect } from 'react';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import { MultiBetType } from '../../../../../constants/betTypes';
import { PriceType } from '../../../../../constants/priceTypes';
import useBetslip from '../../../../../hooks/useBetslip/useBetslip';
import useMultiBetslip from '../../../../../hooks/useBetslip/useMultiBetslip';
import useFormat from '../../../../../hooks/useFormat';
import useRace from '../../../../../hooks/useRace/useRace';
import RaceContext from '../../RaceContext';
import MultiSelectionHeader from '../SelectionHeader/MultiSelectionHeader';
import styles from './MultiExoticSelectionColumn.module.css';

export default function MultiExoticSelectionColumn({
  raceId,
}: {
  raceId: string;
}) {
  const { betType, multiRunnerLength, updateMultiRunnerLength, runnerHeight } =
    useContext(RaceContext);
  const { race, loading } = useRace(raceId);
  const {
    state: { multiSelections },
  } = useBetslip();
  const { updateMultiSelection } = useMultiBetslip();
  const format = useFormat();

  useEffect(() => {
    if (race) {
      updateMultiRunnerLength(race?.competitors.length);
    }
  }, [race, updateMultiRunnerLength]);

  if (loading || !race) {
    return null;
  }

  const multiBet = multiSelections[betType as string as MultiBetType];
  const raceIndex = multiBet.races.findIndex((rId) => rId === raceId);
  const selections = multiBet.selections[raceIndex];

  return (
    <div className={styles.container}>
      <MultiSelectionHeader
        column={raceIndex}
        selections={race.competitors.filter((c) => !c.scratched)}
      />

      <div className={styles.wrapper}>
        {race.competitors.map((currentCompetitor, index) => (
          <div
            key={currentCompetitor.tabNo}
            className={styles.competitor}
            style={{
              height: runnerHeight[index],
            }}
          >
            <Checkbox
              disabled={currentCompetitor.scratched}
              checked={selections.some(
                (sel) => sel.tabNo === currentCompetitor.tabNo
              )}
              onChange={() =>
                updateMultiSelection({
                  raceId: race.id,
                  competitor: currentCompetitor,
                  betType,
                })
              }
            />
            <span className={styles.odds}>
              {format.odds(
                currentCompetitor.prices.find(
                  (price) => price.type === PriceType.WIN
                )?.price
              )}
            </span>
          </div>
        ))}
        {range(multiRunnerLength - race.competitors.length).map(
          (value, index) => (
            <div key={value}>
              <div
                className={styles.empty}
                style={{
                  height: runnerHeight[race.competitors.length + index],
                }}
              />
              <div className={styles.separator} />
            </div>
          )
        )}
      </div>
    </div>
  );
}
