import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Card from '../../../components/Card/Card';
import CardLabel from '../../../components/Card/CardLabel';
import Container from '../../../components/Container/Container';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import InfoBox from '../../../components/InfoBox/InfoBox';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import BankDeposit from './BankDeposit';
import CreditCardDeposit from './CreditCardDeposit';
import styles from './Deposit.module.css';
import DepositMenu from './DepositMenu';
import { DepositViews } from './DepositTypes';

export default function Deposit() {
  const route = useMemo(
    () => [
      {
        label: 'Account',
        url: '/account',
      },
      {
        label: 'Deposit',
        url: '/account/deposit',
      },
    ],
    []
  );

  const payNearMeInfoData = [
    { title: 'Deposit Limit', value: '$1 - $500' },
    { title: 'Fees', value: '3.5%' },
    { title: 'Availability', value: 'On Clearing' },
  ];

  useBreadcrumbs(route);
  // Temporarily hardcoding value until we can check if user has existing registered card
  const hasExistingCard = false;
  const hasExistingAccount = false;

  const [view, setView] = useState<DepositViews>('creditCard');

  return (
    <HomeContainer HeaderComponent={<h1>Deposit</h1>}>
      <Helmet title="Deposit">
        <meta name="description" content="Deposit" />
      </Helmet>
      <Container>
        <div className={styles.depositContainer}>
          <DepositMenu view={view} setView={setView} />
          <div className={styles.contentSection}>
            {view === 'creditCard' && (
              <>
                <CardLabel
                  label={hasExistingCard ? 'DEPOSIT' : 'ADD NEW CARD & DEPOSIT'}
                />
                <CreditCardDeposit hasExistingCard={hasExistingCard} />
              </>
            )}

            {view === 'bank' && (
              <>
                <CardLabel label="ADD NEW ACCOUNT" />
                <BankDeposit hasExistingAccount={hasExistingAccount} />
              </>
            )}

            {view === 'payNearMe' && (
              <>
                <CardLabel label="ADD NEW ACCOUNT" />
                <Card className={styles.infoBoxContainer}>
                  <InfoBox data={payNearMeInfoData} />
                </Card>
                <Card className={styles.cardPadding}>PayNearMe iframe</Card>
              </>
            )}
          </div>
        </div>
      </Container>
    </HomeContainer>
  );
}
