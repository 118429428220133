import clsx from 'clsx';
import React, { InputHTMLAttributes } from 'react';
import Input from '../../../components/Form/Input/Input';
import useFormat from '../../../hooks/useFormat';
import styles from './DepositCurrencyInput.module.css';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  register: any;
};

export default function DepositCurrencyInput({
  type = 'text',
  className = '',
  register,
  ...rest
}: Props) {
  const format = useFormat();

  return (
    <div className={styles.container}>
      <div className={styles.inputPrefix}>
        <span className={styles.currencyCode}>
          {format.currency(0).split('0')}
        </span>
        <Input
          type={type}
          className={clsx(styles.input, className)}
          id="Deposit Amount"
          {...register('amount', { required: 'Required' })}
          {...rest}
        />
      </div>
    </div>
  );
}
