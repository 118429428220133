import QueryString from 'qs';
import { useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions/FormActions';
import Input from '../../../../components/Form/Input/Input';
import InputRow from '../../../../components/Form/InputRow/InputRow';
import Modal from '../../../../components/Modal/Modal';
import ModalCard from '../../../../components/Modal/ModalCard';
import styles from './PersonalSettings.module.css';

type Props = {
  onDismiss: () => void;
};

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ChangePasswordModal({ onDismiss }: Props) {
  const history = useHistory();
  const location = useLocation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormValues>();
  const passwordRef = useRef('');

  passwordRef.current = watch('newPassword');

  const changePassword: SubmitHandler<FormValues> = async () => {
    toast.success('Password updated');
    history.push({
      search: QueryString.stringify({
        ...QueryString.parse(location.search, {
          ignoreQueryPrefix: true,
        }),
        action: undefined,
      }),
    });
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalCard
        title="Change Password"
        description="Your password must be minimum of 6 characters and must contain 1 uppercase letter, 1 lowercase letter and 1 number."
      >
        <form onSubmit={handleSubmit(changePassword)} className={styles.form}>
          <InputRow
            label="Current Password"
            errorText={errors.currentPassword?.message}
          >
            <Input
              id="Current Password"
              type="password"
              autoComplete="current-password"
              autoFocus
              {...register('currentPassword', { required: 'Required' })}
            />
          </InputRow>
          <InputRow
            label="New Password"
            errorText={errors.newPassword?.message}
          >
            <Input
              id="New Password"
              type="password"
              autoComplete="new-password"
              {...register('newPassword', { required: 'Required' })}
            />
          </InputRow>
          <InputRow
            label="Confirm New Password"
            errorText={errors.confirmPassword?.message}
          >
            <Input
              id="Confirm New Password"
              type="password"
              autoComplete="confirm-password"
              {...register('confirmPassword', {
                required: 'Required',
                validate: (value) =>
                  value === passwordRef.current || 'The passwords do not match',
              })}
            />
          </InputRow>
          <FormActions>
            <Button type="submit" disabled={isSubmitting} theme="secondary">
              Update
            </Button>
          </FormActions>
          <FormActions>
            <Button onClick={onDismiss}>Cancel</Button>
          </FormActions>
        </form>
      </ModalCard>
    </Modal>
  );
}
