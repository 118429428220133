import QueryString from 'qs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions/FormActions';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import ModalContainer from './ModalContainer';
import styles from './ModalContainer.module.css';

type Props = {
  onDismiss: () => void;
};

type FormValues = {
  email: string;
  password: string;
};

export default function ForgotPasswordModal({ onDismiss }: Props) {
  const history = useHistory();
  const location = useLocation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const sendResetLink: SubmitHandler<FormValues> = async () => {
    toast.success('You should receive an email with your reset link.');
    onDismiss();
  };

  return (
    <ModalContainer onDismiss={onDismiss}>
      <form onSubmit={handleSubmit(sendResetLink)} className={styles.form}>
        <div className={styles.center}>
          <h2>Forgot Password</h2>
          <p className={styles.note}>
            Please enter your email associated with your account and we will
            email you a code to reset your password.
          </p>
        </div>
        <InputRow label="Email Address" errorText={errors.email?.message}>
          <Input
            id="Email Address"
            autoComplete="email"
            autoFocus
            {...register('email', { required: 'Required' })}
          />
        </InputRow>
        <FormActions>
          <Button type="submit" disabled={isSubmitting} theme="secondary">
            Send Reset Link
          </Button>
        </FormActions>
        <FormActions>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              history.push({
                search: QueryString.stringify({
                  ...QueryString.parse(location.search, {
                    ignoreQueryPrefix: true,
                  }),
                  action: 'login',
                }),
              });
            }}
          >
            Back to login
          </Button>
        </FormActions>
        <p className={styles.secondary}>
          Need help? Contact us on
          <br />
          <a href="tel:8004682260" className="link">
            (800) 468-2260
          </a>{' '}
          or{' '}
          <a href="mailto:customercare@mywinners.com" className="link">
            CustomerCare@mywinners.com
          </a>
        </p>
      </form>
    </ModalContainer>
  );
}
