import { createContext } from 'react';

export type RemoteConfig = {
  fixedOddsEnabled?: boolean;
  racingEnabled?: boolean;
  sportsEnabled?: boolean;
  heroCarouselEnabled?: boolean;
  quickLinksEnabled?: boolean;
  oddsSwitcherEnabled?: boolean;
  liveVisionEnabled?: boolean;
  oddsboardEnabled?: boolean;
};

const RemoteConfigContext = createContext<RemoteConfig | undefined>(undefined);

export default RemoteConfigContext;
