import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Card from '../../../components/Card/Card';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import InfoBox from '../../../components/InfoBox/InfoBox';
import DepositAmountInput from '../Deposit/DepositAmountInput';
import styles from './CheckWithdraw.module.css';
import WithdrawContext, { CheckWithdrawForm } from './WithdrawContext';

const infoBoxData = [
  { title: 'Transaction Limit', value: '$1 - $500' },
  {
    title: 'Fees',
    value: 'None',
  },
  {
    title: 'Availability',
    value: '5 - 8 business days',
  },
];

export default function CheckWithdraw() {
  const { data } = useContext(WithdrawContext);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    setError,
    reset,
  } = useForm<CheckWithdrawForm>({
    defaultValues: data,
  });

  const submitCheckWithdrawal: SubmitHandler<CheckWithdrawForm> = (
    formValues
  ) => {
    // console.log('Check Withdrawal', formValues);
    reset();
    toast.success(
      `Withdrawal Request made. After we verify your request - the check should be delivered in 5-8 business days.`
    );
  };

  const tempPostalAddress = '801 Sugar Drive, Buffalo, NY 14215';

  return (
    <Card className={styles.container}>
      <InfoBox data={infoBoxData} />
      <form
        onSubmit={handleSubmit(submitCheckWithdrawal)}
        className={styles.form}
      >
        <InputRow
          label="Your Postal Address"
          helpText="To update your address, call customer service on TODO"
          errorText={errors.address?.message}
        >
          <Input
            disabled
            defaultValue={tempPostalAddress}
            id="Your Postal Address"
            autoComplete="address"
            className={styles.input}
            {...register('address')}
          />
        </InputRow>
        <InputRow
          label="Password (for security purposes)"
          errorText={errors.password?.message}
        >
          <Input
            type="password"
            id="Password (for security purposes)"
            autoComplete="password"
            className={styles.input}
            {...register('password', { required: 'Required' })}
          />
        </InputRow>
        <div>
          <DepositAmountInput
            isWithdrawal
            buttonLabel="Confirm Check Withdrawal"
            depositType="bank"
            getValues={getValues}
            setError={setError}
            register={register}
            errors={errors}
            setValue={setValue}
          />
        </div>
      </form>
    </Card>
  );
}
