import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CarryOverBadge from '../../../../components/Badge/CarryoverBadge';
import FixedOddsBadge from '../../../../components/Badge/FixedOddsBadge';
import MandatoryPayoutBadge from '../../../../components/Badge/MandatoryPayoutBadge';
import CountryFilters from '../../../../components/CountryFilters/CountryFilters';
import Icon from '../../../../components/Icon/Icon';
import RacingFilters from '../../../../components/RacingFilters/RacingFilters';
import meetingTypes, { MeetingType } from '../../../../constants/meetingTypes';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs/useBreadcrumbs';
import useCountryFilter from '../../../../hooks/useCountryFilter';
import RacingGrid from './RacingGrid';
import styles from './RacingToday.module.css';

export default function RacingToday() {
  const route = useMemo(
    () => [
      {
        label: 'Racing',
        url: '/racing',
      },
      {
        label: 'Todays Racing',
        url: '/racing/today',
      },
    ],
    []
  );
  useBreadcrumbs(route);
  const [typeFilters, setTypeFilter] = useState<MeetingType[]>([]);
  const countryFilteredMeetings = useCountryFilter();

  const raceTypeAll = typeFilters.length === 0;
  const raceTypeThoroughbred =
    raceTypeAll || typeFilters.includes(meetingTypes.THOROUGHBRED);
  const raceTypeGreyhound =
    raceTypeAll || typeFilters.includes(meetingTypes.GREYHOUND);
  const raceTypeHarness =
    raceTypeAll || typeFilters.includes(meetingTypes.HARNESS);

  function racingTitle(raceType: MeetingType) {
    const raceTypeTitle = {
      [meetingTypes.THOROUGHBRED]: 'HORSE RACING',
      [meetingTypes.GREYHOUND]: 'GREYHOUND RACING',
      [meetingTypes.HARNESS]: 'HARNESS RACING',
    };
    const meetings = countryFilteredMeetings.filter(
      (meeting) => meeting.type === raceType
    );

    return (
      <section className={styles.section}>
        <div className={styles.sectionHeader}>
          <span className={styles.title}>
            <Icon name={raceType} /> {raceTypeTitle[raceType]}
          </span>
          <div className={styles.legend}>
            <span className={styles.tooltip}>
              <FixedOddsBadge helper />
            </span>
            <span className={styles.tooltip}>
              <CarryOverBadge helper />
            </span>
            <span className={styles.tooltip}>
              <MandatoryPayoutBadge helper />
            </span>
          </div>
        </div>
        <RacingGrid meetings={meetings} />
      </section>
    );
  }
  return (
    <>
      <Helmet title="Todays">
        <meta name="description" content="Todays Racing" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <RacingFilters filters={typeFilters} setFilters={setTypeFilter} />
            <CountryFilters />
          </div>
        </div>

        {raceTypeThoroughbred && racingTitle(meetingTypes.THOROUGHBRED)}
        {raceTypeGreyhound && racingTitle(meetingTypes.GREYHOUND)}
        {raceTypeHarness && racingTitle(meetingTypes.HARNESS)}
      </div>
    </>
  );
}
