import { AppEvent, BetslipEventType } from '../../constants/appEvents';
import { BetType } from '../../constants/betTypes';
import { Competitor } from '../../hooks/useRace/types';
import { ExoticBet, MultiRaceBet, SingleBet } from './types';

export type AddOrRemoveBetslipDetail = {
  type: BetslipEventType.ADD_OR_REMOVE;
  traditional?: boolean;
  betType?: BetType;
} & SingleBet;
export type AddBetslipExoticDetail = {
  type: BetslipEventType.ADD_EXOTIC;
  betType?: BetType;
} & ExoticBet;

export type AddBetslipMultiExoticDetail = {
  type: BetslipEventType.ADD_MULTI_EXOTIC;
  betType?: BetType;
} & MultiRaceBet;

export type RemoveBetslipDetail = {
  type: BetslipEventType.REMOVE;
  index: number;
  betType?: BetType | '';
};
export type RemoveTraditionalBetslipDetail = {
  type: BetslipEventType.REMOVE_TRADITIONAL;
  raceId: string;
  betType: BetType | string;
};

export type UpdateTraditionalSingleBetslipDetail = {
  type: BetslipEventType.UPDATE_TRADITIONAL_SINGLE_STAKE;
  raceId: string;
  betType: BetType;
  stake: number;
};
export type UpdateAllStakeBetslipDetail = {
  type:
    | BetslipEventType.UPDATE_ALL_SINGLES_STAKE
    | BetslipEventType.UPDATE_ALL_EXOTICS_STAKE
    | BetslipEventType.UPDATE_ALL_MULTIS_STAKE;
  stake: number;
  betType?: BetType | '';
};

export type ClearBetslipDetail = {
  type: BetslipEventType.CLEAR;
  betType?: BetType | '';
};

export type ResetExoticSelections = {
  type: BetslipEventType.RESET_EXOTIC_SELECTIONS;
  betType?: BetType;
};

export type SetExoticBoxed = {
  type: BetslipEventType.SET_EXOTIC_BOXED;
  betType: BetType;
};

export type UpdateExoticSelection = {
  type: BetslipEventType.UPDATE_EXOTIC_SELECTION;
  index: number;
  competitor: Competitor;
  betType: BetType;
};

export type UpdateExoticSelectionAll = {
  type: BetslipEventType.UPDATE_EXOTIC_SELECTION_ALL;
  index: number;
  selections: Competitor[];
  betType: BetType;
};

export type ResetMultiSelections = {
  type: BetslipEventType.RESET_MULTI_SELECTIONS;
  betType?: BetType;
};

export type UpdateMultiRaces = {
  type: BetslipEventType.UPDATE_MULTI_RACES;
  races: string[];
  betType: BetType;
};

export type UpdateMultiSelection = {
  type: BetslipEventType.UPDATE_MULTI_SELECTION;
  raceId: string;
  competitor: Competitor;
  betType: BetType;
};

export type UpdateMultiSelectionAll = {
  type: BetslipEventType.UPDATE_MULTI_SELECTION_ALL;
  index: number;
  selections: Competitor[];
  betType: BetType;
};

export type ToggleBetslip = {
  type: BetslipEventType.TOGGLE_BETSLIP;
};

export type EventDispatcherDetail =
  | AddOrRemoveBetslipDetail
  | AddBetslipExoticDetail
  | AddBetslipMultiExoticDetail
  | RemoveBetslipDetail
  | RemoveTraditionalBetslipDetail
  | UpdateTraditionalSingleBetslipDetail
  | UpdateAllStakeBetslipDetail
  | ClearBetslipDetail
  | ResetExoticSelections
  | SetExoticBoxed
  | UpdateExoticSelection
  | UpdateExoticSelectionAll
  | ResetMultiSelections
  | UpdateMultiRaces
  | UpdateMultiSelection
  | UpdateMultiSelectionAll
  | ToggleBetslip;

export default function eventDispatcher(detail: EventDispatcherDetail) {
  window.dispatchEvent(
    new CustomEvent<EventDispatcherDetail>(AppEvent.BETSLIP, {
      bubbles: true,
      detail,
    })
  );
}
