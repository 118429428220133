import { useCallback, useContext } from 'react';
import { BetslipEventType } from '../../constants/appEvents';
import { RemoveTraditionalBetslipDetail } from '../../utils/betslip/eventDispatcher';
import BetslipContext from './BetslipContext';

export default function useBetslip() {
  const { state, dispatch } = useContext(BetslipContext);
  const toggleBetslip = useCallback(
    () => dispatch({ type: BetslipEventType.TOGGLE_BETSLIP }),
    [dispatch]
  );
  const clearBetslip = useCallback(
    () =>
      dispatch({
        type: BetslipEventType.CLEAR,
      }),
    [dispatch]
  );

  const removeBetByBetTypeRace = useCallback(
    (detail: Omit<RemoveTraditionalBetslipDetail, 'type'>) => {
      dispatch({ type: BetslipEventType.REMOVE_TRADITIONAL, ...detail });
    },
    [dispatch]
  );

  return {
    state,
    dispatch,
    toggleBetslip,
    clearBetslip,
    removeBetByBetTypeRace,
  };
}
