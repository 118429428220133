import { useContext, useEffect, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { CollapsableHeight } from '../../../../components/Collapsable/Collapsable';
import Icon from '../../../../components/Icon/Icon';
import { ExoticBetType, MultiBetType } from '../../../../constants/betTypes';
import useBetslip from '../../../../hooks/useBetslip/useBetslip';
import useExoticBetslip from '../../../../hooks/useBetslip/useExoticBetslip';
import useMultiBetslip from '../../../../hooks/useBetslip/useMultiBetslip';
import useFormat from '../../../../hooks/useFormat';
import { Meeting, Race } from '../../../../hooks/useRace/types';
import {
  getExoticCombinations,
  getMultiExoticCombinations,
} from '../../../../utils/betslip/common';
import FloatingNavigation from '../FloatingNavigation/FloatingNavigation';
import RaceContext from '../RaceContext';
import styles from './ExoticBuilder.module.css';

export default function ExoticBuilder({
  race,
  meeting,
}: {
  race: Race;
  meeting: Meeting;
}) {
  const raceContext = useContext(RaceContext);
  const [isSlidUpSelection, setIsSlidUpSelection] = useState(false);
  const format = useFormat();
  const { isExoticBetType, addExotic, resetExoticSelections } =
    useExoticBetslip();
  const { isMultiBetType, resetMultiSelections, addMulti } = useMultiBetslip();

  const {
    state: { multiSelections, exoticSelections },
  } = useBetslip();

  const isMulti = isMultiBetType(raceContext.betType);
  const isExotic = isExoticBetType(raceContext.betType);
  const isAvailable = isMulti || isExotic;

  const multiBet =
    multiSelections[raceContext.betType as string as MultiBetType];

  useEffect(() => {
    let isEmpty = false;

    if (isExotic) {
      isEmpty =
        exoticSelections?.[
          raceContext.betType as string as ExoticBetType
        ]?.flat().length === 0;
    }

    if (isMulti) {
      isEmpty =
        multiSelections?.[
          raceContext.betType as string as MultiBetType
        ]?.selections.flat().length === 0;
    }

    if (!isEmpty) {
      setIsSlidUpSelection(true);
    } else {
      setIsSlidUpSelection(false);
    }
  }, [
    exoticSelections,
    multiSelections,
    isMulti,
    isExotic,
    raceContext.betType,
  ]);

  if (!isAvailable) return null;

  const handleSendToBetslip = () => {
    if (isMulti) handleSendMultiToBetslip();
    if (isExotic) handleSendExoticToBetslip();
  };

  const handleSendExoticToBetslip = () => {
    const exoticBet =
      exoticSelections[raceContext.betType as string as ExoticBetType];
    const selections = [...exoticBet];
    addExotic({
      betType: raceContext.betType,
      race,
      meeting,
      selections,
    });
  };

  const handleSendMultiToBetslip = () => {
    const selections = [...multiBet.selections];

    if (raceContext) {
      addMulti({
        betType: raceContext.betType,
        race,
        races: multiBet.races,
        meeting,
        selections,
      });
    }
  };

  const handleClearSelections = () => {
    if (!isAvailable) return null;

    if (isExotic) {
      resetExoticSelections({});
    }

    if (isMulti) {
      resetMultiSelections({});
    }

    return null;
  };

  function SelectionsBuilder() {
    const selectedBet =
      exoticSelections[raceContext.betType as string as ExoticBetType];
    if (!selectedBet) return null;
    return (
      <div className={styles.selections}>
        {selectedBet.map((selection, index) => (
          <div key={format.ordinal(index + 1)}>
            {format.ordinal(index + 1)}:{' '}
            {selection.map((c) => c.tabNo).toString()}
          </div>
        ))}
      </div>
    );
  }

  function MultiSelectionsBuilder() {
    const selectedBet =
      multiSelections[raceContext.betType as string as MultiBetType];
    if (!selectedBet) return null;
    return (
      <div className={styles.selections}>
        {selectedBet?.races.map((r, index) => (
          <div key={r}>
            {format.ordinal(index + 1)}:{' '}
            {selectedBet.selections?.[index]?.map((c) => c.tabNo).toString()}
          </div>
        ))}
      </div>
    );
  }

  function getSelections() {
    const selections = exoticSelections[
      raceContext.betType as string as ExoticBetType
    ].map((sel) => sel.map((c) => c.tabNo));
    return selections;
  }
  let combinations = 0;

  if (isExotic) {
    combinations = getExoticCombinations(
      exoticSelections[raceContext.betType as string as ExoticBetType]?.length,
      getSelections()
    );
  }

  if (isMulti) {
    combinations = getMultiExoticCombinations(
      multiSelections[raceContext.betType as string as MultiBetType]?.selections
    );
  }

  const selectionsValid = combinations > 0;

  return (
    <>
      {isMulti && <FloatingNavigation />}
      <div className={styles.container}>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setIsSlidUpSelection((prev) => !prev)}
          className={styles.slideupMenu}
        >
          <strong className={styles.selectionText}>Selections</strong>
          <span className={styles.combos}>Combos: {combinations}</span>
        </div>
        <CollapsableHeight open={isSlidUpSelection}>
          {isExotic && SelectionsBuilder()}
          {isMulti && MultiSelectionsBuilder()}
        </CollapsableHeight>

        {isMulti && (
          <div className={styles.multiActionContainer}>
            <div className={styles.clearButton}>
              <Button
                size="medium"
                theme="transparentLight"
                onClick={handleClearSelections}
              >
                Clear All Selections
              </Button>
            </div>

            <div className={styles.rightContainer}>
              <div className={styles.addButton}>
                <Button
                  size="medium"
                  onClick={handleSendToBetslip}
                  disabled={!selectionsValid}
                  className={styles.betslipButton}
                >
                  <Icon name="plus" className={styles.icon} size="small" />
                  Add To Betslip
                </Button>
              </div>
            </div>
          </div>
        )}
        {!isMulti && (
          <div className={styles.selectionActionContainer}>
            <div className={styles.clearButton}>
              <Button
                size="medium"
                onClick={handleClearSelections}
                className={styles.button}
              >
                Clear
              </Button>
            </div>
            <div className={styles.addButton}>
              <Button
                size="medium"
                onClick={handleSendToBetslip}
                disabled={!selectionsValid}
                className={styles.betslipButton}
              >
                <Icon name="plus" className={styles.icon} size="small" />
                Add To Betslip
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
