import dayjs, { Dayjs } from 'dayjs';
import { createContext, Dispatch, SetStateAction } from 'react';
import { OddsboardSelectionType } from '../../constants/oddsboardTypes';
import { Auth } from '../../types/auth';
import { Config } from '../../types/window';
import { NextToJump } from '../useMeetings/types';
import { Meeting } from '../useRace/types';

export type OddsDisplayType = 'DECIMAL' | 'AMERICAN' | 'FRACTIONAL';
export type PriceTypeSelection = 'fixed' | 'tote';
export interface AppSettings {
  oddsType: OddsDisplayType;
  showRunnerInfo: boolean;
  showShortForm: boolean;
  showOddsboard: boolean;
  shortFormTab: ShortFormTab;
  oddsboardSelected: OddsboardSelectionType;
  selectedPriceType: PriceTypeSelection;
  upcomingExpanded: boolean;
  showBalance: boolean;
}

export type ShortFormTab = 'runner' | 'jockey' | 'trainer';

export type AppState = {
  config?: Config;
  currentTime: Dayjs;
  meetings: Meeting[];
  nextToJump: NextToJump[];
  settings: AppSettings;
  updateSettings: Dispatch<SetStateAction<AppSettings>>;
  user?: Auth;
  setUser: Dispatch<SetStateAction<Auth | undefined>>;
  liveVision: {
    isGlobalVisionOpen: boolean;
    setIsGlobalVisionOpen: Dispatch<SetStateAction<boolean>>;
    visionUrl: string | undefined;
  };
  countryFilter: {
    countryFilters: string[];
    setCountryFilters: Dispatch<SetStateAction<string[]>>;
  };
};

// const INITIAL_STATE =

const AppStateContext = createContext<AppState>({
  currentTime: dayjs(),
  meetings: [],
  nextToJump: [],
  settings: {
    oddsType: 'DECIMAL',
    showRunnerInfo: false,
    showOddsboard: false,
    showShortForm: false,
    shortFormTab: 'runner',
    oddsboardSelected: OddsboardSelectionType.ALL_POOLS,
    selectedPriceType: 'tote',
    upcomingExpanded: false,
    showBalance: true,
  },
  updateSettings: () => {},
  setUser: () => {},
  liveVision: {
    isGlobalVisionOpen: false,
    setIsGlobalVisionOpen: () => {},
    visionUrl: undefined,
  },
  countryFilter: {
    countryFilters: [],
    setCountryFilters: () => {},
  },
});

export default AppStateContext;
