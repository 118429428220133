import React from 'react';
import {
  OddsboardSelectionType,
  OddsboardType,
} from '../../../../constants/oddsboardTypes';
import useAppState from '../../../../hooks/useAppState/useAppState';
import styles from './PoolsNavigation.module.css';

export default function PoolsNavigation() {
  const { settings, updateSettings } = useAppState();

  function handleChangeSelected(event: React.ChangeEvent<HTMLSelectElement>) {
    const key = event.target.value as keyof typeof OddsboardType;
    updateSettings((prevSettings) => ({
      ...prevSettings,
      oddsboardSelected:
        OddsboardSelectionType[key as keyof typeof OddsboardSelectionType],
    }));
  }

  return (
    <div className={styles.container}>
      <select
        onChange={handleChangeSelected}
        className={styles.select}
        value={settings.oddsboardSelected}
      >
        {Object.keys(OddsboardType).map((key) => (
          <option value={key} key={key}>
            {`Pool:  ${OddsboardType[key as keyof typeof OddsboardType]}`}
          </option>
        ))}
      </select>
    </div>
  );
}
