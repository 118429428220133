import { createContext } from 'react';

const BetslipContext = createContext<{
  showCalculator: boolean;
  setShowCalculator: (value: string) => void;
}>({
  showCalculator: false,
  setShowCalculator: () => {},
});

export default BetslipContext;
