import dayjs from 'dayjs';
import Button from '../../../../../components/Button/Button';
import { FormHistory } from '../../../../../hooks/useRunner/types';
import styles from './StartInfo.module.css';

type Props = {
  start: FormHistory;
  startBefore: FormHistory;
};

function formatTime(millisecond: number) {
  const time = (millisecond / 60000).toFixed(2).split('.');
  return `${time[0]}:${time[1]}`;
}

function daysBetweenDates(dateFrom: string, dateTo: string) {
  const date1 = dayjs(dateFrom);
  const date2 = dayjs(dateTo);
  const days = date1.diff(date2, 'day');
  return days;
}

export default function StartInfo({ start, startBefore }: Props) {
  return (
    <>
      <div className={styles.shortFormRunnerInfo}>
        <div className={styles.recentStartInfo}>
          <div className={styles.startInfo}>
            <span>
              <span className={styles.label}>Margin: </span>
              {`${start.margin} L`}
            </span>
            <span>
              <span className={styles.label}>Jockey: </span>
              {start.jockey}
            </span>
            <span>
              <span className={styles.label}>M/L: </span>
            </span>
          </div>
          <div className={styles.startInfo}>
            <span>
              <span className={styles.label}>Gate: </span>
              {start.barrier}
            </span>
            <span>
              <span className={styles.label}>Days: </span>
              {daysBetweenDates(start?.date, startBefore?.date)}
            </span>
            <span>
              <span className={styles.label}>Time: </span>
              {formatTime(start.elapsedTime)}
            </span>
          </div>
          <div className={styles.startInfo}>
            <span>
              <span className={styles.label}>Winner: </span>
              {start.placeGetters[0]?.name}
            </span>
            <span>
              <span className={styles.label}>2nd: </span>
              {start.placeGetters[1]?.name}
            </span>
            <span>
              <span className={styles.label}>3rd: </span>
              {start.placeGetters[2]?.name}
            </span>
          </div>
        </div>
        <div className={styles.recentStartWatchReplay}>
          <Button theme="secondary" leftIcon="watch">
            <span>&nbsp;Watch Replay</span>
          </Button>
        </div>
      </div>
    </>
  );
}
