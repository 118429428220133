import useAppState from '../../../hooks/useAppState/useAppState';
import styles from './BankDeposit.module.css';

export default function BankHelpText() {
  const { config } = useAppState();

  return (
    <img
      src={`${config?.contentUrl}/ach.png`}
      alt="Bank Account number positions"
      width="170px"
      className={styles.cardImage}
    />
  );
}
