import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Card from '../../../../components/Card/Card';
import CardLabel from '../../../../components/Card/CardLabel';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import styles from './CommunicationSettings.module.css';

export default function CommunicationSettings() {
  const [marketingEmails, setMarketingEmails] = useState(true);
  const [sportEmails, setSportEmails] = useState(true);
  const [markrtingLetters, setMarketingLetters] = useState(true);

  return (
    <>
      <Helmet title="Communication">
        <meta name="description" content="Communication Settings" />
      </Helmet>
      <CardLabel label="CONTACT PREFERENCES" />
      <div>
        <Card className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.description}>
              <span className={styles.heading}>
                Send Racing marketing emails
              </span>
              <span className={styles.value}>
                Marketing emails are sent weekly and keep you up to date on
              </span>
              <span className={styles.value}>promotions and big events.</span>
            </div>
            <ToggleSwitch
              value={marketingEmails}
              changeValue={() => setMarketingEmails((prev) => !prev)}
            />
          </div>
        </Card>
        <Card className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.description}>
              <span className={styles.heading}>
                Send Sport marketing emails
              </span>
              <span className={styles.value}>
                Marketing emails are sent weekly and keep you up to date on
              </span>
              <span className={styles.value}>promotions and big events.</span>
            </div>
            <ToggleSwitch
              value={sportEmails}
              changeValue={() => setSportEmails((prev) => !prev)}
            />
          </div>
        </Card>
        <Card className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.description}>
              <span className={styles.heading}>
                Send Sport marketing mail / letters
              </span>
              <span className={styles.value}>
                Marketing emails are sent weekly and keep you up to date on
              </span>
              <span className={styles.value}>promotions and big events.</span>
            </div>
            <ToggleSwitch
              value={markrtingLetters}
              changeValue={() => setMarketingLetters((prev) => !prev)}
            />
          </div>
        </Card>
      </div>
    </>
  );
}
