import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { ReactNode, useRef } from 'react';
import { useQuery } from 'react-query';
import useAppState from '../../hooks/useAppState/useAppState';
import useBetslip from '../../hooks/useBetslip/useBetslip';
import { useMeasure } from '../../hooks/useMeasure';
import useProxy from '../../hooks/useProxy';
import { Auth } from '../../types/auth';
import Betslip from '../Betslip/Betslip';
import GlobalVision from '../Racing/LiveVision/GlobalVision';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import styles from './Layout.module.css';
import Navigation from './Navigation/Navigation';

export default function Layout({ children }: { children: ReactNode }) {
  const {
    liveVision: { isGlobalVisionOpen },
    config,
    setUser,
  } = useAppState();
  const {
    state: { showBetslip },
  } = useBetslip();
  const axios = useProxy();
  const headerRef = useRef<HTMLDivElement>(null);
  const headerRect = useMeasure(headerRef);
  const navigationRef = useRef<HTMLDivElement>(null);
  const navigationRect = useMeasure(navigationRef);
  const betslipRef = useRef<HTMLDivElement>(null);
  const betslipRect = useMeasure(betslipRef);

  useQuery<Auth>(
    'Auth',
    async () => {
      const response = await axios.get<unknown, AxiosResponse<Auth>>(
        '/api/v1/falcon/auth'
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setUser(data);
      },
    }
  );

  return (
    <div className={styles.container}>
      {!config?.options?.disableNavigation ? (
        <>
          <div className={styles.navigationContainer} ref={navigationRef}>
            <Navigation />
          </div>
        </>
      ) : null}
      <aside style={{ width: navigationRect?.width }} role="presentation" />
      {!config?.options?.disableHeader ? (
        <header
          ref={headerRef}
          style={{ marginLeft: navigationRect?.width, position: 'fixed' }}
          className={styles.header}
        >
          <Header />
        </header>
      ) : null}
      <div
        className={styles.contentContainer}
        style={{ marginTop: headerRect?.height }}
      >
        <div className={styles.content}>{children}</div>
        <Footer
          imageList={[
            {
              image: '/android_store.svg',
              label: 'Android Store',
              url: '/',
            },
            {
              image: '/app_store.svg',
              label: 'App Store',
              url: '/',
            },
            {
              image: '/facebook.svg',
              label: 'Facebook',
              url: '/',
            },
            {
              image: '/instagram.svg',
              label: 'Instagram',
              url: '/',
            },
          ]}
          footerLists={[
            {
              title: 'ABOUT',
              children: [
                {
                  label: 'About BetMakers',
                  url: '/',
                },
                {
                  label: 'Terms & Conditions',
                  url: '/',
                },
                {
                  label: 'Disclaimer',
                  url: '/',
                },
              ],
            },
            {
              title: 'GETTING STARTED',
              children: [
                {
                  label: 'Open an account',
                  url: '/',
                },
                {
                  label: 'How to bet',
                  url: '/',
                },
                {
                  label: 'Basic bet types',
                  url: '/',
                },
              ],
            },
            {
              title: 'HELP',
              children: [
                {
                  label: 'Get help',
                  url: '/',
                },
                {
                  label: 'Problem gambling',
                  url: '/',
                },
              ],
            },
            {
              title: 'CONTACT',
              children: [
                {
                  label: 'Phone: 1-888-FUN2BET (1-888-386-2238)',
                  url: '/',
                },
              ],
            },
          ]}
          description="If you think you may have a gambling problem, help is available 24 hours a day - every day of the year. Call 1-800-522-4700"
        />
      </div>
      <div
        className={clsx(styles.betslipContainer, {
          [styles.open]: showBetslip,
          [styles.embedded]: config?.options?.embeddedBetslip,
        })}
        ref={betslipRef}
        style={{ marginTop: headerRect?.height }}
      >
        <Betslip />
      </div>
      <aside style={{ width: betslipRect?.width }} role="presentation" />
      {isGlobalVisionOpen && <GlobalVision />}
    </div>
  );
}
