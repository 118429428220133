import Button from '../../../components/Button/Button';
import { BetType } from '../../../constants/betTypes';
import useBetslip from '../../../hooks/useBetslip/useBetslip';
import useExoticBetslip from '../../../hooks/useBetslip/useExoticBetslip';
import { TraditionalSingleBet } from '../TraditionalBetslip/types';
import styles from './AdditionalWagers.module.css';

export default function AdditionalWagers({
  bet,
}: {
  bet: TraditionalSingleBet;
}) {
  const {
    state: { betslip },
  } = useBetslip();
  const { addExotic } = useExoticBetslip();
  const selections = [...bet.selections];

  const hasEnoughSelections = selections.length > 1;
  const showExacta =
    selections.length >= 2 &&
    !betslip.some(
      (b) => b.betType === BetType.EXACTA && b.race.id === bet.race.id
    );
  const showTrifecta =
    selections.length >= 3 &&
    !betslip.some(
      (b) => b.betType === BetType.TRIFECTA && b.race.id === bet.race.id
    );

  return hasEnoughSelections ? (
    <div className={styles.container}>
      {showExacta ? (
        <Button
          onClick={() => {
            addExotic({
              betType: BetType.EXACTA,
              race: bet.race,
              meeting: bet.meeting,
              selections: [[...selections], [...selections]],
            });
          }}
          className={styles.button}
          leftIcon="plus"
        >
          <span>Boxed Exacta</span>
        </Button>
      ) : null}
      {showTrifecta ? (
        <Button
          onClick={() => {
            addExotic({
              betType: BetType.TRIFECTA,
              race: bet.race,
              meeting: bet.meeting,
              selections: [[...selections], [...selections], [...selections]],
            });
          }}
          className={styles.button}
          leftIcon="plus"
        >
          <span>Boxed Trifecta</span>
        </Button>
      ) : null}
    </div>
  ) : null;
}
