import { createContext, Dispatch, SetStateAction } from 'react';

export type BankWithdrawForm = {
  routingNum: number;
  accountNum: number;
  amount: number;
  password: string;
};

export type CheckWithdrawForm = {
  address: string;
  amount: number;
  password: string;
};

export type BankWithdrawFormContext = {
  data?: BankWithdrawForm;
  setData?: Dispatch<SetStateAction<BankWithdrawForm>>;
};

const WithdrawContext = createContext<BankWithdrawFormContext>({});

export default WithdrawContext;
