import clsx from 'clsx';
import HorizontalOverflow from '../../../../components/HorizontalOverflow/HorizontalOverflow';
import Silk from '../../../../components/Silk/Silk';
import { BetType } from '../../../../constants/betTypes';
import oddsboardTypes from '../../../../constants/oddsboardTypes';
import { PriceType } from '../../../../constants/priceTypes';
import useBetslip from '../../../../hooks/useBetslip/useBetslip';
import useSingleBetslip from '../../../../hooks/useBetslip/useSingleBetslip';
import { Pool } from '../../../../hooks/usePools/types';
import usePoolHistory from '../../../../hooks/usePools/usePoolHistory';
import useWPSPools from '../../../../hooks/usePools/useWPSPool';
import { Competitor } from '../../../../hooks/useRace/types';
import { UseRaceData } from '../../../../hooks/useRace/useRace';
import { hasSingleBet } from '../../../../utils/betslip/common';
import styles from './WPSBoard.module.css';

export default function WPSBoard({ race }: { race: UseRaceData }) {
  const pools = useWPSPools(race?.id);
  const {
    state: { betslip },
  } = useBetslip();
  const { WIN_PLACE_SHOW } = oddsboardTypes;
  const { addOrRemoveBet } = useSingleBetslip();

  if (!pools) return null;

  function getBet(competitor: Competitor) {
    return {
      race,
      meeting: race.meeting,
      competitor,
    };
  }

  function isSelected(
    competitor: Competitor,
    betType: BetType.WIN | BetType.PLACE | BetType.SHOW
  ) {
    return hasSingleBet(betslip, { betType, ...getBet(competitor) });
  }

  return (
    <>
      <div className={styles.header}>
        <div>{WIN_PLACE_SHOW}</div>
      </div>
      <HorizontalOverflow>
        <div className={styles.container}>
          <div>
            <div className={clsx(styles.row, styles.bg)}>
              <div className={styles.rowHeading}>Runners</div>
              {race.competitors?.map((c) => (
                <div className={styles.competitor} key={c.tabNo}>
                  <Silk competitor={c} race={race} meeting={race.meeting} />
                  <div className={styles.competitorName}>
                    {c.tabNo}. {c.name}
                  </div>
                </div>
              ))}
              <div className={styles.rowHeading}>Total</div>
            </div>
          </div>
          <div>
            <div className={styles.row}>
              <div className={styles.rowHeading}>Win Odds</div>
              {race.competitors?.map((c) => {
                const winPrice = c.prices?.find((p) => p.type === 'WIN')?.price;

                return winPrice ? (
                  <div
                    key={c.tabNo}
                    className={clsx(styles.oddsButtonContainer, styles.value)}
                  >
                    <div
                      className={clsx(
                        styles.oddsButton,
                        isSelected(c, BetType.WIN) ? styles.selected : null
                      )}
                      onClick={() =>
                        addOrRemoveBet({
                          traditional: true,
                          betType: BetType.WIN,
                          ...getBet(c),
                        })
                      }
                      role="button"
                      tabIndex={-1}
                    >
                      {winPrice}
                    </div>
                  </div>
                ) : (
                  <div className={styles.noOdds} key={c.tabNo}>
                    -
                  </div>
                );
              })}
              <div className={styles.rowHeading}>{pools.WIN?.poolSize}</div>
            </div>
          </div>
          <div>
            {Object.keys(pools)
              .filter((p) => p !== 'WIN')
              .map((type) => (
                <PSTypeSection
                  race={race}
                  type={type as PriceType}
                  poolSize={pools?.[type as PriceType]?.poolSize}
                  key={type}
                  getBet={getBet}
                  isSelected={isSelected}
                />
              ))}
          </div>
        </div>
      </HorizontalOverflow>
    </>
  );
}

function PSTypeSection({
  race,
  type,
  poolSize,
  getBet,
  isSelected,
}: {
  race: UseRaceData;
  type: PriceType;
  poolSize?: number;
  getBet: Function;
  isSelected: Function;
}) {
  const poolHistory = usePoolHistory(race.id, type);
  const { addOrRemoveBet } = useSingleBetslip();
  function mapRunners(priceHistory: Pool[]) {
    const prices = priceHistory.map((ph) => ph.prices);

    const competitors = race.competitors.map((c) => ({
      tabNo: c.tabNo,
      prices: prices
        .map((p) => p.find((price) => price?.tabNo === c.tabNo)?.price || 0)
        .filter((p) => p !== 0)
        .sort((a, b) => (a > b ? -1 : 1)),
    }));
    return competitors;
  }

  const competitors = mapRunners(poolHistory);

  return (
    <div className={styles.row}>
      <div className={styles.rowHeading}>
        <span>{type}</span> <span>Hi/Lo</span>
      </div>
      {competitors.map((c) =>
        c.prices?.length > 0 ? (
          <div
            key={c.tabNo}
            className={clsx(
              styles.value,
              isSelected(c, BetType[type]) ? styles.selected : null
            )}
            onClick={() =>
              addOrRemoveBet({
                traditional: true,
                betType: BetType[type],
                ...getBet(c),
              })
            }
            role="button"
            tabIndex={-1}
          >
            {Math.min(...c?.prices)}/ {Math.max(...c?.prices)}
          </div>
        ) : (
          <div key={c.tabNo} className={styles.value}>
            -
          </div>
        )
      )}
      <div className={styles.rowHeading}>{poolSize}</div>
    </div>
  );
}
