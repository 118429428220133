import clsx from 'clsx';
import { useState } from 'react';
import { MemoryRouter, Route, Switch, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal/Modal';
import { ReactComponent as Logo } from './interbets-logo.svg';
import ProgressIndicator from './ProgressIndicator';
import SignupContext, { SignupForm } from './SignupContext';
import SignupFooter from './SignupFooter';
import styles from './SignupModal.module.css';
import SignupStep1 from './SignupStep1';
import SignupStep2 from './SignupStep2';
import SignupStep3 from './SignupStep3';
import SignupSuccess from './SignupSuccess';

type Props = {
  onDismiss: () => void;
};

export default function SignupModal({ onDismiss }: Props) {
  const history = useHistory();
  const [data, setData] = useState<SignupForm>({});

  function onComplete() {
    toast.success('Your account has been created. Please verify your email.');
    history.push('/');
  }

  const isStepThreeComplete =
    data.firstName && data.lastName && data.dateOfBirth;

  return (
    <SignupContext.Provider value={{ data, setData, onComplete }}>
      <Modal
        onDismiss={onDismiss}
        fullscreen
        closeButtonClassName={styles.closeButton}
      >
        <div
          className={clsx(styles.container, {
            [styles.success]: isStepThreeComplete,
          })}
        >
          <div className={styles.formContainer}>
            <MemoryRouter>
              <div className={styles.header}>
                <Logo />
              </div>
              <Switch>
                <Route exact path="/" component={SignupStep1} />
                <Route exact path="/step2" component={SignupStep2} />
                <Route exact path="/step3" component={SignupStep3} />
                <Route exact path="/success" component={SignupSuccess} />
              </Switch>
              {!isStepThreeComplete && <ProgressIndicator />}
            </MemoryRouter>
          </div>
          {!isStepThreeComplete && <SignupFooter />}
        </div>
      </Modal>
    </SignupContext.Provider>
  );
}
