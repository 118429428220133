import { SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import Input from '../../../components/Form/Input/Input';
import InputRow from '../../../components/Form/InputRow/InputRow';
import Modal from '../../../components/Modal/Modal';
import CardSecurityHelpText from './CardSecurityHelpText';
import { CCForm } from './DepositContext';
import styles from './DepositModal.module.css';

export default function EditCardModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CCForm>();

  const submit: SubmitHandler<CCForm> = (formValues) => {
    // console.log(formValues);
    onDismiss();
    toast.success(`Card updated`);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <Card className={styles.container}>
        <h1 className={styles.heading}>Edit Card</h1>
        <form onSubmit={handleSubmit(submit)}>
          <div className={styles.inputContainer}>
            <div className={styles.halfWidth}>
              <InputRow
                label="Card Number"
                errorText={errors.cardNumber?.message}
              >
                <Input
                  id="Card Number"
                  autoComplete="cc-number"
                  autoFocus
                  className={styles.input}
                  {...register('cardNumber', { required: 'Required' })}
                />
              </InputRow>
            </div>
            <InputRow label="Expiry" errorText={errors.expiry?.message}>
              <InputMask
                mask="99/99"
                {...register('expiry', { required: 'Required' })}
                className={styles.test}
              >
                {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    id="Expiry"
                    placeholder="MM/YY"
                    autoComplete="cc-exp"
                    className={styles.input}
                    {...register('expiry', { required: 'Required' })}
                  />
                )}
              </InputMask>
            </InputRow>
            <InputRow
              label="Security No"
              errorText={errors.cvv?.message}
              helpText={CardSecurityHelpText}
            >
              <Input
                id="Security No"
                autoComplete="cc-csc"
                className={styles.input}
                {...register('cvv', { required: 'Required' })}
              />
            </InputRow>
          </div>
          <div className={styles.buttonContainer}>
            <Button theme="secondary" type="submit" className={styles.input}>
              Save
            </Button>
            <Button
              theme="transparentDark"
              onClick={onDismiss}
              className={styles.input}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Card>
    </Modal>
  );
}
