import dayjs from 'dayjs';
import useAppState from './useAppState/useAppState';

const WARN_SECONDS = 900;
const URGENT_SECONDS = 300;

export default function useCountdown(compareTime: string) {
  const appState = useAppState();
  const countdown = dayjs.duration(
    dayjs(compareTime).diff(appState.currentTime)
  );
  const warning = countdown.asSeconds() < WARN_SECONDS;
  const urgent = countdown.asSeconds() < URGENT_SECONDS;
  const label = [
    {
      unit: 'd',
      value: countdown.days(),
    },
    {
      unit: 'h',
      value: countdown.hours(),
    },
    {
      unit: 'm',
      value: countdown.minutes(),
    },
    urgent
      ? {
          unit: 's',
          value: countdown.seconds(),
        }
      : {
          value: 0,
        },
  ]
    .reduce<string[]>((aggregated, time) => {
      if (time.value === 0) {
        return aggregated;
      }

      return [...aggregated, `${time.value}${time.unit}`];
    }, [])
    .join(' ');

  return {
    warning: !urgent && warning,
    urgent,
    posted: countdown.asSeconds() < 0,
    label,
  };
}
