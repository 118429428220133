import Icon from '../../../components/Icon/Icon';
import useAppState from '../../../hooks/useAppState/useAppState';
import useWindowSize, { Size } from '../../../hooks/useWindowSize';
import styles from './LiveVision.module.css';

type LiveVisionProps = {
  setIsLiveVisionOpen: (isOpen: boolean) => void;
};

export default function LiveVision({ setIsLiveVisionOpen }: LiveVisionProps) {
  const {
    liveVision: { visionUrl },
  } = useAppState();
  const size: Size = useWindowSize();

  const desiredHeight = size.height ? size.height / 3 : 240;
  const desiredWidth = (desiredHeight / 3) * 4;

  const scaleFactor = size.width && size.width < desiredWidth ? 0.75 : 1;

  const scaledWidth = desiredWidth * scaleFactor;

  const streamWidth = scaledWidth > 300 ? scaledWidth : 300;
  const streamHeight = (streamWidth / 4) * 3;

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <button
          type="button"
          className={styles.closeIconContainer}
          onClick={() => setIsLiveVisionOpen(false)}
          title="Close"
        >
          <Icon name="cross" className={styles.icon} />
        </button>
      </div>
      <iframe
        title="LiveVision"
        loading="lazy"
        name="videoFrame"
        id="videoFrame"
        frameBorder="0"
        height={streamHeight}
        width={streamWidth}
        src={visionUrl}
        scrolling="no"
        referrerPolicy="origin"
        allowFullScreen
      />
    </div>
  );
}
