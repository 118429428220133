import Icon from '../Icon/Icon';
import Badge from './Badge';
import styles from './Badge.module.css';

export default function CarryOverBadge({ helper }: { helper?: boolean }) {
  return (
    <Badge
      Component={
        <div className={styles.helper}>
          <Icon name="carryover" size="small" />
          {helper && <span className={styles.helperText}>Carryover</span>}
        </div>
      }
    >
      {!helper && <span className={styles.badgeTitle}>Carryover</span>}
      <span className={styles.details}>
        A carryover is when no wins occur at the end of a race on a particular
        wager type - for example, a Pick 4, Pick 5 or Pick 6. All wagers in the
        carryover pool or &apos;pool&apos; are then carried over to the next
        designated race.
      </span>
    </Badge>
  );
}
