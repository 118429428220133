import clsx from 'clsx';
import { useContext } from 'react';
import Icon from '../../../../../components/Icon/Icon';
import betTypes, {
  BetType,
  MultiBetType,
} from '../../../../../constants/betTypes';
import useBetslip from '../../../../../hooks/useBetslip/useBetslip';
import { isExoticBetType } from '../../../../../utils/betslip/common';
import { SortByKey, SortOrder } from '../../../../../utils/race/types';
import RaceContext from '../../RaceContext';
import ExoticSelectionHeader from './ExoticSelectionHeader';
import MultiSelectionHeader from './MultiSelectionHeader';
import styles from './SelectionHeader.module.css';

export default function SelectionHeader({ tabs }: { tabs: BetType[] }) {
  const raceContext = useContext(RaceContext);
  const {
    state: { multiSelections },
  } = useBetslip();

  if (!tabs || !raceContext) return null;

  const handleChangeSort = (sortValue: SortByKey) => {
    if (sortValue === raceContext.sortBy) {
      raceContext.setSortDesc(!raceContext.sortDesc);
    } else {
      raceContext.setSortBy(sortValue);
      raceContext.setSortDesc(true);
    }
  };

  const renderSortIcon = (sortValue: string) => {
    if (sortValue !== raceContext.sortBy) {
      return 'sort';
    }

    return raceContext.sortDesc ? SortOrder.DESCENDING : SortOrder.ASCENDING;
  };

  const hideLabelOnMobile =
    tabs.includes(betTypes.WIN) &&
    tabs.includes(betTypes.PLACE) &&
    tabs.includes(betTypes.SHOW);

  const exoticsMulti =
    tabs.includes(betTypes.DOUBLE) ||
    tabs.includes(betTypes.PICK3) ||
    tabs.includes(betTypes.PICK6);

  return (
    <>
      <span
        className={clsx(styles.header, styles.actions, {
          [styles.selected]: raceContext.sortBy === SortByKey.NAME,
        })}
        onClick={() => handleChangeSort(SortByKey.NAME)}
        role="button"
        tabIndex={-1}
      >
        RUNNER&nbsp;
        <Icon name={renderSortIcon(SortByKey.NAME)} size="small" />
      </span>

      {tabs.includes(betTypes.WIN) && (
        <span
          className={clsx(styles.header, styles.actions, {
            [styles.selected]: raceContext.sortBy === SortByKey.WIN,
          })}
          onClick={() => handleChangeSort(SortByKey.WIN)}
          role="button"
          tabIndex={-1}
        >
          WIN&nbsp;
          <Icon name={renderSortIcon(SortByKey.WIN)} size="small" />
        </span>
      )}

      {tabs.includes(betTypes.PLACE) && (
        <span
          className={clsx(styles.header, styles.actions, {
            [styles.selected]: raceContext.sortBy === SortByKey.PLACE,
            [styles.hideLabelOnMobile]: hideLabelOnMobile,
          })}
          onClick={() => handleChangeSort(SortByKey.PLACE)}
          role="button"
          tabIndex={-1}
        >
          PLACE&nbsp;
          <Icon name={renderSortIcon(SortByKey.PLACE)} size="small" />
        </span>
      )}

      {tabs.includes(betTypes.SHOW) && (
        <span
          className={clsx(styles.header, {
            [styles.selected]: raceContext.sortBy === SortByKey.SHOW,
            [styles.hideLabelOnMobile]: hideLabelOnMobile,
          })}
          onClick={() => handleChangeSort(SortByKey.SHOW)}
          role="button"
          tabIndex={-1}
        >
          SHOW&nbsp;
          <Icon name={renderSortIcon(SortByKey.SHOW)} size="small" />
        </span>
      )}

      {isExoticBetType(tabs[0]) ? <ExoticSelectionHeader /> : null}
      {exoticsMulti && raceContext.race ? (
        <MultiSelectionHeader
          column={multiSelections[
            raceContext.betType as string as MultiBetType
          ].races.indexOf(raceContext.race.id)}
          selections={raceContext.sortedCompetitors.filter((c) => !c.scratched)}
        />
      ) : null}
    </>
  );
}
