import dayjs from 'dayjs';
import betTypes from '../../constants/betTypes';
import priceTypes from '../../constants/priceTypes';
import { NextToJump } from '../../hooks/useMeetings/types';
import { RaceDetailedCompetitor } from '../../hooks/useRace/types';
import { SortByKey } from './types';

export const sortFn = (
  a: RaceDetailedCompetitor,
  b: RaceDetailedCompetitor,
  sortBy: string,
  sortDesc: boolean
) => {
  const truthy = sortDesc ? -1 : 1;
  const falsy = sortDesc ? 1 : -1;

  switch (sortBy) {
    case SortByKey.NAME:
      return a.tabNo < b.tabNo ? truthy : falsy;
    case SortByKey.WIN:
      return (a.prices.find((price) => price.type === priceTypes.WIN)?.price ||
        0) <
        (b.prices.find((price) => price.type === priceTypes.WIN)?.price || 0)
        ? truthy
        : falsy;
    case SortByKey.PLACE:
    case SortByKey.SHOW:
      return (a.prices.find((price) => price.type === priceTypes.PLACE)
        ?.price || 0) <
        (b.prices.find((price) => price.type === priceTypes.PLACE)?.price || 0)
        ? truthy
        : falsy;
    default:
      return 1;
  }
};

export const sortNTJRaces = (
  a: NextToJump,
  b: NextToJump,
  sortBy: string,
  sortDesc: boolean
) => {
  const truthy = sortDesc ? -1 : 1;
  const falsy = sortDesc ? 1 : -1;

  switch (sortBy) {
    case SortByKey.TRACK:
      return a.meeting?.track?.name < b.meeting?.track?.name ? truthy : falsy;
    case SortByKey.MTP:
      return dayjs(a?.startTime).isBefore(b?.startTime || '') ? truthy : falsy;
    default:
      return 1;
  }
};

export const clearExoticSelections: { [key: string]: number[] } = {
  '1': [],
  '2': [],
  '3': [],
  '4': [],
  '5': [],
  '6': [],
};

export const betTypeObjFromString = (betType: string) => {
  switch (betType) {
    case betTypes.WIN:
      return betTypes.WIN;
    case betTypes.PLACE:
      return betTypes.PLACE;
    case betTypes.SHOW:
      return betTypes.SHOW;
    case betTypes.EXACTA:
      return betTypes.EXACTA;
    case betTypes.TRIFECTA:
      return betTypes.TRIFECTA;
    case betTypes.QUINELLA:
      return betTypes.QUINELLA;
    case betTypes.SUPERFECTA:
      return betTypes.SUPERFECTA;
    case betTypes.DOUBLE:
      return betTypes.DOUBLE;
    case betTypes.PICK3:
      return betTypes.PICK3;
    case betTypes.PICK6:
      return betTypes.PICK6;
    case betTypes.DAILY_DOUBLE:
      return betTypes.DAILY_DOUBLE;
    case betTypes.EARLY_QUADRELLA:
      return betTypes.EARLY_QUADRELLA;
    case betTypes.EXTRA_DOUBLE:
      return betTypes.EXTRA_DOUBLE;
    case betTypes.FIRST_FOUR:
      return betTypes.FIRST_FOUR;
    case betTypes.OPENING_FLUC:
      return betTypes.OPENING_FLUC;
    case betTypes.PLACE_FIXED_ODDS:
      return betTypes.PLACE_FIXED_ODDS;
    case betTypes.QUADRELLA:
      return betTypes.QUADRELLA;
    case betTypes.QUINELLA_FIXED_ODDS:
      return betTypes.QUINELLA_FIXED_ODDS;
    case betTypes.QUINELLA_PLACE:
      return betTypes.QUINELLA_PLACE;
    case betTypes.RUNNING_DOUBLE:
      return betTypes.RUNNING_DOUBLE;
    case betTypes.SIX_UP:
      return betTypes.SIX_UP;
    case betTypes.STARTING_PRICE:
      return betTypes.STARTING_PRICE;
    case betTypes.TOP_FLUC:
      return betTypes.TOP_FLUC;
    case betTypes.TREBLE:
      return betTypes.TREBLE;
    case betTypes.TRIO:
      return betTypes.TRIO;
    case betTypes.WIN_FIXED_ODDS:
      return betTypes.WIN_FIXED_ODDS;

    default:
      return betTypes.EXACTA;
  }
};
