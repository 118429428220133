import clsx from 'clsx';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { PriceType } from '../../../../constants/priceTypes';
import usePools from '../../../../hooks/usePools/usePools';
import { Race } from '../../../../hooks/useRace/types';
import titleCase from '../../../../utils/titleCase';
import styles from './AllPools.module.css';

export default function AllPools({
  race,
  setUpdatedAt,
}: {
  race: Race;
  setUpdatedAt: Dispatch<SetStateAction<Date | undefined>>;
}) {
  const { pools } = usePools(race.id);

  useEffect(() => {
    if (pools) setUpdatedAt(pools.WIN?.updated);
  }, [pools, setUpdatedAt]);

  if (!pools) return null;

  const sortedPools = Object.keys(pools)
    .map((key) => pools[key as PriceType])
    .sort((a, b) =>
      a?.poolSize && b?.poolSize && a?.poolSize > b?.poolSize ? -1 : 1
    );

  return (
    <div className={styles.allPools}>
      <div className={clsx(styles.title, styles.row)}>
        <span>Pool</span>
        <span>Totals</span>
      </div>
      {sortedPools.map((pool) => (
        <div className={clsx(styles.pool, styles.row)} key={pool?.type}>
          <span>{titleCase(pool?.type || '')}</span>
          <span>${pool?.poolSize || null}</span>
        </div>
      ))}
    </div>
  );
}
