import clsx from 'clsx';
import OddsButton from '../../../../../components/OddsButton/OddsButton';
import { BetType } from '../../../../../constants/betTypes';
import useBetslip from '../../../../../hooks/useBetslip/useBetslip';
import useExoticBetslip from '../../../../../hooks/useBetslip/useExoticBetslip';
import useFormat from '../../../../../hooks/useFormat';
import {
  Competitor,
  RaceDetailedCompetitor,
} from '../../../../../hooks/useRace/types';
import { ExoticBetType } from '../../../../../utils/betslip/types';
import styles from './RunnerSelection.module.css';

export default function ExoticColumnBuilder({
  betType,
  competitor,
}: {
  betType: BetType;
  competitor: RaceDetailedCompetitor;
}) {
  const {
    state: { exoticSelections, boxed },
  } = useBetslip();
  const { updateExoticSelection } = useExoticBetslip();
  const format = useFormat();
  const selectedBet = exoticSelections[betType as string as ExoticBetType];

  const handleAddSelection = (columnKey: number) => {
    updateExoticSelection({
      betType,
      competitor,
      index: columnKey,
    });
  };

  function isSelected(columnKey: number) {
    return !!selectedBet[columnKey].find(
      (c: Competitor) => c.tabNo === competitor.tabNo
    );
  }

  function getLabel() {
    return selectedBet
      .map((_, i) =>
        i === selectedBet.length - 1
          ? `or ${format.ordinal(i + 1)}`
          : ` ${format.ordinal(i + 1)}`
      )
      .toString()
      .replace(',or', ' or');
  }

  return (
    <div className={clsx(styles.wrapper, styles.gap)}>
      {boxed ? (
        <OddsButton
          onClick={() => handleAddSelection(1)}
          disabled={competitor.scratched}
          label={getLabel()}
          selected={isSelected(0)}
        />
      ) : (
        selectedBet.map((s, i) => (
          <OddsButton
            key={`${format.ordinal(i + 1)}`}
            label={format.ordinal(i + 1)}
            onClick={() => handleAddSelection(i)}
            selected={isSelected(i)}
            disabled={competitor.scratched}
          />
        ))
      )}
    </div>
  );
}
