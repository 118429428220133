import { useState, useEffect } from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { Dayjs } from 'dayjs';
import { Meeting, Race, Result } from '../useRace/types';

export default function useMeetings(date: Dayjs, to: Dayjs) {
  const [meetings, setMeetings] = useState<Meeting[] | undefined>();

  const { data } = useQuery<{ meetingsDated: Meeting[] }>(
    gql`
      query meetingsDated($date: Time!, $to: Time) {
        meetingsDated(date: $date, to: $to) {
          id
          type
          hasVision
          externalIDs(sources: "gbs") {
            id
          }
          track {
            id
            name
            country
          }
          races {
            id
            number
            startTime
            status
            fixedAvailable
            prizeMoney
            results(sources: "gbs") {
              positions {
                tabNo
                position
              }
            }
            competitors {
              tabNo
              barrier
              name
              jockey
              trainer
              scratched
              weightTotal
            }
          }
        }
      }
    `,
    {
      variables: {
        date,
        to,
      },
    }
  );

  useEffect(() => {
    setMeetings(data?.meetingsDated);
  }, [data]);

  useSubscription<{ raceUpdated: Race }>(
    gql`
      subscription raceUpdated {
        raceUpdated {
          id
          startTime
          status
        }
      }
    `,
    {
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data && meetings) {
          const raceUpdate = subscriptionData.data.raceUpdated;
          setMeetings((prevMeetings) =>
            prevMeetings?.map((meeting) => {
              const races = meeting.races.map((race) => {
                if (race.id === raceUpdate.id) {
                  return {
                    ...race,
                    startTime: raceUpdate.startTime,
                    status: raceUpdate.status,
                  };
                }
                return race;
              });
              return { ...meeting, races };
            })
          );
        }
      },
    }
  );

  useSubscription<{ results: Result }>(
    gql`
      subscription results {
        results(sources: "gbs") {
          raceId
          positions {
            tabNo
            position
          }
        }
      }
    `,
    {
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data && meetings) {
          const raceResults = subscriptionData.data?.results;
          setMeetings((prevMeetings) =>
            prevMeetings?.map((meeting) => {
              const races = meeting.races.map((race) => {
                if (race.id === raceResults.raceId) {
                  return {
                    ...race,
                    results: [...race.results, raceResults],
                  };
                }
                return race;
              });
              return { ...meeting, races };
            })
          );
        }
      },
    }
  );

  return meetings;
}
