import clsx from 'clsx';
import { useState } from 'react';
import Button from '../../components/Button/Button';
import useBetslip from '../../hooks/useBetslip/useBetslip';
import useBettingClient from '../../hooks/useBetslip/useBettingClient';
import styles from './Betslip.module.css';
import BetslipContext from './BetslipContext';
import TraditionalBetslip from './TraditionalBetslip/TraditionalBetslip';

export default function Betslip() {
  const { toggleBetslip, clearBetslip } = useBetslip();
  const [showCalculator, setShowCalculator] = useState<string[]>([]);
  const {
    state: { betslip },
  } = useBetslip();
  const { placeBets } = useBettingClient();

  function handleSetShowCalculator(value: string) {
    setShowCalculator((previous) =>
      previous.includes(value)
        ? previous.filter((key) => key !== value)
        : [...previous, value]
    );
  }

  const stakedBets = betslip.filter((bet) => bet.stake);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Button
          theme="none"
          onClick={toggleBetslip}
          className={styles.chevron}
          leftIcon="caretLeft"
          iconClassName={styles.caret}
        />
        <h2>BET SLIP</h2>
      </div>
      <>
        <BetslipContext.Provider
          value={{
            showCalculator: showCalculator.length > 0,
            setShowCalculator: handleSetShowCalculator,
          }}
        >
          <div
            className={clsx(styles.betslip, {
              [styles.padding]: showCalculator.length > 0,
            })}
          >
            <TraditionalBetslip />
          </div>
          <div className={styles.footer}>
            <div className={styles.row}>
              <span className={styles.label}>Total</span>
              <span className={styles.value}>$0</span>
            </div>
            <div className={styles.actions}>
              <Button
                theme="transparentDark"
                size="medium"
                onClick={clearBetslip}
              >
                Clear Slip
              </Button>
              <Button
                size="medium"
                theme="primary"
                disabled={stakedBets.length === 0}
                onClick={() => placeBets(stakedBets)}
              >
                Place Bets
              </Button>
            </div>
          </div>
        </BetslipContext.Provider>
      </>
    </div>
  );
}
