import Axios from 'axios';
import { useMemo } from 'react';
import {
  getBetCombinations,
  isExoticBet,
  isMultiBet,
  isSingleBet,
} from '../../utils/betslip/common';
import { Bet } from '../../utils/betslip/types';
import useAppState from '../useAppState/useAppState';
import { Race } from '../useRace/types';

export default function useBettingClient() {
  const { settings } = useAppState();

  const axios = useMemo(
    () =>
      Axios.create({
        baseURL: process.env.REACT_APP_BETDIRECTOR_URL,
      }),
    []
  );

  async function placeBets(bets: Bet[]) {
    function getRaceMeta(bet: Bet) {
      return isMultiBet(bet)
        ? {}
        : {
            raceId: bet.race.id,
            raceMeta: {
              trackName: bet.meeting.track.name,
              raceStart: bet.race.startTime,
              raceNumber: bet.race.number,
              meetingType: bet.meeting.type,
            },
          };
    }

    function getBetLegs(bet: Bet) {
      if (isSingleBet(bet)) {
        return [
          {
            leg: 1,
            selections: [
              {
                selectionId: bet.competitor?.selectionID,
                selectionMeta: {
                  name: bet.competitor?.name,
                  runner: bet.competitor?.tabNo,
                },
              },
            ],
          },
        ];
      }
      if (isMultiBet(bet)) {
        return bet.selections?.map((selection, index) => {
          const multiRace = bet?.meeting?.races?.find(
            (race: Race) => bet?.races?.[index] === race.id
          );
          return {
            additionalData: {
              raceId: bet.races[index],
              raceMeta: {
                trackName: bet.meeting.track.name,
                raceStart: multiRace?.startTime,
                raceNumber: multiRace?.number,
                meetingType: bet.meeting.type,
              },
            },
            leg: index + 1,
            selections: selection.map((competitor) => ({
              selectionId: competitor.selectionID,
              selectionMeta: {
                name: competitor.name,
                runner: competitor.tabNo,
              },
            })),
          };
        });
      }
      if (isExoticBet(bet)) {
        return bet.selections?.map((selection, index) => ({
          leg: index + 1,
          selections: selection.map((competitor) => ({
            selectionId: competitor.selectionID,
            selectionMeta: {
              name: competitor.name,
              runner: competitor.tabNo,
            },
          })),
        }));
      }
      return [];
    }

    const mappedBets = bets.map((bet) => ({
      bet: {
        type: bet.betType,
        platform: settings.selectedPriceType,
        stake: bet.stake || 0 * 100,
        combinations: getBetCombinations(bet),
        ...getRaceMeta(bet),
        legs: getBetLegs(bet),
      },
    }));

    const response = await Promise.all(
      mappedBets.map((bet) => axios.post('/public/placeBetsV2', bet))
    );
    return response;
  }

  return {
    placeBets,
  };
}
