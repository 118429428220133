import QueryString from 'qs';
import { useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions/FormActions';
import Input from '../../../../components/Form/Input/Input';
import InputRow from '../../../../components/Form/InputRow/InputRow';
import Modal from '../../../../components/Modal/Modal';
import ModalCard from '../../../../components/Modal/ModalCard';
import styles from './PersonalSettings.module.css';

type Props = {
  onDismiss: () => void;
};

type FormValues = {
  newPin: string;
  confirmPin: string;
};

export default function ChangePinModal({ onDismiss }: Props) {
  const history = useHistory();
  const location = useLocation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormValues>();
  const pinRef = useRef('');

  pinRef.current = watch('newPin');

  const changePin: SubmitHandler<FormValues> = async () => {
    toast.success('Pin updated');
    history.push({
      search: QueryString.stringify({
        ...QueryString.parse(location.search, {
          ignoreQueryPrefix: true,
        }),
        action: undefined,
      }),
    });
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalCard title="Change Pin" description="Pin must be 4 numbers.">
        <form onSubmit={handleSubmit(changePin)} className={styles.form}>
          <InputRow label="New Pin" errorText={errors.newPin?.message}>
            <Input
              id="New Pin"
              type="password"
              autoComplete="off"
              autoFocus
              {...register('newPin', { required: 'Required' })}
            />
          </InputRow>
          <InputRow
            label="Confirm New Pin"
            errorText={errors.confirmPin?.message}
          >
            <Input
              id="Confirm New Pin"
              type="password"
              autoComplete="off"
              {...register('confirmPin', {
                required: 'Required',
                validate: (value) =>
                  value === pinRef.current || 'Pin numbers do not match',
              })}
            />
          </InputRow>
          <FormActions>
            <Button type="submit" disabled={isSubmitting} theme="secondary">
              Update
            </Button>
          </FormActions>
          <FormActions>
            <Button onClick={onDismiss}>Cancel</Button>
          </FormActions>
        </form>
      </ModalCard>
    </Modal>
  );
}
