import { Dispatch, SetStateAction } from 'react';
import Button from '../../../../components/Button/Button';
import {
  AllFilters,
  BetFilters,
  TransactionFilters,
  TransactionTypes,
} from '../../../../constants/betStatus';
import styles from './FilterButtons.module.css';

const allFilterValues = {
  ...BetFilters,
  ...TransactionFilters,
};

export default function FilterButtons({
  filters = [],
  setFilters,
}: {
  filters?: AllFilters[];
  setFilters: Dispatch<SetStateAction<AllFilters[]>>;
}) {
  function toggleFilters(filterType: AllFilters) {
    setFilters((prev) => {
      if (prev.includes(filterType)) {
        return prev.filter((type) => type !== filterType);
      }

      const newFilters = [...prev, filterType];
      return newFilters;
    });
  }

  return (
    <div>
      <Button
        theme="default"
        selected={filters.length === 0}
        className={styles.button}
        onClick={() => setFilters([])}
      >
        ALL
      </Button>
      {Object.values(allFilterValues)?.map((filter) => (
        <Button
          key={filter}
          theme="default"
          selected={filters.includes(filter)}
          className={styles.button}
          onClick={() => toggleFilters(filter)}
        >
          {TransactionTypes[filter]}
        </Button>
      ))}
    </div>
  );
}
