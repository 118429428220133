const titleCase = (str: string) => {
  function capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const titled = str.toLowerCase().split(' ').map(capitalize).join(' ');

  return titled;
};

export default titleCase;
