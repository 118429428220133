import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../components/Button/Button';
import CurrencyInput from '../../../components/Form/CurrencyInput/CurrencyInput';
import Icon from '../../../components/Icon/Icon';
import useSingleBetslip from '../../../hooks/useBetslip/useSingleBetslip';
import useClickOutside from '../../../hooks/useClickOutside';
import useFormat from '../../../hooks/useFormat';
import { betTypeObjFromString } from '../../../utils/race/common';
import BetslipContext from '../BetslipContext';
import QuickStakeButtons from './QuickStakeButtons/QuickStakeButtons';
import styles from './SetStakeInput.module.css';
import {
  TraditionalExoticBet,
  TraditionalMultiRaceBet,
  TraditionalSingleBet,
} from './types';

export default function SetStakeInput({
  bet,
}: {
  bet: TraditionalSingleBet | TraditionalExoticBet | TraditionalMultiRaceBet;
}) {
  const { race, stake = 0 } = bet;
  const { updateStakeByBetTypeRace } = useSingleBetslip();
  const betType = betTypeObjFromString(bet.betType);
  const format = useFormat();
  const buttons = [1, 2, 3, 10, 4, 5, 6, 20, 7, 8, 9, 50, 0];
  const currencyInputRef = useRef<HTMLInputElement>(null);
  const calculatorRef = useRef<HTMLDivElement>(null);
  const [isInFocus, setIsInFocus] = useState(false);
  const betslipContext = useContext(BetslipContext);

  function backspace() {
    const newValue = 0;
    updateStakeByBetTypeRace({
      raceId: race.id,
      betType,
      stake: newValue,
    });
  }

  const toggleInFocus = () => {
    setIsInFocus(true);
    betslipContext.setShowCalculator(`${bet.betType}-${bet.race.id}`);
  };
  const toggleOutFocus = () => {
    setIsInFocus(false);
    betslipContext.setShowCalculator(`${bet.betType}-${bet.race.id}`);
  };

  useClickOutside(calculatorRef, () => toggleOutFocus(), isInFocus);

  function addStake(amt: number) {
    const newValue = stake + amt;
    updateStakeByBetTypeRace({
      raceId: race.id,
      betType,
      stake: newValue,
    });
  }

  function addCalcStake(amt: string) {
    let newValue = 0;
    // if (amt === '.') newValue = `${prev}.`;
    if (amt === '10' || amt === '20' || amt === '50')
      newValue = stake + parseFloat(amt);
    else newValue = parseFloat(stake + amt);
    updateStakeByBetTypeRace({
      raceId: race.id,
      betType,
      stake: newValue,
    });
  }

  useEffect(() => {
    if (currencyInputRef.current && isInFocus) {
      currencyInputRef.current.focus();
    }
  }, [isInFocus]);

  return (
    <div>
      <div ref={currencyInputRef}>
        <CurrencyInput
          className={styles.stakeInput}
          value={stake}
          onClick={toggleInFocus}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            updateStakeByBetTypeRace({
              raceId: race.id,
              betType,
              stake: +event.target.value,
            })
          }
        />
      </div>
      <QuickStakeButtons onAddStake={addStake} />
      {isInFocus ? (
        <div className={styles.calcContainer} ref={calculatorRef}>
          <div className={styles.numpadHeader}>Betslip Total: $0.00</div>
          <div className={styles.numpadCalc}>
            {buttons.map((button) => (
              <Button
                key={button}
                theme="none"
                className={styles.buttonCalc}
                onClick={() => {
                  addCalcStake(button.toString());
                }}
              >
                {button > 9 ? <>+{format.currency(button)}</> : <>{button}</>}
              </Button>
            ))}
            <Button
              onClick={() => {
                addCalcStake('.');
              }}
              theme="none"
              className={styles.buttonCalc}
            >
              .
            </Button>
            <Button
              theme="none"
              onClick={backspace}
              className={styles.buttonCalc}
            >
              <Icon name="delete" />
            </Button>
            <Button
              theme="none"
              size="small"
              onClick={backspace}
              className={styles.buttonBet}
            >
              BET NOW
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
