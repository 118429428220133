import QueryString from 'qs';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions/FormActions';
import Input from '../../../../components/Form/Input/Input';
import InputRow from '../../../../components/Form/InputRow/InputRow';
import Modal from '../../../../components/Modal/Modal';
import ModalCard from '../../../../components/Modal/ModalCard';
import styles from './PersonalSettings.module.css';

type Props = {
  onDismiss: () => void;
};

type FormValues = {
  email: string;
};

export default function ChangeEmailModal({ onDismiss }: Props) {
  const history = useHistory();
  const location = useLocation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const changeEmail: SubmitHandler<FormValues> = async () => {
    toast.success('Email updated');
    history.push({
      search: QueryString.stringify({
        ...QueryString.parse(location.search, {
          ignoreQueryPrefix: true,
        }),
        action: undefined,
      }),
    });
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalCard
        title="Change Email Address"
        description="Please check email carefully."
      >
        <form onSubmit={handleSubmit(changeEmail)} className={styles.form}>
          <InputRow label="Email Address" errorText={errors.email?.message}>
            <Input
              id="Email Address"
              autoComplete="email"
              autoFocus
              {...register('email', { required: 'Required' })}
            />
          </InputRow>
          <FormActions>
            <Button type="submit" disabled={isSubmitting} theme="secondary">
              Update
            </Button>
          </FormActions>
          <FormActions>
            <Button onClick={onDismiss}>Cancel</Button>
          </FormActions>
        </form>
      </ModalCard>
    </Modal>
  );
}
