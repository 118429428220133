import { useCallback } from 'react';
import { BetslipEventType } from '../../constants/appEvents';
import { BetType } from '../../constants/betTypes';
import {
  AddBetslipExoticDetail,
  ResetExoticSelections,
  SetExoticBoxed,
  UpdateAllStakeBetslipDetail,
  UpdateExoticSelection,
  UpdateExoticSelectionAll,
} from '../../utils/betslip/eventDispatcher';
import { ExoticBet } from '../../utils/betslip/types';
import { Competitor } from '../useRace/types';
import useBetslip from './useBetslip';

export type ExoticSelections = {
  Exacta: [Competitor[], Competitor[]];
  Quinella: [Competitor[], Competitor[]];
  Trifecta: [Competitor[], Competitor[], Competitor[]];
  Superfecta:
    | [Competitor[], Competitor[], Competitor[], Competitor[]]
    | [[], [], [], []];
};

export const defaultExoticSelections: ExoticSelections = {
  Exacta: [[], []],
  Quinella: [[], []],
  Trifecta: [[], [], []],
  Superfecta: [[], [], [], []],
};

export const exoticBetTypes = [
  BetType.EXACTA,
  BetType.QUINELLA,
  BetType.TRIFECTA,
  BetType.SUPERFECTA,
  BetType.SUPERHI5,
];

export default function useExoticBetslip() {
  const { state, dispatch } = useBetslip();

  const isExoticBetType = useCallback(
    (betType: BetType) => exoticBetTypes.includes(betType),
    []
  );

  const getExoticBets = useCallback(
    () =>
      state.betslip.filter((bet) =>
        isExoticBetType(bet.betType)
      ) as ExoticBet[],
    [state.betslip, isExoticBetType]
  );

  const updateExoticSelection = useCallback(
    (detail: Omit<UpdateExoticSelection, 'type'>) => {
      dispatch({ type: BetslipEventType.UPDATE_EXOTIC_SELECTION, ...detail });
    },
    [dispatch]
  );

  const updateExoticSelectionAll = useCallback(
    (detail: Omit<UpdateExoticSelectionAll, 'type'>) => {
      dispatch({
        type: BetslipEventType.UPDATE_EXOTIC_SELECTION_ALL,
        ...detail,
      });
    },
    [dispatch]
  );

  const addExotic = useCallback(
    (detail: Omit<AddBetslipExoticDetail, 'type'>) => {
      dispatch({ type: BetslipEventType.ADD_EXOTIC, ...detail });
    },
    [dispatch]
  );

  const resetExoticSelections = useCallback(
    (detail: Omit<ResetExoticSelections, 'type'>) => {
      dispatch({ type: BetslipEventType.RESET_EXOTIC_SELECTIONS, ...detail });
    },
    [dispatch]
  );

  const toggleBoxed = useCallback(
    (detail: Omit<SetExoticBoxed, 'type'>) => {
      dispatch({ type: BetslipEventType.SET_EXOTIC_BOXED, ...detail });
    },
    [dispatch]
  );

  const updateStakeAll = useCallback(
    (detail: Omit<UpdateAllStakeBetslipDetail, 'type'>) => {
      dispatch({ type: BetslipEventType.UPDATE_ALL_EXOTICS_STAKE, ...detail });
    },
    [dispatch]
  );

  return {
    isExoticBetType,
    getExoticBets,
    updateExoticSelection,
    updateExoticSelectionAll,
    addExotic,
    resetExoticSelections,
    toggleBoxed,
    updateStakeAll,
  };
}
