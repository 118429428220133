import clsx from 'clsx';
import Button from '../../../../components/Button/Button';
import {
  OddsboardSelectionType,
  OddsboardType,
} from '../../../../constants/oddsboardTypes';
import useAppState from '../../../../hooks/useAppState/useAppState';
import styles from './OddsboardTabs.module.css';

export default function OddsboardTabs() {
  const { settings, updateSettings } = useAppState();

  const oddsboardViews = [
    {
      title: OddsboardType.WIN_PLACE_SHOW,
      value: OddsboardSelectionType.WIN_PLACE_SHOW,
      isDisabled: false,
    },
    {
      title: OddsboardType.EXACTA,
      value: OddsboardSelectionType.EXACTA,
      isDisabled: false,
    },
    {
      title: OddsboardType.DOUBLE,
      value: OddsboardSelectionType.DOUBLE,
      isDisabled: false,
    },
    {
      title: OddsboardType.WILL_PAYS,
      value: OddsboardSelectionType.WILL_PAYS,
      isDisabled: true,
    },
    {
      title: OddsboardType.ALL_POOLS,
      value: OddsboardSelectionType.ALL_POOLS,
      isDisabled: false,
    },
  ];

  return (
    <div className={styles.tabsGrid}>
      {oddsboardViews.map((btn) => (
        <Button
          key={btn.title}
          className={clsx(styles.tab, {
            [styles.selected]: settings.oddsboardSelected === btn.value,
          })}
          onClick={() =>
            updateSettings((prevSettings) => ({
              ...prevSettings,
              oddsboardSelected: btn.value,
            }))
          }
          size="medium"
          disabled={btn.isDisabled}
          theme="transparentDark"
        >
          {btn.title}
        </Button>
      ))}
    </div>
  );
}
