import styles from './ModalCard.module.css';

export default function ModalCard({
  children,
  title,
  description,
}: {
  children: React.ReactNode;
  title?: string;
  description?: string;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {title ? <h2 className={styles.title}>{title}</h2> : null}
        {description ? (
          <p className={styles.description}>{description}</p>
        ) : null}
      </div>
      {children}
    </div>
  );
}
