import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import useClickOutside from '../../hooks/useClickOutside';
import Icon from '../Icon/Icon';
import styles from './Modal.module.css';

type Props = {
  onDismiss: () => void;
  children: React.ReactNode;
  fullscreen?: boolean;
  className?: string;
  closeButtonClassName?: string;
};

export default function Modal({
  onDismiss,
  children,
  fullscreen,
  className = '',
  closeButtonClassName = '',
}: Props) {
  const modalRef = useRef(null);
  useClickOutside(modalRef, onDismiss, true);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return createPortal(
    <div className={styles.container}>
      <div
        className={clsx(
          styles.modal,
          {
            [styles.fullscreen]: fullscreen,
          },
          className
        )}
        ref={modalRef}
      >
        <button
          type="button"
          className={clsx(styles.closeButton, closeButtonClassName)}
          onClick={onDismiss}
        >
          <Icon name="cross" />
        </button>
        {children}
      </div>
    </div>,
    document.querySelector('.bm-root') || document.body
  );
}
