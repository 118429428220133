import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import Button from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';
import Icon from '../../../../components/Icon/Icon';
import {
  AllFilters,
  TransactionFilters,
  TransactionTypes,
} from '../../../../constants/betStatus';
import useProxy from '../../../../hooks/useProxy';
import { BetHistoryType } from '../../../../types/betHistoryType';
import { Transaction } from '../../../../types/transaction';
import FilterButtons from '../FilterButtons/FilterButtons';
import Filters, { FilterButton } from '../Filters/Filters';
import MyBetCard from '../MyBetCard/MyBetCard';
import styles from './TransactionHistory.module.css';

const now = dayjs();

export default function TransactionHistory() {
  const [statusFilters, setStatusFilters] = useState<AllFilters[]>([]);
  const [maxResultsOnPage] = useState(200);
  const [pageNumber] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(
    now.subtract(20, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    now.add(12, 'hours').format('YYYY-MM-DD')
  );
  const axios = useProxy();

  const transactionHistoryData = useQuery<{ content: Transaction[] }>(
    ['TransactionHistory', maxResultsOnPage, pageNumber, startDate, endDate],
    async () => {
      const response = await axios.get('/api/v2/falcon/transactions/history', {
        params: {
          'dateRange.endDate': endDate,
          'dateRange.startDate': startDate,
          size: maxResultsOnPage,
          pageNumber,
        },
      });
      return response.data;
    }
  );

  const transactionHistory = transactionHistoryData?.data?.content || [];

  const betHistoryData = useQuery(
    ['BetHistory', endDate, startDate],
    async () => {
      const response = await axios.get('/api/v3/falcon/bet/history', {
        params: {
          'dateRange.endDate': endDate,
          'dateRange.startDate': startDate,
        },
      });
      return response.data;
    }
  );

  const filteredHistory =
    statusFilters.length > 0
      ? transactionHistory.filter((transaction) =>
          statusFilters.includes(transaction.type as AllFilters)
        )
      : transactionHistory;

  const filteredBets =
    betHistoryData?.data?.historicBets.filter((bet: BetHistoryType) => {
      if (statusFilters.length === 0) {
        return true;
      }
      if (
        statusFilters.includes(TransactionFilters.CANCELED) &&
        bet.bet.status === 'CANCELED'
      ) {
        return true;
      }
      if (
        statusFilters.includes(TransactionFilters.REJECTED) &&
        bet.bet.status === 'REJECTED'
      ) {
        return true;
      }
      if (
        statusFilters.includes(TransactionFilters.WIN) &&
        bet.bet.payout &&
        bet.bet.status !== 'CANCELED' &&
        bet.bet.status !== 'REJECTED'
      ) {
        return true;
      }
      if (
        statusFilters.includes(TransactionFilters.LOSS) &&
        !bet.bet.payout &&
        bet.bet.status !== 'CANCELED' &&
        bet.bet.status !== 'REJECTED'
      ) {
        return true;
      }
      return false;
    }) || [];

  const allTransactions =
    [...(filteredBets || []), ...filteredHistory].sort((a, b) =>
      dayjs(a?.createdOn || a?.date).isBefore(b?.createdOn || b?.date) ? 1 : -1
    ) || [];

  return (
    <>
      <Helmet title="Transaction History">
        <meta name="description" content="Transaction History" />
      </Helmet>
      <div className={styles.filters}>
        <FilterButtons filters={statusFilters} setFilters={setStatusFilters} />
        <FilterButton selected={filtersOpen} setSelected={setFiltersOpen} />
      </div>
      <Filters
        endDate={endDate}
        startDate={startDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        filtersOpen={filtersOpen}
      />
      {!transactionHistoryData.isLoading ? (
        <div>
          {allTransactions?.map((item) => (
            <div key={item.id}>
              {item.date ? (
                <TransactionCard item={item} />
              ) : (
                <MyBetCard bet={item} />
              )}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}

function TransactionCard({ item }: { item: Transaction }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card className={clsx(styles.card, { [styles.expanded]: expanded })}>
      <div className={styles.cardContent}>
        <div className={styles.description}>
          <div className={styles.heading}>
            <div>
              <Icon
                name={TransactionTypes[item.type]}
                className={styles.cardIcon}
              />
              {item.type}
            </div>
            <Button
              theme="transparentDark"
              size="small"
              onClick={() => setExpanded((prev) => !prev)}
            >
              <Icon name="caretDown" className={styles.chevron} />
            </Button>
          </div>
          <div className={styles.content}>
            <span className={styles.value}>
              {dayjs(item.date).format('YYYY-MM-DD')}
            </span>
            <div className={styles.transactionValues}>
              <span
                className={clsx(
                  styles.value,
                  styles[TransactionTypes[item.type]]
                )}
              >{`${
                item.type === 'Cash Deposit' || item.type === 'Winning Ticket'
                  ? '+'
                  : '-'
              }$${item.amount}`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomContent}>
        <div>
          <span className={styles.bold}>Transaction ID: </span>
          <span>{item.id}</span>
        </div>
        <div>
          <span className={styles.bold}>Placed: </span>
          <span>{dayjs(item.date).format('YYYY-MM-DD')}</span>
        </div>
      </div>
    </Card>
  );
}
