import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useBreadcrumbs from '../../hooks/useBreadcrumbs/useBreadcrumbs';
import HorizontalOverflow from '../HorizontalOverflow/HorizontalOverflow';
import styles from './HomeContainer.module.css';

type Props = {
  HeaderComponent: ReactNode;
  tabs?: {
    label: string;
    url: string;
    mobileOnly?: boolean;
    desktopOnly?: boolean;
  }[];
  children: ReactNode;
};

export default function HomeContainer({
  HeaderComponent,
  tabs = [],
  children,
}: Props) {
  const breadcrumbs = useBreadcrumbs();
  const location = useLocation();

  return (
    <>
      <div className={styles.container}>
        <ul className={styles.breadcrumbs}>
          {breadcrumbs.map((breadcrumb) => (
            <li className={styles.breadcrumb} key={breadcrumb.label}>
              <Link to={breadcrumb.url} className={styles.breadcrumbLink}>
                {breadcrumb.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className={styles.headerComponent}>{HeaderComponent}</div>
        {tabs.length > 0 ? (
          <HorizontalOverflow>
            <div className={styles.tabs}>
              {tabs.map((tab) => (
                <Link
                  key={tab.label}
                  to={tab.url}
                  className={clsx(styles.tab, {
                    [styles.selected]: location.pathname === tab.url,
                    [styles.mobileOnly]: tab.mobileOnly,
                    [styles.desktopOnly]: tab.desktopOnly,
                  })}
                >
                  {tab.label}
                </Link>
              ))}
            </div>
          </HorizontalOverflow>
        ) : null}
      </div>
      {children}
    </>
  );
}
