import styles from './SignupModal.module.css';

export default function SignupHeaderSuccess() {
  return (
    <div className={styles.header}>
      <h1>Welcome to Interbets!</h1>
      <p className={styles.subHeading}>
        Get Notified when Interbets is available in your state.
      </p>
    </div>
  );
}
