import QueryString from 'qs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from './SignupModal.module.css';

export default function SignupFooter() {
  const history = useHistory();
  const location = useLocation();

  return (
    <p className={styles.secondary}>
      Have an account?{' '}
      <span
        className={styles.textLink}
        role="link"
        tabIndex={0}
        onClick={(event) => {
          event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
          event.preventDefault(); // Prevents nested embedded links to be fired
          history.push({
            search: QueryString.stringify({
              ...QueryString.parse(location.search, {
                ignoreQueryPrefix: true,
              }),
              action: 'login',
            }),
          });
        }}
      >
        Login
      </span>{' '}
      or{' '}
      <Link
        className={styles.textLink}
        to={{
          search: QueryString.stringify({
            ...QueryString.parse(location.search, {
              ignoreQueryPrefix: true,
            }),
            action: 'forgot',
          }),
        }}
        onClick={(event) => event.stopPropagation()}
      >
        Reset your password
      </Link>
    </p>
  );
}
