import clsx from 'clsx';
import useAppState from '../../../../../hooks/useAppState/useAppState';
import useFormat from '../../../../../hooks/useFormat';
import { RaceDetailedCompetitor } from '../../../../../hooks/useRace/types';
import { UseRaceData } from '../../../../../hooks/useRace/useRace';
import {
  CompetitorConnection,
  PlacingStats,
} from '../../../../../hooks/useRunner/types';
import useRunner from '../../../../../hooks/useRunner/useRunner';
import styles from './ShortForm.module.css';

type Props = {
  competitor: RaceDetailedCompetitor;
  race: UseRaceData;
};

function checkFinalPos(competitor: CompetitorConnection | undefined) {
  if (competitor) {
    const pastStartsFilter = competitor.formHistory.filter(
      (e) => e.class !== 'BTrial' && e.class !== 'Barrier Trial'
    );
    const pastStarts = pastStartsFilter.slice(0, 10).reverse();
    const finalPos = pastStarts.map((b, index) => {
      let a = { ...b };
      if (index !== pastStarts.length - 1) {
        const startTime = pastStarts[index].date;
        const pastTimer = pastStarts[index + 1].date;
        const startTimeMili = new Date(startTime).getTime();
        const pastTimeMili = new Date(pastTimer).getTime();
        const timeBetween = startTimeMili - pastTimeMili;
        const daysBetween = timeBetween / (1000 * 60 * 60 * 24);
        const daysFormatted = Math.floor(daysBetween);
        a = { ...a, dslsLast5: Math.abs(daysFormatted) };
        // b.dslsLast5 = Math.abs(daysFormatted)
      }
      if (a.finishingPosition > 9) {
        return 0;
      }
      if (a.dslsLast5 && a.dslsLast5 >= 84) {
        return `${a.finishingPosition}x`;
      }
      return a.finishingPosition;
    });
    const finalPosStr = finalPos.toString();
    const final = finalPosStr.replace(/,/g, '');
    return final;
  }
  return '';
}

function getCondition(condition: string) {
  let keyCondition = '';
  if (condition.includes('Firm')) {
    keyCondition = 'firm';
  }
  if (condition.includes('Good')) {
    keyCondition = 'good';
  }
  if (condition.includes('Soft')) {
    keyCondition = 'soft';
  }
  if (condition.includes('Heavy')) {
    keyCondition = 'heavy';
  }
  if (condition.includes('Synthetic')) {
    keyCondition = 'synthetic';
  }
  return keyCondition;
}

export default function ShortFormInfo({ competitor, race }: Props) {
  const {
    settings: { showShortForm },
  } = useAppState();
  const runner = useRunner(competitor.runner.id);
  const format = useFormat();
  const competitorDetail = runner?.competitorsConnection?.competitors?.[0];
  const careerDetail = competitorDetail?.runnerStatistics?.career?.detail;
  const careerSpell = competitorDetail?.runnerStatistics?.career?.spell;

  const conditions = careerDetail?.byCondition?.reduce<{
    [key: string]: PlacingStats;
  }>((aggregated, value) => {
    const condition = getCondition(value.key);
    const currentValue = aggregated[condition] || {
      numberOfRuns: 0,
      firsts: 0,
      seconds: 0,
      thirds: 0,
    };

    return {
      ...aggregated,
      [condition]: {
        ...currentValue,
        numberOfRuns: +currentValue.numberOfRuns + value.numberOfRuns,
        firsts: +currentValue.firsts + value.firsts,
        seconds: +currentValue.seconds + value.seconds,
        thirds: +currentValue.thirds + value.thirds,
      },
    };
  }, {});

  const byTrack = careerDetail?.byTrack?.filter?.(
    ({ key }) => key === race.meeting.track.name
  )[0] || {
    numberOfRuns: 0,
    firsts: 0,
    seconds: 0,
    thirds: 0,
  };

  const byDistance = careerDetail?.byDistance?.filter?.(
    ({ key }) => key === String(race.distance)
  )[0] || {
    numberOfRuns: 0,
    firsts: 0,
    seconds: 0,
    thirds: 0,
  };

  const byTrackDistance = careerDetail?.byTrackAndDistance?.filter?.(
    ({ key }) =>
      key === `(${race.meeting.track.name}, ${String(race.distance)})`
  )[0] || {
    numberOfRuns: 0,
    firsts: 0,
    seconds: 0,
    thirds: 0,
  };

  return (
    <>
      <div className={styles.shortFormRunnerInfo}>
        <span className={styles.minWidth}>
          <span className={styles.label}>Last 10 </span>
          {checkFinalPos(competitorDetail)}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Career </span>
          {`${
            competitorDetail?.runnerStatistics
              ? competitorDetail?.runnerStatistics?.career?.all?.numberOfRuns
              : 0
          } ${
            competitorDetail?.runnerStatistics
              ? competitorDetail?.runnerStatistics?.career?.all?.firsts
              : 0
          }-${
            competitorDetail?.runnerStatistics
              ? competitorDetail?.runnerStatistics?.career?.all?.seconds
              : 0
          }-${
            competitorDetail?.runnerStatistics
              ? competitorDetail?.runnerStatistics?.career?.all?.thirds
              : 0
          }`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Prize </span>
          {format.currency(competitorDetail?.prizeMoney)}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Win </span>
          {competitorDetail?.runnerStatistics
            ? `${competitorDetail?.runnerStatistics?.career?.all?.winPercentage}%`
            : `0%`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Place </span>
          {competitorDetail?.runnerStatistics
            ? `${competitorDetail?.runnerStatistics?.career?.all?.placePercentage}%`
            : `0%`}
        </span>
      </div>
      <div
        className={clsx(styles.separator, {
          [styles.shortForm]: showShortForm,
        })}
      />
      <div className={styles.shortFormRunnerInfo}>
        <span className={styles.minWidth}>
          <span className={styles.label}>Firm </span>
          {`${conditions?.firm ? conditions?.firm?.numberOfRuns : 0} ${
            conditions?.firm ? conditions?.firm?.firsts : 0
          }-${conditions?.firm ? conditions?.firm?.seconds : 0}-${
            conditions?.firm ? conditions?.firm?.thirds : 0
          }`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Good </span>
          {`${conditions?.good ? conditions?.good?.numberOfRuns : 0} ${
            conditions?.good ? conditions?.good?.firsts : 0
          }-${conditions?.good ? conditions?.good?.seconds : 0}-${
            conditions?.good ? conditions?.good?.thirds : 0
          }`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Soft </span>
          {`${conditions?.soft ? conditions?.soft?.numberOfRuns : 0} ${
            conditions?.soft ? conditions?.soft?.firsts : 0
          }-${conditions?.soft ? conditions?.soft?.seconds : 0}-${
            conditions?.soft ? conditions?.soft?.thirds : 0
          }`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Heavy </span>
          {`${conditions?.heavy ? conditions?.heavy?.numberOfRuns : 0} ${
            conditions?.heavy ? conditions?.heavy?.firsts : 0
          }-${conditions?.heavy ? conditions?.heavy?.seconds : 0}-${
            conditions?.heavy ? conditions?.heavy?.thirds : 0
          }`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Synthetic </span>
          {`${
            conditions?.synthetic ? conditions?.synthetic?.numberOfRuns : 0
          } ${conditions?.synthetic ? conditions?.synthetic?.firsts : 0}-${
            conditions?.synthetic ? conditions?.synthetic?.seconds : 0
          }-${conditions?.synthetic ? conditions?.synthetic?.thirds : 0}`}
        </span>
      </div>

      <div className={styles.shortFormRunnerInfo}>
        <span className={styles.minWidth}>
          <span className={styles.label}>Track </span>
          {`${byTrack.numberOfRuns} ${byTrack.firsts}-${byTrack.seconds}-${byTrack.thirds}`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Distance </span>
          {`${byDistance.numberOfRuns} ${byDistance.firsts}-${byDistance.seconds}-${byDistance.thirds}`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>Trk/Dst </span>
          {`${byTrackDistance.numberOfRuns} ${byTrackDistance.firsts}-${byTrackDistance.seconds}-${byTrackDistance.thirds}`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>1st Up </span>
          {`${
            competitorDetail?.runnerStatistics
              ? careerSpell?.firstUp?.numberOfRuns
              : 0
          } ${
            competitorDetail?.runnerStatistics
              ? careerSpell?.firstUp?.firsts
              : 0
          }-${
            competitorDetail?.runnerStatistics
              ? careerSpell?.firstUp?.seconds
              : 0
          }-${
            competitorDetail?.runnerStatistics
              ? careerSpell?.firstUp?.thirds
              : 0
          }`}
        </span>
        &nbsp;
        <span className={styles.minWidth}>
          <span className={styles.label}>2nd Up </span>
          {`${
            competitorDetail?.runnerStatistics
              ? careerSpell?.secondUp?.numberOfRuns
              : 0
          } ${
            competitorDetail?.runnerStatistics
              ? careerSpell?.secondUp?.firsts
              : 0
          }-${
            competitorDetail?.runnerStatistics
              ? careerSpell?.secondUp?.seconds
              : 0
          }-${
            competitorDetail?.runnerStatistics
              ? careerSpell?.secondUp?.thirds
              : 0
          }`}
        </span>
      </div>
      <div
        className={clsx(styles.separator, {
          [styles.shortForm]: showShortForm,
        })}
      />
    </>
  );
}
