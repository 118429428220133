import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Input from '../../../../components/Form/Input/Input';
import Select from '../../../../components/Form/Select/Select';
import RacingFilters from '../../../../components/RacingFilters/RacingFilters';
import { MeetingType } from '../../../../constants/meetingTypes';
import raceStatuses from '../../../../constants/raceStatuses';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs/useBreadcrumbs';
import useMeetings from '../../../../hooks/useMeetings/useMeetings';
import { Meeting } from '../../../../hooks/useRace/types';
import useRace from '../../../../hooks/useRace/useRace';
import RaceHeader from '../../Race/RaceHeader';
import RaceResult from '../../Race/RaceResult';
import styles from './RacingResults.module.css';

const now = dayjs();

export default function RacingResults() {
  const route = useMemo(
    () => [
      {
        label: 'Racing',
        url: '/racing',
      },
      {
        label: 'Results',
        url: '/racing/results',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  const [typeFilters, setTypeFilters] = useState<MeetingType[]>([]);

  const startOfDay = now.startOf('date');
  const nowString = startOfDay.format('YYYY-MM-DD');
  const [selectedDate, setSelectedDate] = useState(nowString);
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | undefined>();
  const [selectedRaceId, setSelectedRaceId] = useState<string | undefined>();
  const { race } = useRace(selectedRaceId);

  const meetings = useMeetings(
    dayjs(selectedDate).startOf('day'),
    dayjs(selectedDate).endOf('day')
  );

  function onChangeDateResults(event: ChangeEvent<HTMLInputElement>) {
    setSelectedDate(event.target.value);
    setSelectedMeeting(undefined);
  }

  function onChangeMeeting(meetingId: string) {
    const meeting = meetings?.find((a) => a.id === meetingId);
    setSelectedMeeting(meeting);
  }

  useEffect(() => {
    if (selectedMeeting) {
      const nextRace =
        selectedMeeting?.races.filter(
          (r) =>
            r.status === raceStatuses.CLOSED &&
            r.results.length > 0 &&
            r.status !== raceStatuses.ABANDONED
        )[0] || selectedMeeting?.races[0];
      setSelectedRaceId(nextRace.id);
    }
  }, [selectedMeeting]);

  const filteredMeetings =
    typeFilters.length > 0
      ? meetings?.filter((meeting) => typeFilters.includes(meeting.type))
      : meetings;

  return (
    <>
      <Helmet title="Results">
        <meta name="description" content="Racing Results" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.filters}>
            <label htmlFor="date">Date</label>
            <div>
              <Input
                id="date"
                type="date"
                value={selectedDate}
                onChange={onChangeDateResults}
                className={styles.dateInput}
              />
            </div>
            <div>
              <RacingFilters
                filters={typeFilters}
                setFilters={setTypeFilters}
              />
            </div>
            <div>
              <Select
                value={selectedMeeting?.id}
                onChange={(event) => onChangeMeeting(event.target.value)}
                options={[
                  {
                    value: '',
                    label: 'Track',
                  },
                  ...(filteredMeetings?.map((m) => ({
                    value: m.id,
                    label: m.track.name,
                  })) || []),
                ]}
              />
            </div>
          </div>
        </div>
        {race && selectedMeeting && meetings ? (
          <div className={styles.raceHeader}>
            <RaceHeader
              race={race}
              meeting={selectedMeeting}
              meetings={meetings}
              onChangeMeeting={onChangeMeeting}
              onChangeRace={setSelectedRaceId}
            />
          </div>
        ) : null}
        <div>{selectedMeeting && race ? <RaceResult race={race} /> : null}</div>
      </div>
    </>
  );
}
