import useAppState from './useAppState/useAppState';

// Returns array of meetings filtered by preferredCountry, International or neither

export default function useCountryFilter() {
  const {
    meetings,
    config,
    countryFilter: { countryFilters },
  } = useAppState();

  if (!config?.options?.preferredCountry) return meetings;

  const { preferredCountry } = config.options;

  if (!countryFilters.length || countryFilters.length === 2) {
    return meetings;
  }

  if (preferredCountry && countryFilters.includes(preferredCountry)) {
    return meetings.filter(
      (meeting) => meeting.track.country === preferredCountry
    );
  }

  if (countryFilters.includes('Intl')) {
    return meetings.filter(
      (meeting) => meeting.track.country !== preferredCountry
    );
  }

  return meetings;
}
