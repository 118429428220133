import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { HashRouter } from 'react-router-dom';
import AppStateProvider from './hooks/useAppState/AppStateProvider';
import RemoteConfigProvider from './hooks/useRemoteConfig/RemoteConfigProvider';
import App from './pages/App/App';
import './styles/globals.css';
import './styles/variables.css';
import { Config } from './types/window';
import dodgeToken from './utils/dodgeToken';

async function init(config: Config, element: HTMLElement) {
  if (!config) {
    throw new Error('Application is misconfigured.');
  }

  if (process.env.NODE_ENV === 'development') {
    dodgeToken();
  }

  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(advanced);

  let root = element;

  if (!element) {
    root = document.createElement('div');
    document.body.appendChild(root);
  }

  root.classList.add('bm-root');

  firebase.initializeApp(
    JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_STRING)
  );
  firebase.analytics();
  firebase.analytics().setUserProperties({
    tenant: config.clientId,
  });

  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <HelmetProvider>
          <RemoteConfigProvider>
            <AppStateProvider config={config}>
              <App />
            </AppStateProvider>
          </RemoteConfigProvider>
        </HelmetProvider>
      </HashRouter>
    </React.StrictMode>,
    root
  );
}

window.bm = {
  init,
};
