import { gql, useQuery } from '@apollo/client';
import { Runner } from './types';

export default function useRunner(id: string) {
  const { data } = useQuery<{ runner: Runner }>(
    gql`
      query runner($id: ID!) {
        runner(id: $id) {
          competitorsConnection(first: 1) {
            competitors {
              tabNo
              name
              jockey
              trainer
              sex
              age
              colour
              owners
              sire
              dam
              prizeMoney
              formHistory {
                date
                track
                finishingPosition
                starters
                distance
                class
                margin
                trackCondition
                elapsedTime
                barrier
                jockey
                placeGetters {
                  finishingPosition
                  name
                }
              }
              runnerStatistics {
                career {
                  all {
                    numberOfRuns
                    firsts
                    seconds
                    thirds
                    winPercentage
                    placePercentage
                  }
                  spell {
                    firstUp {
                      numberOfRuns
                      firsts
                      seconds
                      thirds
                    }
                    secondUp {
                      numberOfRuns
                      firsts
                      seconds
                      thirds
                    }
                    thirdUp {
                      numberOfRuns
                      firsts
                      seconds
                      thirds
                    }
                    fourthUp {
                      numberOfRuns
                      firsts
                      seconds
                      thirds
                    }
                  }
                  detail {
                    byTrack {
                      key
                      numberOfRuns
                      firsts
                      seconds
                      thirds
                    }
                    byCondition {
                      key
                      numberOfRuns
                      firsts
                      seconds
                      thirds
                    }
                    byDistance {
                      key
                      numberOfRuns
                      firsts
                      seconds
                      thirds
                    }
                    byTrackAndDistance {
                      key
                      numberOfRuns
                      firsts
                      seconds
                      thirds
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id,
      },
    }
  );

  return data?.runner;
}
