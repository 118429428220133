import styles from './ToggleSwitch.module.css';

export default function ToggleSwitch({
  value,
  changeValue,
}: {
  value: boolean;
  changeValue: () => void;
}) {
  return (
    <div className={styles.toggleSwitchContainer}>
      <label className={styles.toggleSwitchLabel}>
        <input
          type="checkbox"
          className={styles.toggleSwitchInput}
          checked={value}
          onChange={changeValue}
        />
        <span className={styles.toggleSwitchToggle} />
      </label>
    </div>
  );
}
