import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import Icon from '../../../../components/Icon/Icon';
import { MultiBetType } from '../../../../constants/betTypes';
import useBetslip from '../../../../hooks/useBetslip/useBetslip';
import { betTypeLookup } from '../../../../utils/betslip/common';
import styles from './FloatingNavigation.module.css';

export default function FloatingNavigation() {
  const {
    state: { multiSelections },
  } = useBetslip();
  const history = useHistory();
  const match = useRouteMatch<{ betType: string; raceId: string }>();
  const betType = betTypeLookup(match?.params?.betType);
  const { raceId } = match.params;
  const selectedBet = multiSelections?.[betType as string as MultiBetType];
  const currentRaceIndex = selectedBet?.races.indexOf(raceId);

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <div className={styles.bounds}>
          {selectedBet.races[currentRaceIndex - 1] ? (
            <Button
              theme="secondary"
              onClick={() =>
                history.push(
                  `/racing/race/${
                    selectedBet.races[currentRaceIndex - 1]
                  }/${betType.toLowerCase().split(' ').join('')}`
                )
              }
              className={styles.buttonPrev}
            >
              <Icon name="caretDown" className={clsx(styles.chevronPrev)} />
              Previous Race
            </Button>
          ) : null}
        </div>
        <div className={styles.raceButtonGroup}>
          {selectedBet?.races?.map((race, index) => (
            <Button
              key={race}
              theme="secondary"
              className={styles.raceButton}
              disabled={selectedBet.races[index] === raceId}
              onClick={() =>
                history.push(
                  `/racing/race/${selectedBet.races[index]}/${betType
                    .toLowerCase()
                    .split(' ')
                    .join('')}`
                )
              }
            >
              Race {index + 1}
            </Button>
          ))}
        </div>
        <div className={clsx(styles.bounds, styles.next)}>
          {selectedBet.races[currentRaceIndex + 1] ? (
            <Button
              theme="secondary"
              onClick={() =>
                history.push(
                  `/racing/race/${
                    selectedBet.races[currentRaceIndex + 1]
                  }/${betType.toLowerCase().split(' ').join('')}`
                )
              }
              className={styles.buttonNext}
            >
              Next Race
              <Icon name="caretDown" className={clsx(styles.chevronNxt)} />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
