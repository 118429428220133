import { useCallback } from 'react';
import { BetslipEventType } from '../../constants/appEvents';
import { BetType } from '../../constants/betTypes';
import {
  AddOrRemoveBetslipDetail,
  UpdateAllStakeBetslipDetail,
  UpdateTraditionalSingleBetslipDetail,
} from '../../utils/betslip/eventDispatcher';
import { SingleBet } from '../../utils/betslip/types';
import useBetslip from './useBetslip';

export const fixedSingleBetTypes = [
  BetType.WIN_FIXED_ODDS,
  BetType.PLACE_FIXED_ODDS,
  BetType.SHOW_FIXED_ODDS,
];
export const toteSingleBetTypes = [BetType.WIN, BetType.PLACE, BetType.SHOW];
export const singleBetTypes = [...toteSingleBetTypes, ...fixedSingleBetTypes];

export default function useSingleBetslip() {
  const { state, dispatch } = useBetslip();

  const isSingleBetType = useCallback(
    (betType: BetType) => singleBetTypes.includes(betType),
    []
  );

  const getSingleBets = useCallback(
    () =>
      state.betslip.filter((bet) =>
        singleBetTypes.includes(bet.betType)
      ) as SingleBet[],
    [state.betslip]
  );

  const addOrRemoveBet = useCallback(
    (detail: Omit<AddOrRemoveBetslipDetail, 'type'>) => {
      dispatch({ type: BetslipEventType.ADD_OR_REMOVE, ...detail });
    },
    [dispatch]
  );

  const singleBetIndex = useCallback(
    (singleBet: SingleBet) =>
      getSingleBets().findIndex(
        (bet) =>
          bet.betType === singleBet.betType &&
          bet.race.id === singleBet.race.id &&
          bet.competitor.tabNo === singleBet.competitor.tabNo
      ),
    [getSingleBets]
  );

  const inBetslip = useCallback(
    (singleBet: SingleBet) => singleBetIndex(singleBet) !== -1,
    [singleBetIndex]
  );

  const updateStakeByBetTypeRace = useCallback(
    (detail: Omit<UpdateTraditionalSingleBetslipDetail, 'type'>) => {
      dispatch({
        type: BetslipEventType.UPDATE_TRADITIONAL_SINGLE_STAKE,
        ...detail,
      });
    },
    [dispatch]
  );

  const updateStakeAll = useCallback(
    (detail: Omit<UpdateAllStakeBetslipDetail, 'type'>) => {
      dispatch({ type: BetslipEventType.UPDATE_ALL_SINGLES_STAKE, ...detail });
    },
    [dispatch]
  );

  return {
    isSingleBetType,
    getSingleBets,
    addOrRemoveBet,
    singleBetIndex,
    inBetslip,
    updateStakeByBetTypeRace,
    updateStakeAll,
  };
}
