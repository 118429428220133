import clsx from 'clsx';
import { forwardRef, useState } from 'react';
import Button from '../../Button/Button';
import styles from './Input.module.css';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  prefix?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className = '', prefix = '', type, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const inputType = type === 'password' && showPassword ? 'text' : type;

    return (
      <div
        className={clsx(styles.container, {
          [styles.hasPrefix]: !!prefix,
        })}
        data-prefix={prefix}
      >
        <input
          className={clsx(styles.input, className)}
          style={{
            paddingLeft: `${prefix?.length * 1 + 0.8}rem`,
          }}
          type={inputType}
          ref={ref}
          {...rest}
        />
        {type === 'password' ? (
          <Button
            theme="none"
            className={styles.showPasswordButton}
            tabIndex={-1}
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? 'Hide' : 'Show'}
          </Button>
        ) : null}
      </div>
    );
  }
);

export default Input;
