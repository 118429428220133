import { useState } from 'react';
import { MeetingType } from '../../constants/meetingTypes';
import useCountryFilterNTJ from '../../hooks/useCountryFilterNTJ';
import { NextToJump as NextToJumpType } from '../../hooks/useMeetings/types';
import CountryFilters from '../CountryFilters/CountryFilters';
import HorizontalOverflow from '../HorizontalOverflow/HorizontalOverflow';
import RacingFilters from '../RacingFilters/RacingFilters';
import styles from './NextToJump.module.css';
import NextToJumpRace from './NextToJumpRace';

export default function NextToJump() {
  const countryFilteredNTJ: NextToJumpType[] = useCountryFilterNTJ();
  const [typeFilters, setTypeFilters] = useState<MeetingType[]>([]);
  const filteredMeetings =
    typeFilters.length > 0
      ? countryFilteredNTJ?.filter((race: NextToJumpType) =>
          typeFilters.includes(race.meeting.type)
        )
      : countryFilteredNTJ;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Upcoming Races</h2>
        <div className={styles.filters}>
          <RacingFilters filters={typeFilters} setFilters={setTypeFilters} />
          <CountryFilters />
        </div>
      </div>
      <HorizontalOverflow scrollWidth={150}>
        {filteredMeetings.slice(0, 3).map((race: NextToJumpType) => (
          <NextToJumpRace key={race.id} nextToJump={race} />
        ))}
      </HorizontalOverflow>
    </div>
  );
}
