import useAppState from '../../../hooks/useAppState/useAppState';
import styles from './Footer.module.css';

type FooterList = {
  title: string;
  children: NavigationItem[];
};

type NavigationItem = {
  label: string;
  url: string;
};

type ImageNavigationItem = NavigationItem & {
  image: string;
};

export default function Footer({
  imageList = [],
  footerLists = [],
  description,
}: {
  imageList: ImageNavigationItem[];
  footerLists: FooterList[];
  description: string;
}) {
  const { config } = useAppState();

  return (
    <footer className={styles.footer}>
      <div className={styles.row}>
        <ul className={styles.imageList}>
          {imageList.map((imageListItem) => (
            <li key={imageListItem.label} className={styles.imageListItem}>
              <a href={imageListItem.url}>
                <img
                  src={`${config?.contentUrl}${imageListItem.image}`}
                  alt={imageListItem.label}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.row}>
        <div className={styles.footerLists}>
          {footerLists.map((footerList) => (
            <div className={styles.footerList} key={footerList.title}>
              <span className={styles.title}>{footerList.title}</span>
              <ul className={styles.list}>
                {footerList.children.map((list) => (
                  <li key={list.label}>
                    <a href={list.url} className={styles.link}>
                      {list.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.row}>{description}</div>
    </footer>
  );
}
