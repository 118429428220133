import { RefObject, useEffect } from 'react';

export default function useClickOutside<T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  enabled = false
) {
  useEffect(() => {
    function handler(event: Event) {
      if (ref.current?.contains(event.target as Node)) {
        return;
      }

      callback();
    }

    if (enabled) {
      document.addEventListener('click', handler);
    } else {
      document.removeEventListener('click', handler);
    }

    return () => {
      document.removeEventListener('click', handler);
    };
  }, [ref, callback, enabled]);
}
