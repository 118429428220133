const raceStatuses = {
  UNKNOWN: 'UNKNOWN',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  INTERIM: 'INTERIM',
  FINAL: 'FINAL',
  IN_PROGRESS: 'IN_PROGRESS',
  BEHIND_GATES: 'BEHIND_GATES',
  PAST_POST: 'PAST_POST',
  ABANDONED: 'ABANDONED',
  PROTEST: 'PROTEST',
};

export default raceStatuses;

export type RaceStatus = keyof typeof raceStatuses;
