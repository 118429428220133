import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import useClickOutside from '../../../hooks/useClickOutside';
import Icon from '../../Icon/Icon';
import styles from './InputRow.module.css';

type Props = {
  label: string;
  children: React.ReactNode;
  errorText?: string;
  hintText?: string;
  ActionComponent?: React.ReactNode;
  helpText?: React.ReactNode;
};

export default function InputRow({
  label,
  children,
  errorText,
  hintText,
  ActionComponent,
  helpText,
}: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLInputElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(
    buttonRef.current,
    tooltipRef.current,
    {
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    }
  );

  useClickOutside(tooltipRef, () => setShowTooltip(false), showTooltip);

  function handleTooltipClick(event: React.MouseEvent) {
    event.stopPropagation(); // Prevents `useClickOutside` from being fired immediately
    event.preventDefault(); // Prevents nested embedded links to be fired
    setShowTooltip((prev) => !prev);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.labelContainer}>
          <label htmlFor={label} className={styles.label}>
            {label}
            {helpText && (
              <button
                type="button"
                onClick={handleTooltipClick}
                ref={buttonRef}
                className={styles.iconContainer}
              >
                <Icon name="info" className={styles.icon} />
              </button>
            )}
          </label>
          {ActionComponent}
        </div>
        <div
          className={clsx(styles.row, {
            [styles.hasError]: !!errorText,
          })}
        >
          {children}
        </div>
        {errorText ? (
          <span className={styles.errorText}>{errorText}</span>
        ) : null}
        {hintText ? <span className={styles.hintText}>{hintText}</span> : null}
      </div>
      {showTooltip
        ? createPortal(
            <div
              ref={tooltipRef}
              className={styles.tooltip}
              style={popperStyles.popper}
              {...attributes.popper}
            >
              <div
                ref={setArrowRef}
                className={styles.arrow}
                style={popperStyles.arrow}
                data-popper-arrow
              />
              {helpText}
            </div>,
            document.getElementById('bm-root') || document.body
          )
        : null}
    </>
  );
}
