import { useEffect, useRef, useState } from 'react';
import { useMeasure } from '../../hooks/useMeasure';

interface CollapsibleProps {
  direction: 'width' | 'height';
  open: boolean;
  transitionTime?: number;
  maxSizeClosed?: number;
  maxSizeOpen?: 'auto' | number;
  children: React.ReactNode;
  initialOpen?: boolean;
}

const useDifferentValueForFirstRender = <A, B>(
  firstValue: A,
  laterValue: B
) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => setIsFirstRender(false), []);

  return isFirstRender ? firstValue ?? laterValue : laterValue;
};

function Collapsable({
  direction,
  children,
  open,
  transitionTime = 0.5,
  maxSizeClosed = 0,
  maxSizeOpen = 'auto',
  initialOpen,
}: CollapsibleProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const refMeasure = useMeasure(ref);
  const size = refMeasure?.[direction];

  const openSize = maxSizeOpen === 'auto' ? size : maxSizeOpen;
  const closeSize = maxSizeClosed;

  const effectiveOpen = useDifferentValueForFirstRender(initialOpen, open);

  const effectiveSize = effectiveOpen ? openSize : closeSize;

  return (
    <div
      style={{
        [direction]: `${effectiveSize}px`,
        overflow: 'hidden',
        transition: `${direction} ${transitionTime}s ease`,
      }}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
}

function CollapsableWidth(props: Omit<CollapsibleProps, 'direction'>) {
  return <Collapsable {...props} direction="width" />;
}

function CollapsableHeight(props: Omit<CollapsibleProps, 'direction'>) {
  return <Collapsable {...props} direction="height" />;
}

export { Collapsable as default, CollapsableWidth, CollapsableHeight };
