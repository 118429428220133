import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';

export default function PlayersCard() {
  const route = useMemo(
    () => [
      {
        label: 'Account',
        url: '/account',
      },
      {
        label: 'Players Card',
        url: '/account/players-card',
      },
    ],
    []
  );
  useBreadcrumbs(route);

  return (
    <HomeContainer HeaderComponent={<h1>Player’s Card</h1>}>
      <Helmet title="Player’s Card">
        <meta name="description" content="Player’s Card" />
      </Helmet>
    </HomeContainer>
  );
}
