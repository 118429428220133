import clsx from 'clsx';
import Icon from '../Icon/Icon';
import Badge from './Badge';
import styles from './Badge.module.css';

export default function FixedOddsBadge({ helper }: { helper?: boolean }) {
  return (
    <Badge
      Component={
        <div className={styles.helper}>
          <Icon name="fixedOdds" size="small" />
          {helper && <span className={styles.helperText}>Fixed Odds</span>}
        </div>
      }
    >
      {!helper && <span className={styles.badgeTitle}>Fixed Odds</span>}
      <span className={styles.details}>
        Fixed odds betting means that the odds that are shown when you place the
        bet are what you will get if the bet wins*
      </span>
      <span className={clsx(styles.details, styles.disclaimer)}>
        *There are sometimes exceptions that can effect the estimated payout
        such as Scratched runners.
      </span>
    </Badge>
  );
}
