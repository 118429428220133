import clsx from 'clsx';
import styles from './CountryFlag.module.css';
import * as flagList from './flags';

export type FlagName = keyof typeof flagList;

export default function CountryFlag({
  countryCode,
  size = 'medium',
}: {
  countryCode: FlagName;
  size?: 'small' | 'medium' | 'large';
}) {
  const FlagComponent = flagList[countryCode];

  return (
    <div className={styles.container}>
      <FlagComponent className={clsx(styles[size], styles.flag)} />
    </div>
  );
}
