import clsx from 'clsx';
import useAppState from '../../hooks/useAppState/useAppState';
import styles from './CountryFilters.module.css';

export default function CountryFilters() {
  const intl = 'Intl';
  const {
    config,
    countryFilter: { countryFilters, setCountryFilters },
  } = useAppState();

  function toggleFilters(countryCode: string) {
    setCountryFilters((prev: string[]) => {
      if (prev.includes(countryCode)) {
        return prev.filter((code) => code !== countryCode);
      }

      return [...prev, countryCode];
    });
  }

  if (!config?.options?.preferredCountry) return null;

  const { preferredCountry } = config.options;

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: countryFilters.includes(preferredCountry),
        })}
        onClick={() => toggleFilters(preferredCountry)}
      >
        {preferredCountry}
      </button>
      <button
        type="button"
        className={clsx(styles.button, {
          [styles.selected]: countryFilters.includes(intl),
        })}
        onClick={() => toggleFilters(intl)}
      >
        {intl}
      </button>
    </div>
  );
}
