import clsx from 'clsx';
import range from 'lodash/range';
import { Fragment, useContext } from 'react';
import { BetType } from '../../../../constants/betTypes';
import useMultiBetslip from '../../../../hooks/useBetslip/useMultiBetslip';
import { singleBetTypes } from '../../../../hooks/useBetslip/useSingleBetslip';
import usePrices from '../../../../hooks/usePrices/usePrices';
import RaceContext from '../RaceContext';
import styles from './RaceCard.module.css';
import EmptyRunnerDetails from './RunnerSelection/EmptyRunnerDetails';
import RunnerSelection from './RunnerSelection/RunnerSelection';
import SelectionHeader from './SelectionHeader/SelectionHeader';

export default function RaceCard({ className = '' }: { className?: string }) {
  const raceContext = useContext(RaceContext);
  const prices = usePrices(raceContext?.race);
  const { isMultiBetType } = useMultiBetslip();

  if (!raceContext?.race) return null;

  const { race, betType } = raceContext;
  const betTypes = betType === BetType.WIN ? singleBetTypes : [betType];

  return (
    <div className={clsx(styles.grid, styles[betType], className)}>
      <SelectionHeader tabs={betTypes} />
      {raceContext.sortedCompetitors?.map((competitor) => (
        <Fragment key={competitor.tabNo}>
          <RunnerSelection
            race={race}
            competitor={competitor}
            betTypes={betTypes}
            prices={prices?.[competitor.tabNo]}
          />
          <div className={styles.separator} />
        </Fragment>
      ))}
      {isMultiBetType(raceContext.betType)
        ? range(
            raceContext.multiRunnerLength - raceContext.sortedCompetitors.length
          ).map((value, index) => (
            <EmptyRunnerDetails
              key={value}
              tabNo={raceContext.sortedCompetitors.length + index}
            />
          ))
        : null}
    </div>
  );
}
