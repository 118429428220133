import useAppState from '../../../hooks/useAppState/useAppState';
import styles from './CreditCardDeposit.module.css';

export default function CardSecurityHelpText() {
  const { config } = useAppState();

  return (
    <img
      src={`${config?.contentUrl}/cvv.png`}
      alt="Card Security number position on card"
      width="170px"
      className={styles.cardImage}
    />
  );
}
