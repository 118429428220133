import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Container from '../../../components/Container/Container';
import HomeContainer from '../../../components/HomeContainer/HomeContainer';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs/useBreadcrumbs';
import BankWithdraw from './BankWithdraw';
import CheckWithdraw from './CheckWithdraw';
import styles from './Withdraw.module.css';
import WithdrawMenu from './WithdrawMenu';
import { WithdrawViews } from './WithdrawTypes';

export default function Withdraw() {
  const [view, setView] = useState<WithdrawViews>('bank');
  const route = useMemo(
    () => [
      {
        label: 'Account',
        url: '/account',
      },
      {
        label: 'Withdraw',
        url: '/account/withdraw',
      },
    ],
    []
  );

  useBreadcrumbs(route);

  const hasExistingAccount = false;

  return (
    <HomeContainer HeaderComponent={<h1>Withdraw</h1>}>
      <Helmet title="Withdraw">
        <meta name="description" content="Withdraw" />
      </Helmet>
      <Container>
        <div className={styles.balanceTotals}>
          <div className={styles.balanceItem}>
            <div>Withdrawable Balance</div>
            <div>$900.00</div>
          </div>
          <div className={styles.balanceItem}>
            <div>Account Balance</div>
            <div>$1000.00</div>
          </div>
        </div>
        <div className={styles.withdrawContainer}>
          <WithdrawMenu view={view} setView={setView} />
          <div className={styles.contentSection}>
            {view === 'bank' && (
              <BankWithdraw hasExistingAccount={hasExistingAccount} />
            )}
            {view === 'check' && <CheckWithdraw />}
          </div>
        </div>
      </Container>
    </HomeContainer>
  );
}
