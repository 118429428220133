import clsx from 'clsx';
import { useEffect, useState } from 'react';
import HorizontalOverflow from '../../../../components/HorizontalOverflow/HorizontalOverflow';
import Silk from '../../../../components/Silk/Silk';
import numberColours from '../../../../constants/numberColours';
import { ExoticDividend } from '../../../../hooks/usePools/types';
import useExoticPools from '../../../../hooks/usePools/useExoticPools';
import { Competitor, Race } from '../../../../hooks/useRace/types';
import useRace, { UseRaceData } from '../../../../hooks/useRace/useRace';
import style from './Double.module.css';

export default function Double({ race }: { race: UseRaceData }) {
  const [dividends, setDividends] = useState<ExoticDividend[]>([]);
  const exoticPools = useExoticPools(race?.id);

  useEffect(() => {
    const latestDividends = exoticPools?.DAILY_DOUBLE?.dividends;
    if (latestDividends && latestDividends.length > 0) {
      setDividends(latestDividends);
    }
  }, [exoticPools, dividends]);

  const nextRaceId = race?.meeting?.races?.find(
    (otherRace: Race) => otherRace.number === race.number + 1
  )?.id;

  const { race: nextRace } = useRace(nextRaceId || '');

  if (!nextRaceId) return null;

  return (
    <>
      <div className={style.header}>
        <div>Double</div>
        <div>
          {exoticPools?.DAILY_DOUBLE?.poolSize
            ? `Pool: $${exoticPools?.DAILY_DOUBLE?.poolSize}`
            : ``}
        </div>
      </div>
      <HorizontalOverflow>
        <div>
          <table className={style.table}>
            <tbody>
              <tr>
                <th className={style.gridHeader}>
                  <div className={clsx(style.with)}>With</div>
                </th>
                {race?.competitors.map((competitorXHeader: Competitor) => {
                  if (competitorXHeader.scratched) return null;
                  return (
                    <th
                      key={competitorXHeader.name}
                      className={style.gridHeader}
                    >
                      <div
                        className={clsx(
                          style.topHeaderContent,
                          style.flexCenter
                        )}
                        title={`${competitorXHeader.tabNo}. ${competitorXHeader.name} (${competitorXHeader.barrier})`}
                      >
                        <Silk
                          competitor={competitorXHeader}
                          race={race}
                          meeting={race.meeting}
                        />
                        <div
                          className={clsx(style.numBox, style.topNumBox)}
                          style={numberColours[competitorXHeader.tabNo - 1]}
                        >
                          {competitorXHeader.tabNo}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
              {nextRace?.competitors.map((competitorY: Competitor) => {
                if (competitorY.scratched) return null;
                return (
                  <tr key={competitorY.name}>
                    <th className={style.leftHeaders}>
                      <div
                        className={style.leftHeaderContent}
                        title={`${competitorY.tabNo}. ${competitorY.name} (${competitorY.barrier})`}
                      >
                        <Silk
                          competitor={competitorY}
                          race={nextRace}
                          meeting={race.meeting}
                        />
                        <div
                          className={clsx(style.numBox, style.leftNumBox)}
                          style={numberColours[competitorY.tabNo - 1]}
                        >
                          {competitorY.tabNo}
                        </div>
                      </div>
                    </th>
                    {race?.competitors.map((competitorX: Competitor) => {
                      if (competitorX.scratched) return null;
                      return (
                        <td key={competitorX.name + competitorY.name}>
                          <div
                            className={clsx(style.flexCenter, style.tableCell)}
                          >
                            {/* Refer Exacta oddsboard activeCell for cell styles when showing live odds here */}
                            -
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </HorizontalOverflow>
    </>
  );
}
